import * as React from 'react';
import styled from 'styled-components';
import { RadioValue } from '../../models';
import {
  LineRadioActiveStyles,
  LineRadioStyles,
} from '../../styles/radios/LineRadio.styles';
import { LineRadioCheckmark } from './LineRadioCheckmark';

const LineRadioElement = styled.div<{ isActive: boolean }>`
  --background-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.lightPrimary};

  ${LineRadioStyles}

  ${({ isActive }) => isActive && LineRadioActiveStyles}
`;

type Props = {
  isActive: boolean;
  textComponent: React.ComponentType<{
    isActive: boolean;
  }>;
  model: RadioValue;
  onClick?: (model: RadioValue) => void;
};

export const LineRadio: React.FC<Props> = ({
  isActive,
  model,
  textComponent,
  onClick,
}) => (
  <LineRadioElement
    isActive={isActive}
    onClick={() => onClick && onClick(model)}
  >
    <LineRadioCheckmark isActive={isActive} />

    {React.createElement(textComponent as any, { isActive }, model.text)}
  </LineRadioElement>
);
