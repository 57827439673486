import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RadioCheckmark } from 'studie-core/src/components/radios/RadioCheckmark';
import { RadioValue } from 'studie-core/src/models';
import { RadioActiveStyles, RadioStyles } from 'studie-core/src/styles/radios/Radio.styles';
import styled from 'styled-components';
import { checkValidity, updateAnswerAsync, updateRadio } from '../../state/questionnaire';
import { RadioIcon } from './RadioIcon';
import { RadioText } from './RadioText';

type RadioElementProps = {
  isActive: boolean;
};

const RadioElement = styled.div<RadioElementProps>`
  ${RadioStyles}

  user-select: none;
  cursor: pointer;

  ${({ isActive }) => isActive && RadioActiveStyles}
`;

type Props = {
  model: RadioValue;
  widgetId: string;
  isSelected: boolean;
};

export const Radio: React.FC<Props> = ({ model, widgetId, isSelected }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(updateRadio({ widgetId, selectedAnswerId: model.id }));
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  React.useEffect(() => {
    if (isSelected) {
      dispatch(updateRadio({ widgetId, selectedAnswerId: model.id }));
      dispatch(checkValidity());
    }
  }, []);

  return (
    <RadioElement isActive={isSelected} onClick={() => onClick()}>
      {model.icon && <RadioIcon isActive={isSelected} icon={model.icon} />}

      <RadioCheckmark isActive={isSelected} />

      {React.createElement(RadioText, {}, model!.text)}
    </RadioElement>
  );
};
