import { css } from 'styled-components';

export const ImageWithTextStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const ImageStyles = css`
  height: auto;
  width: 100%;
  margin-bottom: 30px;

  @media only screen and (min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 30px;
    height: 100%;
  }
`;

export const TextStyles = css`
  h1 {
    margin-top: 0;
  }
`;
