import * as React from 'react';

type Size = {
  width: number;
  height: number;
};

export const useWindowSize = () => {
  const [size, setSize] = React.useState<Size>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  React.useEffect(() => {
    const handler = () => {
      setSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return size;
};
