import { useFocusWithin } from '@react-aria/interactions';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ValidationCheckmark } from 'studie-core/src/components/inputs/ValidationCheckmark';
import { ValidationText } from 'studie-core/src/components/inputs/ValidationText';
import { AutocompleteValue } from 'studie-core/src/models';
import { AutocompleteStyles } from 'studie-core/src/styles/autocompletes/Autocomplete.styles';
import styled from 'styled-components';
import { checkValidity, updateAnswerAsync, updateAutocomplete } from '../../state/questionnaire';
import { AutocompleteList } from './AutocompleteList';
import { AutocompleteTextBox } from './AutocompleteTextBox';

const AutocompleteElement = styled.div`
  ${AutocompleteStyles};

  height: 55px;
`;

const AutocompleteTextBoxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

type Props = {
  widgetId: string;
  values: AutocompleteValue[];
  answer: AutocompleteValue;
  placeholder: string;
  isValid: boolean;
  validate?: boolean;
};

export const Autocomplete: React.FC<Props> = ({
  widgetId,
  values,
  answer,
  placeholder,
  isValid,
  validate,
}) => {
  const [isFocusWithin, setFocusWithin] = React.useState(false);
  const autocompleteTextBoxRef = React.createRef<HTMLInputElement>();
  const autocompleteRef = React.createRef<HTMLDivElement>();
  const [searched, setSearched] = React.useState('');
  const [isTouched, setIsTouched] = React.useState(false);
  const [autocompleteWidth, setAutocompleteWidth] = React.useState(0);
  const dispatch = useDispatch();
  const onBlur = (value: string) => {
    const found = values.find(
      (v) =>
        v.value
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') === value
    );
    if (found) {
      setSearched('');
      setIsTouched(true);
      dispatchAnswer(found, true);
    } else {
      setSearched('');
      setIsTouched(true);
      dispatchAnswer(undefined, false);
    }
  };
  const onKeyUp = (value: string) => {
    setSearched(value);
  };
  const onClick = (selectedAnswer: AutocompleteValue) => {
    setSearched('');
    setIsTouched(true);
    dispatchAnswer(selectedAnswer, true);

    setTimeout(() => {
      autocompleteTextBoxRef.current?.dispatchEvent(new Event('blur', { bubbles: true }));
    }, 100);
  };
  const dispatchAnswer = React.useCallback(
    (selectedAnswer?: AutocompleteValue, valid?: boolean, dispatchToApi: boolean = true) => {
      dispatch(
        updateAutocomplete({
          widgetId,
          selectedAnswerId: selectedAnswer?.id || '',
          isValid: valid!,
        })
      );
      dispatch(checkValidity());

      if (dispatchToApi) {
        dispatch(
          updateAnswerAsync.request({
            widgetId,
          })
        );
      }
    },
    [dispatch, widgetId]
  );
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => {
      if (isFocusWithin && !isEmpty(answer)) {
        setSearched(answer.value.toLowerCase());
      }

      if (isFocusWithin && autocompleteRef?.current) {
        setAutocompleteWidth(autocompleteRef.current.getBoundingClientRect().width);
      }

      setFocusWithin(isFocusWithin);
    },
    onBlurWithin: (e) => {
      if ((e as React.FocusEvent<HTMLInputElement>).target === autocompleteTextBoxRef.current) {
        onBlur(
          (e as React.FocusEvent<HTMLInputElement>).target.value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        );
      }
    },
  });

  React.useEffect(() => {
    if (answer && !isTouched) {
      dispatchAnswer(answer, true, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouched]);

  return (
    <AutocompleteElement {...focusWithinProps} ref={autocompleteRef}>
      <AutocompleteTextBoxWrapper>
        {validate && isTouched && !isValid && <ValidationCheckmark />}

        <AutocompleteTextBox
          ref={autocompleteTextBoxRef}
          placeholder={placeholder}
          value={answer?.value || ''}
          onKeyUp={onKeyUp}
          isValid={!validate || (validate && (!isTouched || (isTouched && isValid)))}
        />

        {validate && isTouched && !isValid && <ValidationText />}
      </AutocompleteTextBoxWrapper>

      {isFocusWithin && (
        <AutocompleteList
          active={isFocusWithin}
          values={values}
          onClick={onClick}
          searched={searched}
          width={autocompleteWidth}
        />
      )}
    </AutocompleteElement>
  );
};
