import * as React from 'react';
import { TextBoxValue } from 'studie-core/src/models';
import {
  InputTextBoxInvalidStyles,
  InputTextBoxStyles,
} from 'studie-core/src/styles/inputs/InputTextBox.styles';
import styled from 'styled-components';

type InputElementProps = {
  isValid: boolean;
};

const InputElement = styled.input<InputElementProps>`
  ${InputTextBoxStyles};

  &:hover,
  &:active,
  &:focus {
    --border-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  }

  ${({ isValid }) => !isValid && InputTextBoxInvalidStyles};
`;

type Props = {
  model: TextBoxValue;
  onChange: (value: string) => void;
  isValid: boolean;
  answer?: TextBoxValue;
};

export const InputTextBox: React.FC<Props> = ({ model, onChange, isValid, answer }) => (
  <InputElement
    type="text"
    tabIndex={-1}
    placeholder={model?.placeholder}
    defaultValue={answer?.value}
    onChange={(e) => onChange(e.target.value)}
    isValid={isValid}
  />
);
