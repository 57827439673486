import noUiSlider, { API, PipsMode } from 'nouislider';
import 'nouislider/dist/nouislider.css';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SliderValue } from 'studie-core/src/models';
import { SliderStyles } from 'studie-core/src/styles/sliders/Slider.styles';
import styled from 'styled-components';
import { checkValidity, updateAnswerAsync, updateSlider } from '../../state/questionnaire';

const SliderWrapperElement = styled.div`
  ${SliderStyles}

  .noUi-base {
    background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.lightPrimary};
  }

  .noUi-connect {
    background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  }

  .noUi-handle {
    background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  }

  .noUi-value--active {
    color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  }
`;

type Props = {
  step: number;
  values: SliderValue[];
  widgetId: string;
  value?: SliderValue;
};

export const Slider: React.FC<Props> = ({ step, values, widgetId, value }) => {
  const [range] = React.useState(values.sort((a, b) => a.order - b.order));
  const [slider, setSlider] = React.useState<API>();
  const sliderContainer = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const onChange = (answer: SliderValue) => {
    dispatch(updateSlider({ widgetId, selectedAnswerId: answer.id }));
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  const clickOnPip = (e: any) => {
    const value = Math.trunc(Number(e.currentTarget.getAttribute('data-value')));
    if (slider) {
      slider.set(value);
    }
  };

  const setClickablePips = () => {
    const sliderHTML = sliderContainer.current!;
    Array.from<HTMLDivElement>(sliderHTML.querySelectorAll('.noUi-value')).forEach((pip) => {
      pip.addEventListener('click', clickOnPip);
    });
  };

  const onChangeSlider = (
    _1: string[],
    _2: number,
    unencodedValues: number[],
    _3: boolean,
    _4: number,
    noUiSlider: any
  ) => {
    const realValue = Math.trunc(unencodedValues[0]);
    if (onChange) {
      onChange(range.find((v) => v.value === realValue)!);
    }

    Array.from(
      (noUiSlider.target as HTMLDivElement).querySelectorAll<HTMLDivElement>('[data-value]')
    ).forEach((el) => {
      if (Math.trunc(Number(el.dataset.value)) === realValue) {
        el.classList.add('noUi-value--active');
      } else {
        el.classList.remove('noUi-value--active');
      }
    });
  };

  const createSlider = () => {
    const rangeValues = range.map((rangeValue) => rangeValue.value);
    const sliderComponent = noUiSlider.create(sliderContainer.current!, {
      start: value?.value || range[0].value,
      range: {
        min: rangeValues[0],
        max: rangeValues[range.length - 1],
      },
      connect: [true, false],
      step,
      pips: {
        mode: PipsMode.Values,
        values: rangeValues,
        format: {
          to: (sliderValue: number) => range.find((v) => v.value === Math.trunc(sliderValue))!.text,
          from: Number,
        },
        density: Infinity,
        stepped: true,
      },
    });

    sliderComponent.on('set', onChangeSlider as any); // official typings are missing params

    if (value === null || value === undefined || Object.keys(value).length === 0) {
      sliderComponent!.set(range[0].value);
    }

    setSlider(sliderComponent);
  };

  React.useEffect(() => {
    const sliderHTML = sliderContainer.current;
    if (sliderHTML) {
      createSlider();
    }

    return () => {
      if (slider) slider.destroy();
    };
  }, []);

  React.useEffect(() => {
    if (slider) {
      setClickablePips();
    }
  }, [slider]);

  return (
    <SliderWrapperElement>
      <div ref={sliderContainer} />
    </SliderWrapperElement>
  );
};
