import * as React from 'react';
import styled from 'styled-components';
import {
  RadioCheckmarkActiveStyles,
  RadioCheckmarkStyles,
} from '../../styles/radios/RadioCheckmark.styles';

type RadioCheckmarkElementProps = {
  isActive: boolean;
};

const RadioCheckmarkElement = styled.div<RadioCheckmarkElementProps>`
  --border-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  ${RadioCheckmarkStyles};

  align-self: flex-end;

  ${({ isActive }) => isActive && RadioCheckmarkActiveStyles}
`;

type Props = {
  isActive: boolean;
};

export const RadioCheckmark: React.FC<Props> = ({ isActive }) => (
  <RadioCheckmarkElement isActive={isActive} />
);
