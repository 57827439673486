import { debounce } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ValidationCheckmark } from 'studie-core/src/components/inputs/ValidationCheckmark';
import { ValidationText } from 'studie-core/src/components/inputs/ValidationText';
import { InputStyles } from 'studie-core/src/styles/inputs/Input.styles';
import styled from 'styled-components';
import { checkValidity, updateAnswerAsync, updateText } from '../../state/questionnaire';
import { equalityComparer } from './equalityComparer';
import { InputTextBox } from './InputTextBox';
import { Props } from './props';

const InputElement = styled.div`
  ${InputStyles}

  height: 55px;
  margin: 0 auto;
`;

const InputTextBoxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Input: React.FC<Props> = React.memo(
  ({ model, widgetId, isValid, answer, validate = false }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const dispatch = useDispatch();

    const onChange = (newValue: string, dispatchToApi: boolean = true) => {
      dispatch(
        updateText({
          widgetId,
          selectedAnswerId: model.id,
          value: newValue,
        })
      );
      dispatch(checkValidity());

      if (dispatchToApi) {
        dispatch(
          updateAnswerAsync.request({
            widgetId,
          })
        );
      }
    };
    const delayedOnChange = React.useCallback(
      debounce((newValue: string) => onChange(newValue, true), 500),
      []
    );

    React.useEffect(() => {
      if (answer && answer?.value !== undefined && answer?.value.length >= 0 && !isTouched) {
        onChange(answer?.value, false);
      }
    }, [isTouched, answer]);

    return (
      <InputElement>
        <InputTextBoxWrapper>
          {validate && isTouched && !isValid && <ValidationCheckmark />}

          <InputTextBox
            model={model}
            answer={answer}
            onChange={(newValue) => {
              setIsTouched(true);
              delayedOnChange(newValue);
            }}
            isValid={!validate || (validate && (!isTouched || (isTouched && isValid)))}
          />

          {validate && isTouched && !isValid && <ValidationText />}
        </InputTextBoxWrapper>
      </InputElement>
    );
  },
  equalityComparer
);
