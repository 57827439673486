import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { IconsAction, iconsReducer } from './icons';
import { LandingAction, landingReducer } from './landing';
import {
  insertCallOrderSaga,
  LiveSaleIntegrationAction,
  livesaleIntegrationReducer,
  verifyNapiPasswordSaga,
  verifyPasswordSaga,
} from './livesale-integration';
import { ModalAction, modalReducer } from './modal';
import {
  getQuestionnaireSaga,
  QuestionnaireAction,
  questionnaireReducer,
  updateAnswerSaga,
  updateStateSaga,
} from './questionnaire';
import { ViewsAction, viewsReducer } from './views';

const combinedReducer = combineReducers({
  modal: modalReducer,
  views: viewsReducer,
  questionnaire: questionnaireReducer,
  landing: landingReducer,
  livesale: livesaleIntegrationReducer,
  icons: iconsReducer,
});

export type RootStateType = ReturnType<typeof combinedReducer>;

export type RootActionType =
  | QuestionnaireAction
  | ViewsAction
  | LandingAction
  | ModalAction
  | LiveSaleIntegrationAction
  | IconsAction;

const rootReducer = (state: RootStateType, action: RootActionType) =>
  combinedReducer(state, action);

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    console.log(error, errorInfo);
  },
});

const devToolsEnhancer = composeWithDevTools({ trace: true, traceLimit: 25 });
const middlewares = [sagaMiddleware];

const store = createStore(rootReducer as any, devToolsEnhancer(applyMiddleware(...middlewares)));

function* rootSaga() {
  yield all([
    verifyNapiPasswordSaga(),
    verifyPasswordSaga(),
    insertCallOrderSaga(),
    getQuestionnaireSaga(),
    updateAnswerSaga(),
    updateStateSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);

export const useApplicationStore = () => {
  (window as any).callscriptStore = store;

  return { store };
};
