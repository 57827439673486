import { debounce } from 'lodash';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContactsExtra,
  ContactValue,
  FormCompany,
  FormContacts,
  FormPerson,
} from 'studie-core/src/models';
import styled from 'styled-components';
import {
  updateAnswerAsync,
  updateFormAnswer,
  UpdateFormAnswerData,
} from '../../state/questionnaire';
import { RootStateType } from '../../state/store';
import { PhoneInput } from '../questionnaires/pages/forms/PhoneInput';
import { Checkbox } from './Checkbox';
import { FormInput } from './FormInput';
import { DispatchFormAnswerData } from './types';

const FormElement = styled.form`
  margin: 0 auto;
  width: 650px;
`;

const SubHeaderElement = styled.h4`
  font-size: 18px;
`;

const InputGroupElement = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;

    > div {
      flex: 0 1 50%;
    }

    > div:first-of-type {
      margin-right: 5px;
    }

    > div:last-of-type {
      margin-left: 5px;
    }
  }
`;

const GridElement = styled.div`
  --columns: 1;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-column-gap: 10px;

  @media only screen and (min-width: 600px) {
    --columns: 2;
  }
`;

type Props = {
  widgetId: string;
  widgetExtra: ContactsExtra;
};

export const Form: React.FC<Props> = ({ widgetId, widgetExtra }) => {
  const companyDefaultValues = {} as any;
  Object.keys(widgetExtra.company)
    .filter((key) => key !== 'id')
    .forEach(
      (key) =>
        (companyDefaultValues[key] = ((widgetExtra.company as never)[key] as ContactValue).value)
    );

  const peopleDefaultValues = {} as any;
  Object.keys(widgetExtra.people[0])
    .filter((key) => !['id', 'order'].includes(key))
    .forEach(
      (key) =>
        (peopleDefaultValues[key] = ((widgetExtra.people[0] as never)[key] as ContactValue).value)
    );

  const defaultValues = {
    isCompanyContact: widgetExtra.isCompanyContact.value,
    company: companyDefaultValues as FormCompany,
    people: peopleDefaultValues as FormPerson,
  };

  const { widgets, activeWidgetIndex } = useSelector((e: RootStateType) => e.questionnaire);
  const dispatch = useDispatch();
  const {
    watch,
    control,
    formState: { errors },
    trigger,
  } = useForm<FormContacts>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const isCompanyWatch = watch('isCompanyContact');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatchValue = React.useCallback(
    debounce(({ value, type, property }: DispatchFormAnswerData) => {
      (defaultValues as any)[type][property] = value;

      const data = {
        widgetId,
        value,
        type,
        property,
      } as UpdateFormAnswerData;
      dispatch(updateFormAnswer(data));

      if (widgets[activeWidgetIndex].id === widgetId) {
        dispatch(
          updateAnswerAsync.request({
            widgetId,
          })
        );
      }
    }, 500),
    [dispatch, widgets, activeWidgetIndex]
  );
  const dispatchIsCompanyContactChange = React.useCallback(
    (value: boolean) => {
      dispatch(
        updateFormAnswer({
          widgetId,
          value,
          type: 'isCompanyContact',
        })
      );

      if (widgets[activeWidgetIndex].id === widgetId) {
        dispatch(
          updateAnswerAsync.request({
            widgetId,
          })
        );
      }
    },
    [dispatch, widgets, activeWidgetIndex]
  );

  return (
    <FormElement>
      <SubHeaderElement>Osobní údaje</SubHeaderElement>

      <GridElement>
        {widgetExtra.people[0].firstName.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].firstName.required && {
                required: 'Toto pole je povinné',
              }),
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormInput
                name={name}
                label="Jméno"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.firstName}
                required={widgetExtra.people[0].firstName.required}
                dispatchAnswer={dispatchValue}
                triggerOwnValidation={trigger}
              />
            )}
            defaultValue={defaultValues?.people?.firstName}
            name="people.firstName"
          />
        )}

        {widgetExtra.people[0].lastName.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].lastName.required && {
                required: 'Toto pole je povinné',
              }),
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormInput
                name={name}
                label="Příjmení"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.lastName}
                required={widgetExtra.people[0].lastName.required}
                dispatchAnswer={dispatchValue}
                triggerOwnValidation={trigger}
              />
            )}
            defaultValue={defaultValues?.people?.lastName}
            name="people.lastName"
          />
        )}

        {widgetExtra.people[0].phone.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].phone.required && {
                required: 'Toto pole je povinné',
              }),
              validate: (value) =>
                /(\+42)+[0-1]\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}/g.test(value) ||
                'Neplatné telefonní číslo',
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <PhoneInput
                name={name}
                label="Telefon"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.phone}
                required={widgetExtra.people[0].phone.required}
                dispatchAnswer={dispatchValue}
              />
            )}
            defaultValue={defaultValues?.people?.phone}
            name="people.phone"
          />
        )}

        {widgetExtra.people[0].mobile.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].mobile.required && {
                required: 'Toto pole je povinné',
              }),
              validate: (value) =>
                /(\+42)+[0-1]\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}/g.test(value) ||
                'Neplatné telefonní číslo',
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <PhoneInput
                name={name}
                label="Mobil"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.mobile}
                required={widgetExtra.people[0].mobile.required}
                dispatchAnswer={dispatchValue}
              />
            )}
            defaultValue={defaultValues?.people?.mobile}
            name="people.mobile"
          />
        )}

        {widgetExtra.people[0].email.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].email.required && {
                required: 'Toto pole je povinné',
              }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Neplatná e-mailová adresa',
              },
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormInput
                name={name}
                label="E-mail"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.email}
                required={widgetExtra.people[0].email.required}
                dispatchAnswer={dispatchValue}
                triggerOwnValidation={trigger}
              />
            )}
            defaultValue={defaultValues?.people?.email}
            name="people.email"
          />
        )}

        {widgetExtra.people[0].email2.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].email2.required && {
                required: 'Toto pole je povinné',
              }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Neplatná e-mailová adresa',
              },
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormInput
                name={name}
                label="E-mail"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.email2}
                required={widgetExtra.people[0].email2.required}
                dispatchAnswer={dispatchValue}
                triggerOwnValidation={trigger}
              />
            )}
            defaultValue={defaultValues?.people?.email2}
            name="people.email2"
          />
        )}

        {widgetExtra.people[0].position.show && (
          <Controller
            control={control}
            rules={{
              ...(widgetExtra.people[0].position.required && {
                required: 'Toto pole je povinné',
              }),
            }}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormInput
                name={name}
                label="Pozice"
                value={value}
                onChange={onChange}
                inputRef={ref}
                fieldError={errors.people?.position}
                required={widgetExtra.people[0].position.required}
                dispatchAnswer={dispatchValue}
                triggerOwnValidation={trigger}
              />
            )}
            defaultValue={defaultValues?.people?.position}
            name="people.position"
          />
        )}
      </GridElement>

      {widgetExtra.isCompanyContact.show && (
        <InputGroupElement>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                inputRef={ref}
                name={name}
                onChange={onChange}
                checked={value}
                dispatchIsCompanyContactChange={dispatchIsCompanyContactChange}
              />
            )}
            name="isCompanyContact"
          />
        </InputGroupElement>
      )}

      {isCompanyWatch && (
        <>
          <SubHeaderElement>Údaje o společnosti</SubHeaderElement>

          {widgetExtra.company.name.show && (
            <Controller
              control={control}
              rules={{
                ...(widgetExtra.company.name.required && {
                  required: 'Toto pole je povinné',
                }),
              }}
              render={({ field: { onChange, value, name, ref } }) => (
                <FormInput
                  name={name}
                  label="Název společnosti"
                  value={value}
                  onChange={onChange}
                  inputRef={ref}
                  fieldError={errors.company?.name}
                  required={widgetExtra.company.name.required}
                  dispatchAnswer={dispatchValue}
                  triggerOwnValidation={trigger}
                />
              )}
              defaultValue={defaultValues?.company?.name}
              name="company.name"
            />
          )}

          <GridElement>
            {widgetExtra.company.identificationNumber.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.identificationNumber.required && {
                    required: 'Toto pole je povinné',
                  }),
                  pattern: {
                    value: /^[0-9]{8}$/i,
                    message: 'Neplatné IČO',
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <FormInput
                    name={name}
                    label="IČO"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.identificationNumber}
                    required={widgetExtra.company.identificationNumber.required}
                    dispatchAnswer={dispatchValue}
                    triggerOwnValidation={trigger}
                  />
                )}
                defaultValue={defaultValues?.company?.identificationNumber}
                name="company.identificationNumber"
              />
            )}

            {widgetExtra.company.vat.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.vat.required && {
                    required: 'Toto pole je povinné',
                  }),
                  pattern: {
                    value: /^CZ+[0-9]{8}$/i,
                    message: 'Neplatné DIČ',
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <FormInput
                    name={name}
                    label="DIČ"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.vat}
                    required={widgetExtra.company.vat.required}
                    dispatchAnswer={dispatchValue}
                    triggerOwnValidation={trigger}
                  />
                )}
                defaultValue={defaultValues?.company?.vat}
                name="company.vat"
              />
            )}

            {widgetExtra.company.phone.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.phone.required && {
                    required: 'Toto pole je povinné',
                  }),
                  validate: (value) =>
                    /(\+42)+[0-1]\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}/g.test(value) ||
                    'Neplatné telefonní číslo',
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <PhoneInput
                    name={name}
                    label="Telefon"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.phone}
                    required={widgetExtra.company.phone.required}
                    dispatchAnswer={dispatchValue}
                  />
                )}
                defaultValue={defaultValues?.company?.phone}
                name="company.phone"
              />
            )}

            {widgetExtra.company.mobile.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.mobile.required && {
                    required: 'Toto pole je povinné',
                  }),
                  validate: (value) =>
                    /(\+42)+[0-1]\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}/g.test(value) ||
                    'Neplatné telefonní číslo',
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <PhoneInput
                    name={name}
                    label="Mobil"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.mobile}
                    required={widgetExtra.company.mobile.required}
                    dispatchAnswer={dispatchValue}
                  />
                )}
                defaultValue={defaultValues?.company?.mobile}
                name="company.mobile"
              />
            )}

            {widgetExtra.company.email.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.email.required && {
                    required: 'Toto pole je povinné',
                  }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Neplatná e-mailová adresa',
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <FormInput
                    name={name}
                    label="E-mail"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.email}
                    required={widgetExtra.company.email.required}
                    dispatchAnswer={dispatchValue}
                    triggerOwnValidation={trigger}
                  />
                )}
                defaultValue={defaultValues?.company?.email}
                name="company.email"
              />
            )}

            {widgetExtra.company.email2.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.email2.required && {
                    required: 'Toto pole je povinné',
                  }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Neplatná e-mailová adresa',
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <FormInput
                    name={name}
                    label="E-mail"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.email2}
                    required={widgetExtra.company.email2.required}
                    dispatchAnswer={dispatchValue}
                    triggerOwnValidation={trigger}
                  />
                )}
                defaultValue={defaultValues?.company?.email2}
                name="company.email2"
              />
            )}

            {widgetExtra.company.groupMail.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.groupMail.required && {
                    required: 'Toto pole je povinné',
                  }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Neplatná e-mailová adresa',
                  },
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <FormInput
                    name={name}
                    label="E-mail"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.groupMail}
                    required={widgetExtra.company.groupMail.required}
                    dispatchAnswer={dispatchValue}
                    triggerOwnValidation={trigger}
                  />
                )}
                defaultValue={defaultValues?.company?.groupMail}
                name="company.groupMail"
              />
            )}

            {widgetExtra.company.webUrl.show && (
              <Controller
                control={control}
                rules={{
                  ...(widgetExtra.company.webUrl.required && {
                    required: 'Toto pole je povinné',
                  }),
                }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <FormInput
                    name={name}
                    label="Web společnosti"
                    value={value}
                    onChange={onChange}
                    inputRef={ref}
                    fieldError={errors.company?.webUrl}
                    required={widgetExtra.company.webUrl.required}
                    dispatchAnswer={dispatchValue}
                    triggerOwnValidation={trigger}
                  />
                )}
                defaultValue={defaultValues?.company?.webUrl}
                name="company.webUrl"
              />
            )}
          </GridElement>
        </>
      )}
    </FormElement>
  );
};
