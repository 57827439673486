import * as React from 'react';
import { ImageValue } from 'studie-core/src/models';
import styled from 'styled-components';

const FullscreenImageElement = styled.div`
  margin: 0 auto;
  max-height: calc(100vh - 75px);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

type Props = {
  model: ImageValue;
};

export const FullscreenImage: React.FC<Props> = ({ model }) => (
  <FullscreenImageElement>
    <img src={model.imageUrl} alt="" />
  </FullscreenImageElement>
);
