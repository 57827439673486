import * as React from 'react';
import styled from 'styled-components';

const ValidationCheckmarkElement = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.red};
  border: 2px solid ${({ theme: { defaultColors } }) => defaultColors.red};
  position: absolute;
  right: 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: default;
  z-index: 4;
`;

export const ValidationCheckmark = () => (
  <ValidationCheckmarkElement>!</ValidationCheckmarkElement>
);
