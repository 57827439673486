import * as React from 'react';
import { VerifyPasswordResponse } from '../../../api';

type Props = {
  verificationResponse: VerifyPasswordResponse;
  submitFormButtonRef: React.RefObject<HTMLButtonElement>;
  entryUrl: string;
};

export const SelectWayForm = ({ verificationResponse, submitFormButtonRef, entryUrl }: Props) => (
  <form
    action={`${entryUrl}/Client/SelectWay`}
    method="post"
    noValidate={true}
    style={{ display: 'none' }}
  >
    <input
      id="OperatorID"
      name="OperatorID"
      type="hidden"
      value={verificationResponse!.OperatorID}
    />
    <input id="CallType" name="CallType" type="hidden" value={verificationResponse!.CallType} />
    <input id="CallDirection" name="CallDirection" type="hidden" value="In" />
    <input
      id="CompanyContactID"
      name="CompanyContactID"
      type="hidden"
      value={verificationResponse!.CompanyContactID}
    />
    <input
      id="CurrentUrl"
      name="CurrentUrl"
      type="hidden"
      value={verificationResponse!.OriginUrl}
    />
    <input id="OrderedCall" name="OrderedCall" type="hidden" value="True" />
    <input
      id="CampaignLeadID"
      name="CampaignLeadID"
      type="hidden"
      value={verificationResponse!.CampaignLeadID}
    />
    <input id="EntryType" name="EntryType" type="hidden" value="CallScript" />
    <input id="PhoneNumber" name="PhoneNumber" type="hidden" value="000000000" />
    <input id="PhonePrefix" name="PhonePrefix" type="hidden" value="+420" />

    <button ref={submitFormButtonRef} onClick={() => {}} type="submit" />
  </form>
);
