import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Answer } from 'studie-core/src/components/matrixes/Answer';
import { Answers } from 'studie-core/src/components/matrixes/Answers';
import { Matrix as SharedMatrix } from 'studie-core/src/components/matrixes/Matrix';
import { Question } from 'studie-core/src/components/matrixes/Question';
import { RatingDefinition } from 'studie-core/src/components/matrixes/RatingDefinition';
import { RatingsDefinition } from 'studie-core/src/components/matrixes/RatingsDefinition';
import { MatrixRatingValue, MatrixValue } from 'studie-core/src/models';
import { checkValidity, updateAnswerAsync, updateMatrix } from '../../state/questionnaire';

type Props = {
  widgetId: string;
  ratings: MatrixRatingValue[];
  values: MatrixValue[];
  answers: MatrixValue[];
};

export const Matrix: React.FC<Props> = ({ widgetId, ratings, values, answers }) => {
  const dispatch = useDispatch();

  const onChange = (valueId: string, ratingId: string, dispatchToApi: boolean = true) => {
    dispatch(
      updateMatrix({
        widgetId,
        selectedAnswerId: values.find((v) => v.id === valueId)!.id,
        value: ratings.find((r) => r.id === ratingId)!.value,
      })
    );

    dispatch(checkValidity());

    if (dispatchToApi) {
      dispatch(
        updateAnswerAsync.request({
          widgetId,
        })
      );
    }
  };

  return (
    <SharedMatrix>
      <RatingsDefinition>
        {ratings.map((rating) => (
          <RatingDefinition key={rating.id}>{rating.text}</RatingDefinition>
        ))}
      </RatingsDefinition>

      {Array.from({ length: values.length }, (_, index) => index).map((index) => {
        const answer = answers?.find((answer) => answer.id === values[index].id);
        const selectedAnswerValue = !isEmpty(answer) ? answer!.value : null;

        return (
          <React.Fragment key={values[index].id}>
            <Question required={values[index].required}>{values[index].text}</Question>

            <Answers>
              {ratings.map((rating) => (
                <Answer
                  key={rating.id}
                  valueId={values[index].id}
                  ratingId={rating.id}
                  onChange={onChange}
                  isSelected={selectedAnswerValue !== null && rating.value === selectedAnswerValue}
                />
              ))}
            </Answers>
          </React.Fragment>
        );
      })}
    </SharedMatrix>
  );
};
