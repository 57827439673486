import * as React from 'react';
import { Widget as WidgetType } from 'studie-core/src/models';
import styled, { css } from 'styled-components';
import { Agreements } from '../agreements/Agreements';
import { Autocomplete } from '../autocompletes/Autocomplete';
import { Checkbox } from '../checkboxes/Checkbox';
import { LineCheckbox } from '../checkboxes/LineCheckbox';
import { Form } from '../form/Form';
import { Input } from '../inputs/Input';
import { Matrix } from '../matrixes/Matrix';
import { LineRadio } from '../radios/LineRadio';
import { Radio } from '../radios/Radio';
import { SimpleChoice } from '../radios/SimpleChoice';
import { Range } from '../ranges/Range';
import { Slider } from '../slider/Slider';
import { FullscreenImage } from '../visual/FullscreenImage';
import { Image } from '../visual/Image';
import { ImageWithText } from '../visual/ImageWithText';
import { SimpleText } from '../visual/SimpleText';
import { Answers } from './Answers';
import { Header } from './headers/Header';
import { WidgetStyles } from './Widget.styles';

const WidgetElement = styled.div<{ isFullscreenWidget: boolean }>`
  ${WidgetStyles}

  ${({ isFullscreenWidget }) =>
    isFullscreenWidget &&
    css`
      padding: 0;
    `}
`;

const ContentElement = styled.div`
  width: 100%;
  margin: auto;

  @media only screen and (min-width: 600px) {
    padding: 0;
  }
`;

const GridElement = styled.div<{ elementsCount: number }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-flow: dense;
  width: 100%;

  @media only screen and (min-width: 600px) {
    margin: 0 auto;
    grid-template-columns: repeat(
      ${({ elementsCount }) => (elementsCount > 3 ? 3 : elementsCount)},
      1fr
    );
    ${({ elementsCount }) =>
      elementsCount < 3 &&
      css`
        width: auto;
      `}
  }

  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(
      ${({ elementsCount }) => (elementsCount > 4 ? 4 : elementsCount)},
      1fr
    );
    ${({ elementsCount }) =>
      elementsCount < 4 &&
      css`
        width: auto;
      `}
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(${({ elementsCount }) => elementsCount}, 1fr);
    ${({ elementsCount }) =>
      elementsCount < 5 &&
      css`
        width: auto;
      `}
  }
`;

const LinedGridElement = styled.div`
  width: 100%;
  max-width: 650px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  grid-auto-flow: dense;
  margin: 0 auto;
`;

type Props = {
  widget: WidgetType;
  isFullscreenWidget: boolean;
};

export const Widget: React.FC<Props> = ({ widget, isFullscreenWidget }) => {
  const getAnswers = (widget: WidgetType) => {
    switch (widget.type) {
      case 'agreements': {
        return <Agreements widgetId={widget.id} widgetExtra={widget.extra} />;
      }

      case 'contacts': {
        return <Form widgetId={widget.id} widgetExtra={widget.extra} />;
      }

      case 'simplechoice': {
        return (
          <SimpleChoice
            widgetId={widget.id}
            values={widget.extra.values}
            answer={widget.extra.answer}
          />
        );
      }

      case 'autocomplete': {
        return (
          <Autocomplete
            widgetId={widget.id}
            values={widget.extra.values}
            answer={widget.extra.answer}
            validate={widget.required}
            isValid={widget.valid}
            placeholder={widget.extra.placeholder}
          />
        );
      }

      case 'checkbox':
        if (widget.subtype === 'boxed') {
          const elementsCount = widget.extra.values.length > 5 ? 5 : widget.extra.values.length;

          return (
            <GridElement elementsCount={elementsCount}>
              {widget.extra.values.map((value) => {
                const answer = widget.extra.answers?.find((a) => a.id === value.id);

                return (
                  <Checkbox
                    key={value.id}
                    widgetId={widget.id}
                    model={value}
                    answer={answer}
                    isSelected={!!answer}
                  />
                );
              })}
            </GridElement>
          );
        }
        return (
          <LinedGridElement>
            {widget.extra.values.map((value) => {
              const answer = widget.extra.answers?.find((a) => a.id === value.id);

              return (
                <LineCheckbox
                  key={value.id}
                  widgetId={widget.id}
                  model={value}
                  isSelected={!!answer}
                />
              );
            })}
          </LinedGridElement>
        );

      case 'radio': {
        if (widget.subtype === 'boxed') {
          const elementsCount = widget.extra.values.length > 5 ? 5 : widget.extra.values.length;

          return (
            <GridElement elementsCount={elementsCount}>
              {widget.extra.values.map((value) => (
                <Radio
                  key={value.id}
                  widgetId={widget.id}
                  model={value}
                  isSelected={widget.extra.answer?.id === value.id}
                />
              ))}
            </GridElement>
          );
        }
        return (
          <LinedGridElement>
            {widget.extra.values.map((value) => (
              <LineRadio
                key={value.id}
                widgetId={widget.id}
                model={value}
                isSelected={widget.extra.answer?.id === value.id}
              />
            ))}
          </LinedGridElement>
        );
      }

      case 'slider': {
        return (
          <Slider
            widgetId={widget.id}
            step={widget.extra.step}
            values={widget.extra.values}
            value={widget.extra.answer}
          />
        );
      }

      case 'text': {
        return widget.extra.values.map((value) => (
          <Input
            key={value.id}
            widgetId={widget.id}
            model={value}
            answer={widget.extra.answer}
            validate={widget.required}
            isValid={widget.valid}
          />
        ));
      }

      case 'range': {
        return (
          <Range widgetId={widget.id} model={widget.extra.values[0]} answer={widget.extra.answer} />
        );
      }

      case 'simpletext': {
        return <SimpleText model={widget.extra.values[0]} />;
      }

      case 'imagetext': {
        return <ImageWithText model={widget.extra.values[0]} />;
      }

      case 'image': {
        if (widget.subtype === 'fullscreen') {
          return <FullscreenImage model={widget.extra.values[0]} />;
        }

        return <Image model={widget.extra.values[0]} />;
      }

      case 'ratingmatrix': {
        return (
          <Matrix
            widgetId={widget.id}
            ratings={widget.extra.ratings}
            values={widget.extra.values}
            answers={widget.extra.answers}
          />
        );
      }

      default:
        return null;
    }
  };

  const gerRealWidget = () => (
    <WidgetElement isFullscreenWidget={isFullscreenWidget}>
      <ContentElement>
        <Header
          isFullscreenWidget={isFullscreenWidget}
          header={widget.headerText}
          subheader={widget.noteText}
        />

        <Answers>{getAnswers(widget)}</Answers>
      </ContentElement>
    </WidgetElement>
  );

  return gerRealWidget();
};
