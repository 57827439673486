import { darken } from 'polished';
import { css } from 'styled-components';

export const ApplicationButtonBaseStyles = css`
  --border-color: ${({ theme: { defaultColors } }) => defaultColors.primary};
  --background-color: ${({ theme: { defaultColors } }) =>
    defaultColors.primary};
  --color: white;

  border: 2px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--color);
  padding: 0 24px;
  border-radius: 28px;
  margin: 0;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ApplicationButtonStyles = css`
  &:hover {
    --border-color: ${({ theme: { defaultColors } }) =>
      darken(0.1, defaultColors.primary)};
    --background-color: ${({ theme: { defaultColors } }) =>
      darken(0.1, defaultColors.primary)};
  }

  &:active {
    --border-color: ${({ theme: { defaultColors } }) =>
      darken(0.17, defaultColors.primary)};
    --background-color: ${({ theme: { defaultColors } }) =>
      darken(0.17, defaultColors.primary)};
  }

  &:disabled {
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
    --background-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
  }
`;

export const ApplicationButtonOutlinedStyles = css`
  --background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  --color: ${({ theme: { defaultColors } }) => defaultColors.primary};

  &:hover {
    --background-color: ${({ theme: { defaultColors } }) =>
      darken(0.1, defaultColors.primary)};
    --border-color: ${({ theme: { defaultColors } }) =>
      darken(0.1, defaultColors.primary)};
    --color: ${({ theme: { defaultColors } }) => defaultColors.white};
  }

  &:active {
    --background-color: ${({ theme: { defaultColors } }) =>
      darken(0.17, defaultColors.primary)};
    --border-color: ${({ theme: { defaultColors } }) =>
      darken(0.17, defaultColors.primary)};
    --color: ${({ theme: { defaultColors } }) => defaultColors.white};
  }

  &:disabled {
    --background-color: ${({ theme: { defaultColors } }) =>
      defaultColors.white};
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
    --color: ${({ theme: { defaultColors } }) => defaultColors.gray};
  }

  &:disabled:hover {
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};
    --color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};
  }
`;
