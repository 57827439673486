import { Structure } from 'studie-core/src/models';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { Landing } from '../components/questionnaires/pages/Landing';
import { SinglePageQuestionnaire } from '../components/questionnaires/SinglePageQuestionnaire';
import { VerticalQuestionnaire } from '../components/questionnaires/VerticalQuestionnaire';

const _pages = {
  Landing,
};

const _types: { [key in Structure]: React.FC } = {
  'single-page': SinglePageQuestionnaire,
  vertical: VerticalQuestionnaire,
};

type ViewsState = {
  activeComponent: React.FC;
  type?: Structure;
};

export const setComponent = createAction('@views/SET_COMPONENT')<
  | {
      component: 'Landing';
    }
  | {
      component: 'Questionnaire';
      type: Structure;
    }
>();

export type ViewsAction = ActionType<typeof setComponent>;

export const viewsReducer = createReducer<ViewsState, ViewsAction>({
  activeComponent: Landing,
  type: undefined,
}).handleAction(setComponent, (state, action) => {
  if (action.payload.component === 'Questionnaire') {
    const component = _types[action.payload.type];

    return { ...state, activeComponent: component, type: action.payload.type };
  }

  return { ...state, activeComponent: _pages[action.payload.component] };
});
