import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'studie-core/src/components/buttons/Button';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';
import { setPage } from '../../../../state/landing';

const MainHeadingElement = styled.h1`
  font-size: 40px;
  margin-top: 0;

  @media only screen and (min-width: 600px) {
    font-size: 50px;
  }
`;

const SmallIcon = styled(Icon)`
  width: 18px;
  height: 18px;
`;

const GoBackButtonElement = styled(Button)`
  justify-content: space-between;
  width: 200px;
  margin-top: 15px;
`;

export const Thanks: React.FC = () => {
  const dispatch = useDispatch();

  const goBack = () => dispatch(setPage({ page: 'Welcome' }));

  return (
    <>
      <MainHeadingElement>Děkujeme</MainHeadingElement>
      <div>za vyplnění údajů. Náš operátor Vás bude kontaktovat ve Vámi zvoleném termínu.</div>

      <GoBackButtonElement onClick={goBack} outlined>
        <SmallIcon name="arrow-left" />
        <span>Zpět na úvod</span>
      </GoBackButtonElement>
    </>
  );
};
