import * as React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../../../hooks/useWindowSize';

type QuestionnaireProps = {
  width: number;
  height: number;
};

const InternalQuestionnaireElement: React.FC<QuestionnaireProps> = ({
  children,
  width,
  height,
  ...rest
}) => <div {...rest}>{children}</div>;
const QuestionnaireContainer = styled(InternalQuestionnaireElement)`
  overflow: hidden;
  width: ${({ width }) => `${width}px`};
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  padding: 75px 0 0 0;
  height: ${({ height }) => `${height}px`};
`;

export const Questionnaire: React.FC = ({ children }) => {
  const { width, height } = useWindowSize();

  return (
    <QuestionnaireContainer width={width} height={height}>
      {children}
    </QuestionnaireContainer>
  );
};
