import { darken } from 'polished';
import { css } from 'styled-components';

export const AutocompleteListItemStyles = css`
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;

  &:hover {
    background-color: #f8f8f8;
    color: ${({ theme: { questionnaireColors } }) =>
      darken(0.1, questionnaireColors.primary)};
  }
`;
