import * as React from 'react';
import styled, { css } from 'styled-components';

type LineRadioTextElementProps = {
  isActive: boolean;
};

const LineRadioTextElement = styled.div<LineRadioTextElementProps>`
  --color: ${({ theme: { defaultColors } }) => defaultColors.black};

  color: var(--color);
  display: flex;
  width: 100%;
  font-size: 18px;
  margin-left: 10px;

  ${({ isActive }) =>
    isActive &&
    css`
      --color: ${({ theme: { defaultColors } }) => defaultColors.white};
    `}
`;

type Props = {
  isActive: boolean;
  className?: string;
};

export const LineRadioText: React.FC<Props> = ({ isActive, className, children }) => (
  <LineRadioTextElement isActive={isActive} className={className}>
    {children}
  </LineRadioTextElement>
);
