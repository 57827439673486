import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LineRadio as SharedRadio } from 'studie-core/src/components/radios/LineRadio';
import { RadioValue } from 'studie-core/src/models';
import styled from 'styled-components';
import { checkValidity, updateAnswerAsync, updateRadio } from '../../state/questionnaire';
import { LineRadioText } from './LineRadioText';

const LineRadioElement = styled(SharedRadio)``;

type Props = {
  model: RadioValue;
  widgetId: string;
  isSelected: boolean;
};

export const LineRadio: React.FC<Props> = ({ model, widgetId, isSelected }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(updateRadio({ widgetId, selectedAnswerId: model.id }));
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  React.useEffect(() => {
    if (isSelected) {
      dispatch(updateRadio({ widgetId, selectedAnswerId: model.id }));
      dispatch(checkValidity());
    }
  }, []);

  return (
    <LineRadioElement
      isActive={isSelected}
      textComponent={LineRadioText}
      model={model}
      onClick={() => onClick()}
    />
  );
};
