import { css } from 'styled-components';

export const CheckboxStyles = css`
  --color: ${({ theme: { defaultColors } }) => defaultColors.black};
  --background-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.lightPrimary};

  max-width: 205px;
  width: 100%;
  height: 205px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: var(--color);
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 17px;
  justify-self: center;

  @media only screen and (min-width: 600px) {
    width: 205px;
  }
`;

export const CheckboxActiveStyles = css`
  --background-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};
  --color: ${({ theme: { defaultColors } }) => defaultColors.white};
`;
