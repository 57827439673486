import * as React from 'react';
import styled from 'styled-components';

const AnswersElement = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;

export const Answers: React.FC = ({ children }) => <AnswersElement>{children}</AnswersElement>;
