import * as React from 'react';
import styled from 'styled-components';

const RadioTextElement = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  height: 89px;
  padding: 10px 0;
  font-size: 16px;
`;

export const RadioText: React.FC = ({ children }) => (
  <RadioTextElement>{children}</RadioTextElement>
);
