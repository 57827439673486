import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Image as ImageType, Widget as WidgetType } from 'studie-core/src/models';
import {
  setNextQuestion,
  setPreviousQuestion,
  updateLoadedActiveWidgetIndex,
  updateStateAsync,
} from '../../state/questionnaire';
import { RootStateType } from '../../state/store';
import { RoutePaths } from '../../views/RoutePaths';
import { DraggableModal } from '../modals/DraggableModal';
import { Widget } from '../widgets/Widget';
import { Footer } from './vertical/Footer';
import { PageHeader } from './vertical/PageHeader';
import { Questionnaire } from './vertical/Questionnaire';
import { WidgetsContainer } from './vertical/WidgetsContainer';
import { WidgetWrapper } from './vertical/WidgetWrapper';

const isImageWidget = (model: Partial<WidgetType>): model is ImageType => model.type === 'image';

export const VerticalQuestionnaire: React.FC = () => {
  const { leadId, securityToken } = useParams<{
    leadId: string;
    securityToken: string;
  }>();
  const history = useHistory();
  const { pages, widgets, activePageIndex, activeWidgetIndex, loadedActiveIndex, widgetsCount } =
    useSelector((e: RootStateType) => e.questionnaire);
  const dispatch = useDispatch();
  const [actionButtonsState, setActionButtonsState] = React.useState({
    isFirstPage: true,
    isLastPage: false,
  });

  const goNext = React.useCallback(() => {
    if (actionButtonsState.isLastPage) {
      dispatch(updateStateAsync.request({ type: 'complete' }));
      history.push({
        pathname: RoutePaths.Done,
        state: { redirected: true },
      });
    } else {
      dispatch(setNextQuestion());

      if (activeWidgetIndex + 1 > loadedActiveIndex) {
        dispatch(updateStateAsync.request({ type: 'update' }));
        dispatch(updateLoadedActiveWidgetIndex());
      }
    }
  }, [actionButtonsState.isLastPage, dispatch, activeWidgetIndex, loadedActiveIndex]);
  const goBack = React.useCallback(() => {
    dispatch(setPreviousQuestion());
  }, [dispatch]);

  React.useEffect(() => {
    if (activeWidgetIndex === 0) {
      setActionButtonsState({
        isFirstPage: true,
        isLastPage: false,
      });
    } else if (widgets.length - 1 === activeWidgetIndex) {
      setActionButtonsState({
        isFirstPage: false,
        isLastPage: true,
      });
    } else if (
      (actionButtonsState.isFirstPage && activeWidgetIndex > 0) ||
      (actionButtonsState.isLastPage && widgets.length - 1 !== activeWidgetIndex)
    ) {
      setActionButtonsState({
        isFirstPage: false,
        isLastPage: false,
      });
    }
  }, [activeWidgetIndex]);

  React.useEffect(() => {
    history.replace({
      pathname: `/${leadId}/${securityToken}/${activeWidgetIndex + 1}`,
    });
  }, [activeWidgetIndex]);

  return (
    <>
      <PageHeader
        overallActiveIndex={activeWidgetIndex + 1}
        widgetsCount={widgetsCount}
        header={pages[activePageIndex].headerText}
      />

      <Questionnaire>
        <WidgetsContainer>
          {widgets.map((widget, index) => {
            const isFullscreenWidget = isImageWidget(widget) && widget.subtype === 'fullscreen';

            return (
              <WidgetWrapper
                key={widget.id}
                isFullscreenWidget={isFullscreenWidget}
                active={activeWidgetIndex === index}
              >
                <Widget widget={widget} isFullscreenWidget={isFullscreenWidget} />

                <DraggableModal content={widget.helpNoteMarkdown} />
              </WidgetWrapper>
            );
          })}
        </WidgetsContainer>
      </Questionnaire>

      <Footer
        isFirstQuestionActive={actionButtonsState.isFirstPage}
        isLastQuestionActive={actionButtonsState.isLastPage}
        goBack={goBack}
        goNext={goNext}
      />
    </>
  );
};
