import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled, { css, ThemeProvider } from 'styled-components';
import { Modal } from '../components/modals/Modal';
import { useWindowSize } from '../hooks/useWindowSize';
import { loadIcons } from '../state/icons';
import { makeStandardTheme } from '../theme';
import { Done } from './done/Done';
import { NotFound } from './global/NotFound';
import { Home } from './home/Home';
import { Questionaire } from './questionaire/Questionaire';
import { RoutePaths } from './RoutePaths';

type ApplicationWrapProps = {
  height: number;
  hasBackgroundColor: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const InternalApplicationWrap: React.FC<ApplicationWrapProps> = ({
  height,
  children,
  hasBackgroundColor,
  ...rest
}) => <div {...rest}>{children}</div>;

const ApplicationWrap = styled(InternalApplicationWrap)`
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: ${({ height }) => `${height}px`};
  max-width: 100%;
  position: relative;

  ${({ hasBackgroundColor }) =>
    hasBackgroundColor &&
    css`
      background: linear-gradient(-180deg, rgba(255, 255, 255, 1) 0%, rgba(236, 239, 241, 1) 100%);
    `};
`;

export const Routes: React.FC = () => {
  const location = useLocation();
  const size = useWindowSize();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadIcons());
  }, [dispatch]);

  return (
    <ThemeProvider theme={makeStandardTheme()}>
      <ApplicationWrap height={size.height} hasBackgroundColor={location.pathname === '/'}>
        <Switch>
          <Route exact={true} path={RoutePaths.Home} component={Home} />

          <Route
            exact={true}
            path="/:leadId/:securityToken/:activeWidgetIndex?"
            component={Questionaire}
          />

          <Route exact={true} path={RoutePaths.Done} component={Done} />

          <Route path="*" component={NotFound} />
        </Switch>

        <Modal />

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
      </ApplicationWrap>
    </ThemeProvider>
  );
};
