import * as React from 'react';
import styled from 'styled-components';
import { RatingsDefinitionStyles } from '../../styles/matrixes/RatingsDefinition.styles';

const RatingsDefinitionElement = styled.div`
  ${RatingsDefinitionStyles}

  position: sticky;
  top: 0;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  z-index: 5;
`;

export const RatingsDefinition: React.FC = ({ children }) => (
  <RatingsDefinitionElement>{children}</RatingsDefinitionElement>
);
