import * as React from 'react';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled, { css } from 'styled-components';

const AgreementLinkElement = styled.div<{ isActive: boolean }>`
  --background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.lightPrimary};
  --border-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};

  background-color: var(--background-color);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid var(--border-color);

  ${({ isActive }) =>
    isActive &&
    css`
      --background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
      --border-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.lightPrimary};
    `}
`;
const Link = styled.a<{ isActive: boolean }>`
  --color: ${({ theme: { defaultColors } }) => defaultColors.black};

  padding: 15px;
  display: block;
  height: 100%;
  color: var(--color);

  ${({ isActive }) =>
    isActive &&
    css`
      --color: ${({ theme: { defaultColors } }) => defaultColors.white};
    `}
`;
const IconSmall = styled(Icon)`
  width: 14px;
  height: 14px;
`;

type Props = {
  url: string;
  isActive: boolean;
};

export const AgreementLink: React.FC<Props> = ({ url, isActive }) => (
  <AgreementLinkElement isActive={isActive}>
    <Link rel="noopener noreferrer" href={url} target="_blank" isActive={isActive}>
      <span>Více</span> <IconSmall name="external-link" />
    </Link>
  </AgreementLinkElement>
);
