import * as React from 'react';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';

const PageHeaderElement = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;

  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  border-bottom: 1px solid ${({ theme: { defaultColors } }) => defaultColors.gray};
`;
const NumberOfPagesElement = styled.div`
  display: flex;
  align-items: center;
`;
const TotalIndexesElement = styled.span`
  font-size: 16px;
  color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};
`;
const PageHeaderContent = styled.div`
  width: 90%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ActiveIndexElement = styled.span`
  border-right: 1px solid ${({ theme: { defaultColors } }) => defaultColors.lightGray};
  padding-right: 5px;
  margin-right: 5px;
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  font-weight: bold;
  display: flex;
`;
const HeaderElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const HeaderElmenet = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;
const IconSmall = styled(Icon)`
  width: 20px;
  height: 20px;
  color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  margin-right: 5px;
`;

type Props = {
  overallActiveIndex: number;
  widgetsCount: number;
  header: string;
};

export const PageHeader: React.FC<Props> = ({ overallActiveIndex, widgetsCount, header }) => (
  <PageHeaderElement>
    <PageHeaderContent>
      <HeaderElement>
        <HeaderElmenet>
          <IconSmall name="bookmark" />
          {header}
        </HeaderElmenet>
      </HeaderElement>

      <NumberOfPagesElement>
        <ActiveIndexElement>{overallActiveIndex}</ActiveIndexElement>
        <TotalIndexesElement>{widgetsCount}</TotalIndexesElement>
      </NumberOfPagesElement>
    </PageHeaderContent>
  </PageHeaderElement>
);
