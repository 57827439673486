import * as React from 'react';
import styled from 'styled-components';

const LogoElement = styled.svg`
  width: 125px;

  @media screen and (min-width: 767px) {
    width: 145px;
  }
`;

export const Logo = () => (
  <LogoElement xmlns="http://www.w3.org/2000/svg" viewBox="0 0 423.31 100.02">
    <title>livesale logo svg</title>
    <path
      fill="#d01419"
      d="M123.31,63.55A59.42,59.42,0,1,0,20.24,103.86a.72.72,0,0,0,.57.28.74.74,0,0,0,.52-.21h0a60.16,60.16,0,0,1,26.13-15.3A3.14,3.14,0,0,0,50,85.54a3.08,3.08,0,0,0-1.4-2.6,24.73,24.73,0,1,1,30.7,0,3.13,3.13,0,0,0,1.09,5.7,60.31,60.31,0,0,1,26.07,15.27.73.73,0,0,0,.55.25.76.76,0,0,0,.54-.23l0,0A59.21,59.21,0,0,0,123.31,63.55Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M151.8,29h2.65v52.2h32.93v2.42H151.8Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M194.56,28.72h3.35v4.76h-3.35Zm.39,15.6h2.57V83.65H195Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M202.2,44.32h2.89l16.15,36.6,16.23-36.6h2.74L222.26,84h-2Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M240,64v-.16c0-11.55,8-20.44,18.8-20.44,11.86,0,18,10.14,18,20.68v.85H242.7c.47,10.69,8,17.33,16.85,17.33A17.84,17.84,0,0,0,273.68,75l1.87,1.48a19.7,19.7,0,0,1-16.07,8.12C248.86,84.59,240,76.55,240,64Zm34.1-1.33c-.39-8.58-5.62-16.93-15.45-16.93-8.9,0-15.45,7.26-15.92,16.93Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M280.85,75.69l1.8-2C288.42,79.2,294.82,82,302.78,82c9.44,0,15.61-5.15,15.61-12v-.16c0-6.4-3.67-9.83-16.86-12.56S283,50.41,283,42.53v-.16c0-7.8,7.41-14.12,17.71-14.12a28.79,28.79,0,0,1,18.58,6.4l-1.64,2.1a26.79,26.79,0,0,0-17.09-6.08c-9,0-14.91,5.38-14.91,11.47v.15c0,6.32,3.67,9.84,16.94,12.57C316.12,57.59,321,62,321,69.61v.15c0,8.43-7.26,14.67-18.42,14.67A30.94,30.94,0,0,1,280.85,75.69Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M327.12,72.57v-.15c0-8.2,7.41-12.65,17.4-12.65A45.28,45.28,0,0,1,358.57,62V59.31c0-8.9-5.31-13.27-14.05-13.27a27.76,27.76,0,0,0-12.79,3.28l-.94-2.19a30.81,30.81,0,0,1,13.89-3.43c5.38,0,9.6,1.4,12.48,4.29,2.5,2.58,3.91,6.32,3.91,11.16v24.5h-2.5V75.46c-2,4.29-7.49,9.13-16.54,9.13C334.46,84.59,327.12,80.53,327.12,72.57Zm31.53-3.43V64.3a44.51,44.51,0,0,0-14.21-2.19c-8.66,0-14.67,3.67-14.67,10.15v.16c0,6.47,5.86,9.83,12.41,9.83C351.7,82.25,358.65,76.16,358.65,69.14Z"
      transform="translate(-4.47 -4.12)"
    />
    <path fill="#13110c" d="M369,26.69h2.58v57H369Z" transform="translate(-4.47 -4.12)" />
    <path
      fill="#13110c"
      d="M377.6,64v-.16c0-11.55,8-20.44,18.81-20.44,11.86,0,18,10.14,18,20.68v.85h-34.1c.47,10.69,8,17.33,16.86,17.33A17.82,17.82,0,0,0,411.31,75l1.88,1.48a19.72,19.72,0,0,1-16.08,8.12C386.5,84.59,377.6,76.55,377.6,64Zm34.1-1.33c-.39-8.58-5.62-16.93-15.45-16.93-8.89,0-15.45,7.26-15.92,16.93Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M417.84,37.56h-2.37v-.19h5v.19h-2.38V44h-.21Z"
      transform="translate(-4.47 -4.12)"
    />
    <path
      fill="#13110c"
      d="M421.66,37.37h.2l2.86,4.08,2.87-4.08h.19V44h-.21V37.72l-2.85,4.06h0l-2.84-4.06V44h-.21Z"
      transform="translate(-4.47 -4.12)"
    />
  </LogoElement>
);
