import { isEmpty } from 'lodash';
import { darken } from 'polished';
import * as React from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'studie-core/src/components/buttons/ApplicationButton';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled, { css, keyframes } from 'styled-components';
import { verifyNapiPasswordAsync, verifyPasswordAsync } from '../../state/livesale-integration';
import { showErrorModal } from '../../state/modal';
import { RootStateType } from '../../state/store';
import { SelectWayForm } from './shared/SelectWayForm';

const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`;
const CallCodeElement = styled.div`
  min-width: 145px;
  max-width: 145px;

  align-self: center;

  color: ${({ theme: { defaultColors, questionnaireColors } }) =>
    !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary};
  text-align: center;

  @media only screen and (min-width: 600px) {
    align-self: start;
  }
`;
const HeadlineElement = styled.span`
  font-size: 16px;
`;
const InputWrapperElement = styled.div`
  position: relative;
  margin-top: 10px;
`;
const InputElemnt = styled.input`
  width: 100%;
  height: 50px;

  border: 1px solid
    ${({ theme: { defaultColors, questionnaireColors } }) =>
      !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary};
  border-radius: 5px;

  font-size: 32px;
  font-weight: normal;
  text-align: center;
`;
const InternalSendCodeElement: React.FC<
  {
    active: boolean;
    validating: boolean;
    onClick: () => void;
  } & Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'ref'
  >
> = ({ onClick, children, ...rest }) => (
  <Button type="button" onClick={() => onClick()} {...rest}>
    {children}
  </Button>
);
const SendCodeElement = styled(InternalSendCodeElement)`
  --primary: ${({ theme: { defaultColors, questionnaireColors } }) =>
    !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary};

  position: absolute;
  right: 0;
  width: 0;
  padding: 0;
  margin: 0;
  top: 0;
  border: none;
  border-radius: 0 5px 5px 0;
  height: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;

  --border-color: var(--primary);
  --background-color: var(--primary);

  ${({ active }) =>
    active &&
    css`
      display: flex;
      width: 40px;
    `}

  ${({ validating }) =>
    validating &&
    css`
      width: 100%;
      border-radius: 5px;
    `}

		&:hover {
    --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.1,
        !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary
      )};
    --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.1,
        !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary
      )};
  }

  &:active {
    --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.17,
        !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary
      )};
    --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.17,
        !isEmpty(questionnaireColors.primary) ? questionnaireColors.primary : defaultColors.primary
      )};
  }

  &:disabled {
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
    --background-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
  }
`;
const LoaderIconSmall = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-right: 5px;

  use {
    animation: ${rotate} 3.5s linear infinite;
    transform-origin: 50% 50%;
  }
`;
const SendIconSmall = styled(Icon)`
  width: 18px;
  height: 18px;

  g {
    animation: ${rotate} 3.5s linear infinite;
    transform-origin: 50% 50%;
  }
`;

const InvalidResponseModalContent = ({ reason }: { reason: string }) => <span>{reason}</span>;

export const CallCode = () => {
  const digitOrLetter = /[a-z0-9]/;
  const mask = [
    digitOrLetter,
    ' ',
    digitOrLetter,
    ' ',
    digitOrLetter,
    ' ',
    digitOrLetter,
    ' ',
    digitOrLetter,
  ];

  const { callPassword, callPasswordNapi } = useSelector((e: RootStateType) => e.livesale);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const [codeEntered, setCodeEntered] = React.useState(false);
  const submitFormButtonRef = React.useRef<HTMLButtonElement>(null);

  const onClickValidate = () => {
    dispatch(verifyNapiPasswordAsync.request({ password: value }));
  };

  React.useEffect(() => {
    setCodeEntered(value.length === 5);
  }, [value]);

  React.useEffect(() => {
    if (!callPasswordNapi.loading) {
      if (callPasswordNapi.error) {
        dispatch(
          showErrorModal({
            content: <InvalidResponseModalContent reason="Při ověřování nastala chyba." />,
          })
        );
      }

      if (!isEmpty(callPasswordNapi.success)) {
        dispatch(
          verifyPasswordAsync.request({
            entryUrl: callPasswordNapi.success!.version2.entryUrl,
            password: callPasswordNapi.success!.version2.code.toString(),
          })
        );
      }

      setValue('');
    }
  }, [dispatch, callPasswordNapi.loading, callPasswordNapi.error, callPasswordNapi.success]);

  React.useEffect(() => {
    if (!callPassword.loading) {
      if (!isEmpty(callPassword.error)) {
        dispatch(
          showErrorModal({
            content: <InvalidResponseModalContent reason={callPassword.error!} />,
          })
        );
      }

      if (!isEmpty(callPassword.verificationResponse)) {
        submitFormButtonRef.current?.click();
      }

      setValue('');
    }
  }, [
    dispatch,
    callPassword.loading,
    callPassword.error,
    callPassword.verificationResponse,
    submitFormButtonRef,
  ]);

  return (
    <CallCodeElement>
      <HeadlineElement>Objednaný hovor</HeadlineElement>

      <InputWrapperElement>
        <InputMask
          mask={mask}
          placeholder="0 0 0 0 0"
          name="call-code"
          id="call-code"
          autoComplete="off"
          value={value}
          onChange={(e) => setValue(e.target.value.replaceAll(' ', '').replaceAll('_', ''))}
        >
          <InputElemnt type="tel" />
        </InputMask>

        <SendCodeElement
          active={codeEntered}
          validating={callPassword.loading || callPasswordNapi.loading}
          disabled={!codeEntered || callPassword.loading || callPasswordNapi.loading}
          onClick={() => onClickValidate()}
        >
          {callPassword.loading || callPasswordNapi.loading ? (
            <LoaderIconSmall name="loader" />
          ) : (
            <SendIconSmall name="send" />
          )}
          {callPassword.loading || (callPasswordNapi.loading && <span>Ověřování...</span>)}
        </SendCodeElement>
      </InputWrapperElement>

      {!isEmpty(callPassword.verificationResponse) && (
        <SelectWayForm
          verificationResponse={callPassword.verificationResponse!}
          submitFormButtonRef={submitFormButtonRef}
          entryUrl={callPasswordNapi.success!.version2.entryUrl}
        />
      )}
    </CallCodeElement>
  );
};
