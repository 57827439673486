import * as React from 'react';
import styled from 'styled-components';
import {
  CheckboxCheckmarkActiveStyles,
  CheckboxCheckmarkStyles,
} from '../../styles/checkboxes/CheckboxCheckmark.styles';

type LineCheckboxCheckmarkElementProps = {
  isActive: boolean;
};

const LineCheckboxCheckmarkElement = styled.div<LineCheckboxCheckmarkElementProps>`
  --border-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  ${CheckboxCheckmarkStyles};

  position: relative;
  margin-top: 1.5px;

  ${({ isActive }) => isActive && CheckboxCheckmarkActiveStyles}
`;

type Props = {
  isActive: boolean;
  className?: string;
};

export const LineCheckboxCheckmark: React.FC<Props> = ({
  isActive,
  className,
}) => (
  <LineCheckboxCheckmarkElement className={className} isActive={isActive} />
);
