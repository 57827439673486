import * as React from 'react';
import { RefCallBack, UseFormTrigger } from 'react-hook-form';
import { Company, FormContacts, Person } from 'studie-core/src/models';
import { InputTextBoxStyles } from 'studie-core/src/styles/inputs/InputTextBox.styles';
import styled, { css } from 'styled-components';
import { FieldErrorProps, useFieldError } from '../../hooks/useFieldError';
import { useHasValue } from '../../hooks/useHasValue';
import { Content, Label, Message, Parent } from '../questionnaires/pages/forms/Common.styles';
import { DispatchFormAnswerData } from './types';

type InternalInputProps = {
  inputRef: RefCallBack;
  hasError: boolean;
  hasValue: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
const InternalInput: React.FC<InternalInputProps> = ({
  hasError,
  hasValue,
  inputRef,
  name,
  id,
  ...rest
}) => <input {...rest} ref={inputRef} name={name} id={id} type="text" />;

const InputElement = styled(InternalInput)`
  ${InputTextBoxStyles}

  min-width: unset;
  width: 100%;

  &:active + label,
  &:focus + label {
    --label-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    transform: translateY(5px) scale(0.8);
    opacity: 1;
  }

  ${({ hasValue }) =>
    hasValue &&
    css`
      & + label {
        transform: translateY(5px) scale(0.8);
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      --border-color: ${({ theme: { defaultColors } }) => defaultColors.red};

      & + label {
        --label-color: ${({ theme: { defaultColors } }) => defaultColors.red};
        opacity: 1;
      }
    `}
`;

type Props = {
  name: string;
  label: string;
  value: string;
  inputRef: RefCallBack;
  onChange: (...event: any[]) => void;
  type?: 'text' | 'email';
  required?: boolean;
  dispatchAnswer: (answer: DispatchFormAnswerData) => void;
  triggerOwnValidation: UseFormTrigger<FormContacts>;
} & FieldErrorProps;

export const FormInput: React.FC<Props> = ({
  name,
  label,
  value,
  inputRef,
  onChange,
  fieldError,
  type = 'text',
  required = false,
  dispatchAnswer,
  triggerOwnValidation,
}) => {
  const [isTouched, setIsTouched] = React.useState(false);
  const hasValue = useHasValue(value, []);
  const { hasError, errorMessage } = useFieldError({ fieldError });
  const labelText = required ? `${label} *` : label;

  const internalOnChange = (value: string) => {
    if (name.split('.')[0] === 'company') {
      dispatchAnswer({
        value,
        type: name.split('.')[0] as 'company',
        property: name.split('.')[1] as keyof Omit<Company, 'id'>,
      });
    } else {
      dispatchAnswer({
        value,
        type: name.split('.')[0] as 'people',
        property: name.split('.')[1] as keyof Omit<Person, 'id' | 'order'>,
      });
    }
  };

  React.useEffect(() => {
    if (!isTouched) {
      if (hasValue) {
        internalOnChange(hasError ? '' : value);

        if (required) {
          triggerOwnValidation(name as keyof FormContacts);
        }
      }
    } else if (hasValue && !hasError) {
      internalOnChange(value);
    } else if (hasError) {
      internalOnChange('');
    }
  }, [isTouched, hasValue, hasError, required, value]);

  return (
    <Parent>
      <Content>
        <InputElement
          defaultValue={value}
          inputRef={inputRef}
          onChange={(e) => {
            setIsTouched(true);
            onChange(e);
          }}
          hasError={hasError}
          hasValue={hasValue}
          name={name}
          id={name}
          type={type}
        />

        <Label htmlFor={name}>{labelText}</Label>
      </Content>

      <Message
        dangerouslySetInnerHTML={{
          __html: (hasError && errorMessage) || '&nbsp;',
        }}
      />
    </Parent>
  );
};
