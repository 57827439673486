import * as React from 'react';
import styled from 'styled-components';
import { Content } from '../../components/home/Content';
import { Navigation } from '../../components/home/Navigation';

const HomeElement = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  padding-left: 5%;
  padding-right: 5%;

  margin: 0 auto;

  color: #000000;

  transition: all 1s ease;

  @media screen and (min-width: 767px) {
    width: 748px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 1023px) {
    width: 948px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width: 1400px) {
    width: 1342px;
  }
`;

export const Home = () => (
  <HomeElement>
    <Navigation />

    <Content />
  </HomeElement>
);
