import { Questionnaire } from 'studie-core/src/models';

type FetchResponse<TSuccess, TError = ErrorResponse> =
  | {
      data: TError;
      status: 400 | 401 | 404 | 500;
      success: false;
    }
  | { data: TSuccess; status: 200 | 204; success: true };

type ErrorResponse = {
  error_id: string;
  error_message: string;
};

async function fetchJson<T>(...args: any): Promise<FetchResponse<T>> {
  const res: Response = await (fetch as any)(...args);
  const json = await res.json();

  if ([200, 204].includes(res.status)) {
    return {
      success: true,
      data: json,
      status: res.status as any,
    };
  }

  return {
    data: json.data,
    status: res.status as any,
    success: false,
  };
}

function apiPost<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers = new Headers({
    Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    'Content-Type': 'application/json',
  })
) {
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(request),
    redirect: 'follow',
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

type ParamsObject = {
  [key: string]: any;
};

function apiGet<TResponse>(
  url: string,
  headers: Headers = new Headers(),
  paramsObject: ParamsObject = {}
) {
  headers.append('Content-Type', 'application/json');
  const queryString = Object.entries(paramsObject)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

  return fetchJson<TResponse>(`${url}?${queryString}`);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function apiPut<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers = new Headers()
) {
  headers.append('Content-Type', 'application/json');

  const raw = JSON.stringify(request);

  const requestOptions = {
    method: 'PUT',
    headers,
    body: raw,
    redirect: 'follow',
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

export const API_ROUTES = {
  Questions_GET: '/v3/callscript/lead/get',
  Lead_Answer_Update_POST: '/v3/callscript/lead/update',
  Lead_State_Update_POST: '/v3/callscript/lead/stateUpdate',
} as const;
const NAPI_ROUTES = {
  ValidateCode_GET: '/v3/one-time-tickets/validate/',
} as const;
const LIVESALE_ROUTES = {
  VerifyPassword_POST: '/Client/VerifyPassword',
  CallOrderUpdate_POST: '/Campaigns/CampaignLeadUpdate',
} as const;

export interface QuestionsRequest {
  definitionUrl: string;
}

export const getQuestionnaire = (
  request: QuestionsRequest
): Promise<FetchResponse<Questionnaire>> => apiGet(`${request.definitionUrl}`);

export interface VerifyNapiPasswordRequest {
  password: string;
}

export interface VerifyNapiPasswordResponse {
  code: string;
  version2: {
    entryUrl: string;
    code: number;
  };
}

export const verifyNapiPassword = (
  request: VerifyNapiPasswordRequest
): Promise<FetchResponse<VerifyNapiPasswordResponse>> =>
  apiGet(`${process.env.REACT_APP_NAPI_URL}${NAPI_ROUTES.ValidateCode_GET}${request.password}`);

export interface VerifyPasswordRequest {
  entryUrl: string;
  password: string;
}
export interface VerifyPasswordResponse {
  IsValid: boolean;
  ErrorMessage: string;
  OriginUrl: string;
  CompanyContactID: number;
  CampaignLeadID: number;
  OperatorID: number;
  CallType: number;
}

export const verifyPassword = (
  request: VerifyPasswordRequest
): Promise<FetchResponse<VerifyPasswordResponse>> =>
  apiPost(`${request.entryUrl}${LIVESALE_ROUTES.VerifyPassword_POST}`, {
    password: request.password,
  });

export interface CallOrderRequest {
  LeadID: string;
  CampaignID: string;
  CallscriptID: string;
  EMail: string;
  Phone: string;
  Date: string;
  Time: string;
}

export const insertCallOrder = (
  url: string,
  request: CallOrderRequest
): Promise<FetchResponse<string>> =>
  apiPost(`${url}${LIVESALE_ROUTES.CallOrderUpdate_POST}`, request);

export interface UpdateAnswerRequest {
  leadId: string;
  securityToken: string;
  widgetId: string;
  answer: any;
}

export const updateWidgetAnswer = (request: UpdateAnswerRequest) =>
  apiPost(`${process.env.REACT_APP_API_URL}${API_ROUTES.Lead_Answer_Update_POST}`, request);

export interface UpdateLeadState {
  leadId: string;
  securityToken: string;
  activeWidgetIndex: number;
  widgetId: string;
  completed: boolean;
}

export const updateLeadState = (request: UpdateLeadState) =>
  apiPost(`${process.env.REACT_APP_API_URL}${API_ROUTES.Lead_State_Update_POST}`, request);
