import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LineCheckboxCheckmark } from 'studie-core/src/components/checkboxes/LineCheckboxCheckmark';
import { AgreementsValue } from 'studie-core/src/models';
import {
  LineCheckboxTextActiveStyles,
  LineCheckboxTextStyles,
} from 'studie-core/src/styles/checkboxes/LineCheckboxText.styles';
import styled, { css } from 'styled-components';
import {
  checkValidity,
  updateAgreement,
  updateAgreementValidity,
  updateAnswerAsync,
} from '../../state/questionnaire';
import { AgreementLink } from './AgreementLink';

const AgreementElement = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;
const LineCheckboxElement = styled.div<{ isActive: boolean; hasLink: boolean }>`
  --background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.lightPrimary};

  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: var(--background-color);
  min-height: 60px;
  padding: 15px;
  flex: 1;

  ${({ isActive }) =>
    isActive &&
    css`
      --background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    `}

  ${({ hasLink }) =>
    hasLink &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

	user-select: none;
  cursor: pointer;
`;
const Checkmark = styled(LineCheckboxCheckmark)``;
const LineCheckboxTextElement = styled.div<{ isActive: boolean }>`
  ${LineCheckboxTextStyles};

  ${({ isActive }) => isActive && LineCheckboxTextActiveStyles};
`;

type Props = {
  widgetId: string;
  agreement: AgreementsValue;
  answer?: AgreementsValue;
};

export const Agreement: React.FC<Props> = ({ widgetId, agreement, answer }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      updateAgreement({
        widgetId,
        selectedAnswerId: agreement.id,
      })
    );
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      updateAgreementValidity({
        widgetId,
      })
    );
    dispatch(checkValidity());
  }, [dispatch, widgetId]);

  return (
    <AgreementElement>
      <LineCheckboxElement
        isActive={!!answer}
        onClick={() => onClick && onClick()}
        hasLink={!isEmpty(agreement.url)}
      >
        <Checkmark isActive={!!answer} />

        <LineCheckboxTextElement isActive={!!answer}>{agreement.text}</LineCheckboxTextElement>
      </LineCheckboxElement>

      {agreement.url && <AgreementLink url={agreement.url} isActive={!!answer} />}
    </AgreementElement>
  );
};
