import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'studie-core/src/components/buttons/Button';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled, { css } from 'styled-components';
import { hideModal } from '../../state/modal';
import { RootStateType } from '../../state/store';

const ModalElement = styled.div<{ active: boolean }>`
  position: fixed;

  ${({ active }) =>
    active &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
    `};
`;
const ModalContentElement = styled.div`
  display: flex;
  flex-direction: column;

  width: 650px;
  min-height: 200px;
  height: fit-content;
  max-height: 600px;

  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;

  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  box-shadow: 0 0 7px 0 ${({ theme: { defaultColors } }) => defaultColors.darkGray};

  @media only screen and (min-width: 600px) {
    margin-top: 100px;
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 142px;
  }
`;
const HeaderSectionElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme: { defaultColors } }) => defaultColors.lightGray};

  h4 {
    margin: 0;
  }
`;
const CloseButtonElement = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
`;
const ContentElement = styled.div`
  padding: 20px;
  flex: 1 0 auto;
`;
const ActionsElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 10px 20px;
  border-top: 1px solid ${({ theme: { defaultColors } }) => defaultColors.lightGray};
`;

// This component needs parent of type ThemeProvider
export const Modal = () => {
  const {
    active,
    content: ModalContent,
    isError,
    buttons,
    headerText,
  } = useSelector((e: RootStateType) => e.modal);
  const dispatch = useDispatch();
  const modalRef = React.useRef<HTMLDivElement>(null);

  const onClick = (e: React.MouseEvent) => {
    if (e.target === modalRef?.current) {
      dispatch(hideModal());
    }
  };

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(hideModal());
  };

  return (
    <ModalElement ref={modalRef} active={active} onClick={onClick}>
      {active && (
        <ModalContentElement>
          <HeaderSectionElement>
            <h4>{isError ? 'Nastal neočekávaný problém' : headerText}</h4>

            <CloseButtonElement type="button" onClick={onClickClose}>
              <Icon width={24} height={24} name="close" />
            </CloseButtonElement>
          </HeaderSectionElement>

          <ContentElement>{ModalContent}</ContentElement>

          <ActionsElement>
            <Button outlined={!isError} onClick={onClickClose}>
              Zavřít
            </Button>

            {buttons}
          </ActionsElement>
        </ModalContentElement>
      )}
    </ModalElement>
  );
};
