import { isEmpty } from 'lodash';
import { darken } from 'polished';
import { css } from 'styled-components';

export const ButtonBaseStyles = css`
  --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
    !isEmpty(questionnaireColors.primary)
      ? questionnaireColors.primary
      : defaultColors.primary};
  --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
    !isEmpty(questionnaireColors.primary)
      ? questionnaireColors.primary
      : defaultColors.primary};
  --color: white;

  border: 2px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--color);
  padding: 0 24px;
  border-radius: 28px;
  margin: 0;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonStyles = css`
  &:hover {
    --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.1,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
    --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.1,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
  }

  &:active {
    --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.17,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
    --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.17,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
  }

  &:disabled {
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
    --background-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
  }
`;

export const ButtonOutlinedStyles = css`
  --background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  --color: ${({ theme: { defaultColors, questionnaireColors } }) =>
    !isEmpty(questionnaireColors.primary)
      ? questionnaireColors.primary
      : defaultColors.primary};

  &:hover {
    --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.1,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
    --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.1,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
    --color: ${({ theme: { defaultColors } }) => defaultColors.white};
  }

  &:active {
    --background-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.17,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
    --border-color: ${({ theme: { defaultColors, questionnaireColors } }) =>
      darken(
        0.17,
        !isEmpty(questionnaireColors.primary)
          ? questionnaireColors.primary
          : defaultColors.primary
      )};
    --color: ${({ theme: { defaultColors } }) => defaultColors.white};
  }

  &:disabled {
    --background-color: ${({ theme: { defaultColors } }) =>
      defaultColors.white};
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
    --color: ${({ theme: { defaultColors } }) => defaultColors.gray};
  }

  &:disabled:hover {
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};
    --color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};
  }
`;
