import { css } from 'styled-components';

export const RangeStyles = css`
  .noUi-target {
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
    display: flex;
    align-items: center;
    height: 42px;
    padding: 0 21px;
  }

  .noUi-base {
    background-color: ${({ theme: { questionnaireColors } }) =>
      questionnaireColors.lightPrimary};
    border-radius: 10px;
    box-shadow: none;
    height: 10px;
  }

  .noUi-horizontal .noUi-handle {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    right: -21px;
    top: 50%;
    transform: translateY(-16px);
    border-width: 0;
  }

  .noUi-handle {
    cursor: pointer;
    background-color: ${({ theme: { questionnaireColors } }) =>
      questionnaireColors.primary};
    box-shadow: none;
  }

  .noUi-handle:before,
  .noUi-handle:after {
    display: none;
  }

  .noUi-touch-area:after {
    content: '';

    position: absolute;
    width: 21px;
    height: 21px;
    background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .noUi-value {
    top: 0;
    cursor: pointer;
  }

  .noUi-value-horizontal {
    transform: translate(-50%, 10%);
  }
`;
