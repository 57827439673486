import { css } from 'styled-components';

export const CheckboxIconStyles = css`
  --color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color);
  max-height: 69px;
`;

export const CheckboxIconActiveStyles = css`
  --color: ${({ theme: { defaultColors } }) => defaultColors.white};
`;
