import * as React from 'react';
import { Props } from './props';

export const equalityComparer = (
  prevProps: Readonly<React.PropsWithChildren<Props>>,
  nextProps: Readonly<React.PropsWithChildren<Props>>
): boolean =>
  prevProps.model === nextProps.model &&
  prevProps.widgetId === nextProps.widgetId &&
  prevProps.isValid === nextProps.isValid;
