import { tint } from 'polished';
import { DefaultTheme } from 'styled-components';

const colorDefaults = {
  gray: '#C7C7C7',
  black: '#000000',
  white: '#FFFFFF',
  red: 'red',
  darkGray: '#666666',
  lightGray: '#EDEDED',
  warning: '#ECAE1B',
  primary: '#D01419',
};

const makeStandardTheme = (): DefaultTheme => ({
  defaultColors: {
    ...colorDefaults,
  },
  questionnaireColors: {
    primary: '',
    lightPrimary: '',
  },
});

const makeQuestionnaireTheme = ({ primaryColor }: { primaryColor: string }): DefaultTheme => ({
  defaultColors: {
    ...colorDefaults,
  },
  questionnaireColors: {
    primary: primaryColor,
    lightPrimary: tint(0.9, primaryColor),
  },
});

export { makeStandardTheme, makeQuestionnaireTheme };
