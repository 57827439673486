import * as React from 'react';
import styled from 'styled-components';
import { AnswersStyles } from '../../styles/matrixes/Answers.styles';

const AnswersElement = styled.div`
  ${AnswersStyles}
`;

export const Answers: React.FC = ({ children }) => (
  <AnswersElement>{children}</AnswersElement>
);
