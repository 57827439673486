import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { RootStateType } from '../../../state/store';

const LeftSectionElement = styled.div`
  background-image: var(--background-image-mobile);
  background-size: cover;
  background-position: center center;
  min-height: 250px;

  @media only screen and (min-width: 1200px) {
    background-image: var(--background-image-desktop);
  }
`;

const RightSectionElement = styled.div`
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    padding: 100px 100px 30px 100px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 142px 142px 40px 142px;
  }
`;

const ContentElement = styled.div`
  flex: 1 0 auto;
`;

type LandingElementProps = { height: number } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const InternalLandingElement: React.FC<LandingElementProps> = ({ height, children, ...rest }) => (
  <div {...rest}>{children}</div>
);

const LandingElement = styled(InternalLandingElement)`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  display: flex;
  flex-direction: column;
  font-size: 20px;
  overflow: auto;

  > div {
    width: 100%;
  }

  @media only screen and (min-width: 600px) and (orientation: portrait) {
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    > div:first-of-type {
      flex-basis: 30%;
    }

    > div:last-of-type {
      flex-basis: 70%;

      min-width: 725px;
    }
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    > div:first-of-type {
      flex-basis: 50%;
    }

    > div:last-of-type {
      flex-basis: 50%;

      min-width: 820px;
    }
  }
`;

const PoweredByElement = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding: 15px 0;
  color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};

  @media only screen and (min-width: 600px) {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1200px) {
    justify-content: flex-start;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }
`;

export const Landing: React.FC = React.memo(() => {
  const { activePage: ActivePage, activePageName } = useSelector((e: RootStateType) => e.landing);
  const {
    questionnaire: {
      systemPages: {
        welcome: { image },
      },
    },
  } = useSelector((e: RootStateType) => e.questionnaire);
  const [canShowLeftSection, setCanShowLeftSection] = React.useState(true);
  const isMobile = useMediaQuery({
    query: 'only screen and (max-width: 599px)',
  });
  const { height } = useWindowSize();

  React.useEffect(() => {
    if (isMobile && activePageName !== 'Welcome') {
      setCanShowLeftSection(false);
    } else {
      setCanShowLeftSection(true);
    }
  }, [isMobile, activePageName]);

  return (
    <LandingElement height={height} className="scrollable">
      {canShowLeftSection && (
        <LeftSectionElement
          style={
            {
              '--background-image-desktop': `url('${image.desktopUrl}')`,
              '--background-image-mobile': `url('${image.mobileUrl}')`,
            } as React.CSSProperties
          }
        />
      )}

      <RightSectionElement>
        <ContentElement>
          <ActivePage />
        </ContentElement>

        <PoweredByElement>
          <span>Powered by&nbsp;</span>

          <a href="https://livesale.cz/" target="_blank" rel="noreferrer">
            LiveSale
          </a>
        </PoweredByElement>
      </RightSectionElement>
    </LandingElement>
  );
});
