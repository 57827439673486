import * as React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';
import { RootStateType } from '../../../state/store';
import { NextButton } from '../shared/NextButton';
import { PreviousButton } from '../shared/PreviousButton';

const FooterElement = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0px;

  height: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  border-top: 1px solid ${({ theme: { defaultColors } }) => defaultColors.gray};
`;
const NotificationElement = styled.div`
  position: absolute;
  top: -65px;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.warning};
  padding: 15px;
  border-radius: 10px;

  display: flex;
  align-items: center;

  font-size: 16px;

  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${({ theme: { defaultColors } }) => defaultColors.warning};
    top: calc(100% - 7px);
    left: 50%;
    transform: rotate(45deg);
  }
`;
const SmallIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;
const ActionsElement = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  isFirstQuestionActive: boolean;
  isLastQuestionActive: boolean;
  goBack: () => void;
  goNext: () => void;
};

export const Footer: React.FC<Props> = ({
  isFirstQuestionActive,
  isLastQuestionActive,
  goBack,
  goNext,
}) => {
  const { widgets, activeWidgetIndex } = useSelector((e: RootStateType) => e.questionnaire);
  const [showAlert, setShowAlert] = React.useState(false);
  const alertTimeoutRef = React.useRef<number>(0);

  const handleOnClick = () => {
    if (!isLastQuestionActive || !window.isDemo) {
      goNext();
    }
  };

  const onDisabledClick = () => {
    window.clearTimeout(alertTimeoutRef.current);
    setShowAlert(true);

    alertTimeoutRef.current = window.setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  React.useEffect(() => {
    setShowAlert(false);
  }, [activeWidgetIndex]);

  return (
    <FooterElement>
      {showAlert && (
        <NotificationElement>
          <SmallIcon name="alert" />
          <span>Abyste mohli pokračovat, zodpovězte prosím otázku.</span>
        </NotificationElement>
      )}

      <ActionsElement>
        <PreviousButton onClick={goBack} disabled={isFirstQuestionActive} />

        <NextButton
          onClick={handleOnClick}
          disabled={!window.isDemo && !widgets[activeWidgetIndex].valid}
          useNativeDisabled={false}
          onDisabledClick={() => onDisabledClick()}
        >
          {isLastQuestionActive ? 'Odeslat' : 'Další'}
        </NextButton>
      </ActionsElement>
    </FooterElement>
  );
};
