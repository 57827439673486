import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import './normalize.css';
import { useApplicationStore } from './state/store';
import { Routes } from './views/Routes';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    font-family: "neue-haas-unica", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    background-color: rgb(255, 255, 255);
    backface-visibility: hidden;
  }

  * {
    box-sizing: border-box;
  }

  button, input, optgroup, select, textarea {
    font-family: inherit !important;
  }
`;

export const App = () => {
  const appStore = useApplicationStore();

  return (
    <>
      <GlobalStyle />

      <Provider store={appStore.store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </>
  );
};
