import * as React from 'react';
import {
  LineCheckboxTextActiveStyles,
  LineCheckboxTextStyles,
} from 'studie-core/src/styles/checkboxes/LineCheckboxText.styles';
import styled from 'styled-components';

type LineCheckboxTextElementProps = {
  isActive: boolean;
};

const LineCheckboxTextElement = styled.div<LineCheckboxTextElementProps>`
  ${LineCheckboxTextStyles};

  ${({ isActive }) => isActive && LineCheckboxTextActiveStyles};
`;

type Props = {
  isActive: boolean;
};

export const LineCheckboxText: React.FC<Props> = ({ isActive, children }) => (
  <LineCheckboxTextElement isActive={isActive}>{children}</LineCheckboxTextElement>
);
