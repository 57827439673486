import { css } from 'styled-components';
import {
  RadioCheckmarkActiveStyles,
  RadioCheckmarkStyles,
} from './RadioCheckmark.styles';

export const LineRadioCheckmarkStyles = css`
  --border-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  ${RadioCheckmarkStyles};

  position: relative;
  margin-top: 1.5px;
`;

export const LineRadioCheckmarkActiveStyles = css`
  ${RadioCheckmarkActiveStyles}
`;
