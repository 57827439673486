import * as React from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from '../../../hooks/useWindowSize';

type WidgetWrapperElementProps = {
  width: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const InternalWidgetWrapperElement: React.FC<WidgetWrapperElementProps> = ({
  children,
  width,
  ...rest
}) => <div {...rest}>{children}</div>;

const WidgetWrapperElement = styled(InternalWidgetWrapperElement)`
  width: ${({ width }) => `${width}px`};
  overflow: auto;
  filter: blur(0); // fixes blurry text when sliding widgets on Edge
  transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
`;

const ContentElment = styled.div<{ isFullscreenWidget: boolean }>`
  margin: 0 auto;

  ${({ isFullscreenWidget }) =>
    isFullscreenWidget &&
    css`
      height: 100%;
      margin: auto;
    `};

  @media only screen and (min-width: 1200px) {
    width: 1100px;

    ${({ isFullscreenWidget }) =>
      isFullscreenWidget &&
      css`
        width: 100%;
      `};
  }
`;

type Props = {
  active: boolean;
  isFullscreenWidget: boolean;
};

export const WidgetWrapper: React.FC<Props> = ({ isFullscreenWidget, active, children }) => {
  const windowSize = useWindowSize();

  return (
    <WidgetWrapperElement width={windowSize.width} className={classname(active)}>
      <ContentElment isFullscreenWidget={isFullscreenWidget}>{children}</ContentElment>
    </WidgetWrapperElement>
  );
};

const classname = (active: boolean) => (active ? 'scrollable active' : 'scrollable');
