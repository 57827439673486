import * as React from 'react';
import { LineRadioCheckmark } from 'studie-core/src/components/radios/LineRadioCheckmark';
import {
  LineRadioActiveStyles,
  LineRadioStyles,
} from 'studie-core/src/styles/radios/LineRadio.styles';
import styled from 'styled-components';
import { LineRadioText } from './LineRadioText';

type RadioElementProps = {
  isActive: boolean;
};

const RadioElement = styled.div<RadioElementProps>`
  --background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.lightPrimary};

  ${LineRadioStyles}

  min-width: 150px;

  ${({ isActive }) => isActive && LineRadioActiveStyles}
`;

const CustomLineRadioText = styled(LineRadioText)`
  text-align: center;
  justify-content: center;
`;

type Props = {
  text: string;
  onClick: () => void;
} & RadioElementProps;

export const Choice: React.FC<Props> = ({ text, isActive, onClick }) => (
  <RadioElement isActive={isActive} onClick={onClick}>
    <LineRadioCheckmark isActive={isActive} />

    <CustomLineRadioText isActive={isActive}>{text}</CustomLineRadioText>
  </RadioElement>
);
