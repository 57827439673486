import { isEmpty } from 'lodash';
import noUiSlider, { API } from 'nouislider';
import 'nouislider/dist/nouislider.css';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RangeValue } from 'studie-core/src/models';
import { RangeStyles } from 'studie-core/src/styles/ranges/Range.styles';
import styled, { css } from 'styled-components';
import { checkValidity, updateAnswerAsync, updateRange } from '../../state/questionnaire';

const WrapperElement = styled.div`
  position: relative;

  width: 650px;
  margin: 0 auto;
`;

const RangeWrapperElement = styled.div`
  ${RangeStyles};
`;

const PipsElement = styled.div`
  position: absolute;
  height: 20px;
  padding: 0;
  width: 100%;
  margin-top: 15px;
`;
const PercentageStyles = css`
  position: absolute;
`;
const LeftPercentageElement = styled.div`
  ${PercentageStyles}

  left: 0%;
`;
const RightPercentageElement = styled.div`
  ${PercentageStyles}

  right: 0%;
  text-align: right;
`;

const TextElement = styled.div`
  color: ${({ theme: { defaultColors } }) => defaultColors.black};
`;

const NumberElement = styled.div`
  color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
`;

type Props = {
  widgetId: string;
  model: RangeValue;
  answer: RangeValue;
};

export const Range: React.FC<Props> = ({ widgetId, model, answer }) => {
  const [leftTitle, rightTitle] = model.parts;
  const [defaultLeft, defaultRight] = !isEmpty(answer) ? answer?.values : model.values;
  const [slider, setSlider] = React.useState<API>();
  const [[left, right], setValues] = React.useState<number[]>([defaultLeft, defaultRight]);
  const sliderContainer = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const onChange = (values: [number, number]) => {
    dispatch(
      updateRange({
        widgetId,
        selectedAnswerId: model.id,
        values,
      })
    );
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  const createSlider = () => {
    const rangeValues = Array.from({ length: 101 }, (_, i) => i);
    const sliderComponent = noUiSlider.create(sliderContainer.current!, {
      start: defaultRight,
      range: {
        min: rangeValues[0],
        max: rangeValues[rangeValues.length - 1],
      },
      step: 1,
    });

    const onSetSlider = (_: (number | string)[], __: number, unencoded: number[]) => {
      const realValue = Math.trunc(unencoded[0]);
      onChange([100 - realValue, realValue]);
      setValues([100 - realValue, realValue]);
    };

    sliderComponent.on('set', onSetSlider);

    if (answer === null || answer === undefined || Object.keys(answer).length === 0) {
      sliderComponent!.set([defaultLeft, defaultRight]);
    }

    setSlider(sliderComponent);
  };

  React.useEffect(() => {
    const sliderHTML = sliderContainer.current;
    if (sliderHTML) {
      createSlider();
    }

    return () => {
      if (slider) slider.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperElement>
      <RangeWrapperElement>
        <div ref={sliderContainer} />
      </RangeWrapperElement>

      <PipsElement>
        <LeftPercentageElement>
          <TextElement>{leftTitle}</TextElement>
          <NumberElement>{left} %</NumberElement>
        </LeftPercentageElement>

        <RightPercentageElement>
          <TextElement>{rightTitle}</TextElement>
          <NumberElement>{right} %</NumberElement>
        </RightPercentageElement>
      </PipsElement>
    </WrapperElement>
  );
};
