import { Icon } from 'studie-core/src/models';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { systemIcons } from './systemIcons';

const svgContainer = document.getElementById('icons-container')! as SVGElement & HTMLElement;

type StatefullIcon = {
  isSystemIcon: boolean;
} & Icon;

type IconsState = {
  icons: StatefullIcon[];
};

export const loadIcons = createAction('@icons/LOAD_ICONS')();
export const setQuestionnaireIcons = createAction('@icons/SET_QUESTIONNAIRE_ICONS')<{
  icons: Icon[];
}>();

export type IconsAction = ActionType<typeof loadIcons> | ActionType<typeof setQuestionnaireIcons>;

export const iconsReducer = createReducer<IconsState, IconsAction>({
  icons: [...systemIcons],
})
  .handleAction(loadIcons, (state) => {
    registerIcons(state.icons);

    return { ...state };
  })
  .handleAction(setQuestionnaireIcons, (state, action) => {
    const customIcons = action.payload.icons.map((icon) => ({
      isSystemIcon: false,
      ...icon,
    }));
    registerIcons(customIcons);

    return {
      ...state,
      icons: [...state.icons, ...customIcons],
    };
  });

const registerIcons = (icons: Icon[]) => {
  icons.forEach((icon: Icon) => {
    const parser = new DOMParser();
    const symbol = parser.parseFromString(icon.svg, 'image/svg+xml').querySelector('symbol')!;
    svgContainer.insertAdjacentElement('beforeend', symbol);
  });
};
