import * as React from 'react';
import styled from 'styled-components';

const ValidationTextElement = styled.div`
  color: ${({ theme: { defaultColors } }) => defaultColors.red};
  position: absolute;
  left: 15px;
  top: calc(100% + 5px);
  font-size: 14px;
`;

export const ValidationText = () => (
  <ValidationTextElement>Toto pole je povinné</ValidationTextElement>
);
