import * as React from 'react';
import styled from 'styled-components';
import { Logo } from './Logo';

const NavigationElement = styled.div`
  box-sizing: content-box;

  padding: 20px 0;
`;

export const Navigation = () => (
  <NavigationElement>
    <Logo />
  </NavigationElement>
);
