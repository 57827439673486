import * as React from 'react';
import styled from 'styled-components';
import {
  LineRadioCheckmarkActiveStyles,
  LineRadioCheckmarkStyles,
} from '../../styles/radios/LineRadioCheckmark.styles';

type LineRadioCheckmarkElementProps = {
  isActive: boolean;
};

const LineRadioCheckmarkElement = styled.div<LineRadioCheckmarkElementProps>`
  --border-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  ${LineRadioCheckmarkStyles}

  ${({ isActive }) => isActive && LineRadioCheckmarkActiveStyles}
`;

type Props = {
  isActive: boolean;
};

export const LineRadioCheckmark: React.FC<Props> = ({ isActive }) => (
  <LineRadioCheckmarkElement isActive={isActive} />
);
