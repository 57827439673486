import { css } from 'styled-components';

export const SimpleTextStyles = css`
  width: 650px;
  max-width: 650px;
  word-break: break-word;
  font-size: x-large;

  h1 {
    margin-top: 0;
  }
`;
