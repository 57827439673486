import * as React from 'react';
import styled from 'styled-components';
import { RatingDefinitionStyles } from '../../styles/matrixes/RatingDefinition.styles';

const RatingDefinitionElement = styled.div`
  ${RatingDefinitionStyles}
`;

export const RatingDefinition: React.FC = ({ children }) => (
  <RatingDefinitionElement>
    <span>{children}</span>
  </RatingDefinitionElement>
);
