import { css } from 'styled-components';

export const LineCheckboxTextStyles = css`
  --color: ${({ theme: { defaultColors } }) => defaultColors.black};

  color: var(--color);
  display: flex;
  width: 100%;
  font-size: 18px;
  margin-left: 10px;
`;

export const LineCheckboxTextActiveStyles = css`
  --color: ${({ theme: { defaultColors } }) => defaultColors.white};
`;
