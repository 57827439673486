import * as React from 'react';
import styled from 'styled-components';
import { CallCode } from './CallCode';
import { Description } from './Description';
import { PromoImage } from './PromoImage';

const ContentElement = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const CenterSectionElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
const BottomSectionElement = styled.div`
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;

  transition: all 1s ease;

  @media only screen and (min-width: 600px) {
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 80px;

    div {
      width: 50%;
    }
  }
`;

export const Content = () => (
  <ContentElement>
    <CenterSectionElement>
      <CallCode />
    </CenterSectionElement>

    <BottomSectionElement>
      <Description />

      <PromoImage />
    </BottomSectionElement>
  </ContentElement>
);
