import { tint } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

const HeaderElement = styled.div<{ isFullscreenWidget: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  padding: 15px 0 75px 0;

  @media only screen and (min-width: 600px) {
    padding: 50px 0;
    height: 25%;
  }

  ${({ isFullscreenWidget }) =>
    isFullscreenWidget &&
    css`
      display: none !important;
    `}
`;

const HeaderText = styled.h1`
  color: ${({ theme: { defaultColors } }) => defaultColors.black};
  font-size: 25px;
  margin: 0;
  margin-bottom: 15px;

  @media only screen and (min-width: 600px) {
    font-size: 36px;
  }
`;

const SubHeaderElement = styled.span`
  color: ${({ theme: { defaultColors } }) => tint(0.5, defaultColors.black)};
  font-size: 15px;

  @media only screen and (min-width: 600px) {
    font-size: 20px;
  }
`;

type Props = {
  isFullscreenWidget: boolean;
  header: string;
  subheader: string;
};

export const Header: React.FC<Props> = ({ isFullscreenWidget, header, subheader }) => (
  <HeaderElement isFullscreenWidget={isFullscreenWidget}>
    <HeaderText>{header}</HeaderText>
    <SubHeaderElement>{subheader}</SubHeaderElement>
  </HeaderElement>
);
