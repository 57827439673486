import * as React from 'react';
import { Remarkable } from 'remarkable';
import { ImageWithTextValue } from 'studie-core/src/models';
import {
  ImageStyles,
  ImageWithTextStyles,
  TextStyles,
} from 'studie-core/src/styles/visual/ImageWithText.styles';
import styled from 'styled-components';

const TextElement = styled.div`
  ${TextStyles};

  h1 {
    color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  }
`;
const ImageElement = styled.img`
  ${ImageStyles};
`;
const ImageWithTextElement = styled.div`
  ${ImageWithTextStyles};

  @media only screen and (min-width: 600px) {
    width: 600px;
  }

  @media only screen and (min-width: 1200px) {
    width: 800px;
    flex-direction: row;

    ${ImageElement} {
      width: calc(50% - 30px);
    }

    ${TextElement} {
      width: 50%;
    }
  }
`;

type Props = {
  model: ImageWithTextValue;
};

export const ImageWithText: React.FC<Props> = ({ model }) => {
  const getRawMarkup = () => {
    const md = new Remarkable({ xhtmlOut: true, breaks: true });
    return { __html: md.render(model.textMarkdown) };
  };

  const getIamge = () => <ImageElement src={model.imageUrl} />;

  return (
    <ImageWithTextElement>
      {model.position === 'left' && getIamge()}

      <TextElement dangerouslySetInnerHTML={getRawMarkup()} />

      {model.position === 'right' && getIamge()}
    </ImageWithTextElement>
  );
};
