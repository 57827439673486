import * as React from 'react';
import { RefCallBack } from 'react-hook-form';
import { CheckboxCheckmark } from 'studie-core/src/components/checkboxes/CheckboxCheckmark';
import styled from 'styled-components';

const CheckboxElement = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const InputElement = styled.input`
  display: none;
`;

const CustomCheckmark = styled(CheckboxCheckmark)`
  --border-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};

  position: relative;
  margin-right: 10px;
`;

type Props = {
  name: string;
  checked: boolean;
  inputRef: RefCallBack;
  onChange: (...event: any[]) => void;
  dispatchIsCompanyContactChange: (value: boolean) => void;
};

export const Checkbox: React.FC<Props> = ({
  name,
  checked,
  inputRef,
  onChange,
  dispatchIsCompanyContactChange,
}) => (
  <CheckboxElement>
    <InputElement
      ref={inputRef}
      name={name}
      onChange={(e) => {
        onChange(e.target.checked);
        dispatchIsCompanyContactChange(e.target.checked);
      }}
      defaultChecked={checked}
      type="checkbox"
    />

    <CustomCheckmark isActive={checked} />

    <span>Zastupuji společnost</span>
  </CheckboxElement>
);
