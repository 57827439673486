import * as React from 'react';
import styled from 'styled-components';
import { MatrixStyles } from '../../styles/matrixes/Matrix.styles';

const MatrixElement = styled.div`
  ${MatrixStyles}

  margin: 0 auto;
`;

export const Matrix: React.FC = ({ children }) => (
  <MatrixElement>{children}</MatrixElement>
);
