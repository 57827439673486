import { isEmpty } from 'lodash';
import * as React from 'react';

export const useHasValue = <T>(value: T, charsToIgnore: string[]) => {
  const [hasValue, setHasValue] = React.useState(false);

  React.useEffect(() => {
    if (isEmpty(value)) {
      setHasValue(false);
    } else {
      let v = String(value);

      charsToIgnore.forEach((char) => (v = v.replaceAll(char, '').trim()));

      setHasValue(!isEmpty(v));
    }
  }, [value, charsToIgnore]);

  return hasValue;
};
