import * as React from 'react';

type Props = {
  query: string;
};

export const useMediaQuery = ({ query }: Props) => {
  const media = window.matchMedia(query);

  const [matches, setMatches] = React.useState(media.matches);

  React.useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches);

    media.addEventListener('change', handler, true);

    return () => media.removeEventListener('change', handler, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return matches;
};
