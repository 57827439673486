import * as React from 'react';

export const Draggable = (ref: React.RefObject<HTMLElement>) => {
  React.useEffect(() => {
    const target = ref.current!;

    if (!target) {
      return undefined;
    }

    let offsetX = 0;
    let offsetY = 0;
    let currentX: number;
    let currentY: number;
    let initialX: number;
    let initialY: number;

    const isTouchStart = (event: MouseEvent | TouchEvent): event is TouchEvent =>
      event.type === 'touchstart';

    function onMousedown(e: MouseEvent | TouchEvent) {
      if (isTouchStart(e)) {
        initialX = e.touches[0].clientX - offsetX;
        initialY = e.touches[0].clientY - offsetY;
        window.addEventListener('touchmove', onMouseMove);
        window.addEventListener('touchend', onMouseUp);
      } else {
        initialX = e.clientX - offsetX;
        initialY = e.clientY - offsetY;
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
      }
    }

    const isTouchMove = (event: MouseEvent | TouchEvent): event is TouchEvent =>
      event.type === 'touchmove';

    function onMouseMove(e: MouseEvent | TouchEvent) {
      if (isTouchMove(e)) {
        currentX = e.touches[0].clientX - initialX;
        currentY = e.touches[0].clientY - initialY;
      } else {
        currentX = e.clientX - initialX;
        currentY = e.clientY - initialY;
      }

      offsetX = currentX;
      offsetY = currentY;
      target.style.transform = `translate(${currentX}px, ${currentY}px)`;
    }

    function onMouseUp(e: MouseEvent | TouchEvent) {
      initialX = currentX;
      initialY = currentY;
      if (e.type === 'touchend') {
        window.removeEventListener('touchmove', onMouseMove);
        window.removeEventListener('touchend', onMouseUp);
      } else {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
      }
    }

    target.addEventListener('mousedown', onMousedown);
    target.addEventListener('touchstart', onMousedown);

    return () => {
      target.removeEventListener('mousedown', onMousedown);
      target.removeEventListener('touchstart', onMousedown);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchend', onMouseUp);
      window.removeEventListener('touchmove', onMouseMove);
    };
  }, [ref]);
};

export default Draggable;
