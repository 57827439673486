import * as React from 'react';
import styled from 'styled-components';
import { CheckboxValue } from '../../models';
import {
  CheckboxActiveStyles,
  CheckboxStyles,
} from '../../styles/checkboxes/Checkbox.styles';
import { CheckboxCheckmark } from './CheckboxCheckmark';
import { CheckboxCounter, CounterPlaceholder } from './CheckboxCounter';
import { CheckboxIcon } from './CheckboxIcon';

type CheckboxElementProps = {
  isActive: boolean;
};

const CheckboxElement = styled.div<CheckboxElementProps>`
  ${CheckboxStyles}

  ${({ isActive }) => isActive && CheckboxActiveStyles}
`;
const ClientCheckboxCheckmark = styled(CheckboxCheckmark)`
  --border-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};
`;

type Props = {
  isActive: boolean;
  textComponent: React.ComponentType<{
    isActive: boolean;
    onClick: () => void;
  }>;
  model: CheckboxValue;
  answer?: CheckboxValue;
  onClick?: (model: CheckboxValue) => void;
  onInputChange?: (value: number | null) => void;
  className?: string;
};

export const Checkbox: React.FC<Props> = ({
  isActive,
  textComponent,
  model,
  answer,
  onClick,
  onInputChange,
  className,
}) => (
  <CheckboxElement
    className={className}
    isActive={isActive}
    onClick={() => onClick && onClick(model)}
  >
    <ClientCheckboxCheckmark isActive={isActive} />

    <CheckboxIcon isActive={isActive} icon={model.icon} />

    {React.createElement(textComponent as any, { isActive }, model.text)}

    {model.hasCounter ? (
      <CheckboxCounter
        counterPrefix={model.counterPrefix}
        counterSuffix={model.counterSuffix}
        counter={answer?.counter}
        onInputChange={onInputChange}
      />
    ) : (
      <CounterPlaceholder />
    )}
  </CheckboxElement>
);
