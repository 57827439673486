import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'studie-core/src/components/buttons/Button';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled, { css } from 'styled-components';
import { setPage } from '../../../../state/landing';
import { updateStateAsync } from '../../../../state/questionnaire';
import { RootStateType } from '../../../../state/store';
import { setComponent } from '../../../../state/views';
import { CallCode } from '../../../callcodes/CallCode';

const MainHeadingElement = styled.h1`
  font-size: 40px;
  margin-top: 0;

  @media only screen and (min-width: 600px) {
    font-size: 50px;
  }
`;

const MainElement = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const OptionsElement = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  align-items: center;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const HeadlineElement = styled.h3`
  font-size: 20px;
`;

const columnShared = css`
  display: flex;
  flex-flow: column;
  max-width: 260px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  justify-self: center;
`;

const AssistanceColumnElement = styled.div`
  ${columnShared}
  background-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.lightPrimary};
  margin-bottom: 35px;

  @media only screen and (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const StartColumnElement = styled.div`
  ${columnShared}
  background-color: ${({ theme: { defaultColors } }) => defaultColors.lightGray};
`;

const ImageWrapperElement = styled.div`
  --size: 61px;

  margin-top: calc((20px + (var(--size) / 4)) * -1);

  img {
    width: var(--size);
    height: var(--size);

    border-radius: 50%;
    box-shadow: 0 0 7px 0 rgba(20, 69, 180, 0.1);
  }
`;

const TextElement = styled.div`
  margin-bottom: 35px;
  font-size: 16px;
`;

const RequestAssistanceButtonElement = styled(Button)`
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  font-size: 16px;
`;

const StartButtonElement = styled(Button)`
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  font-size: 16px;
`;

const SmallIcon = styled(Icon)`
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-left: 12px;
`;
const CallCodeWrapperElement = styled.div`
  display: flex; // hotfix
  flex-direction: column; // hotfix
  margin-top: 10px;
`;

export const Welcome: React.FC = () => {
  const {
    paginationType,
    systemPages: { welcome },
    activeWidgetIndex,
  } = useSelector((e: RootStateType) => e.questionnaire.questionnaire);
  const dispatch = useDispatch();

  const startQuestionnaire = () => {
    if (activeWidgetIndex === 0) {
      dispatch(updateStateAsync.request({ type: 'start' }));
    }

    dispatch(setComponent({ component: 'Questionnaire', type: paginationType }));
  };
  const openCallOrder = () => dispatch(setPage({ page: 'CallOrder' }));

  return (
    <>
      <MainElement>
        <div>
          <MainHeadingElement>{welcome.header}</MainHeadingElement>

          <div>{welcome.note}</div>
        </div>

        <CallCodeWrapperElement>
          <CallCode />
        </CallCodeWrapperElement>
      </MainElement>

      <OptionsElement>
        <AssistanceColumnElement>
          <ImageWrapperElement>
            <img
              src="/img/operator.png"
              srcSet="/img/operator.png, /img/operator@2x.png 2x"
              alt="Operátor"
            />
          </ImageWrapperElement>

          <HeadlineElement>Online meeting</HeadlineElement>

          <TextElement>
            Využijte možnosti spojení s online specialistou, prostřednictvím video meetingu.
          </TextElement>

          <RequestAssistanceButtonElement onClick={openCallOrder}>
            <span>Rezervace hovoru</span>

            <SmallIcon name="arrow-right" />
          </RequestAssistanceButtonElement>
        </AssistanceColumnElement>

        <StartColumnElement>
          <ImageWrapperElement>
            <AssistanceIcon />
          </ImageWrapperElement>

          <HeadlineElement>Vstoupit samostatně</HeadlineElement>

          <TextElement>Projděte si interaktivní prezentaci a dotazník samostatně.</TextElement>

          <StartButtonElement onClick={startQuestionnaire} outlined>
            <span>Vstoupit</span>
            <SmallIcon name="arrow-right" />
          </StartButtonElement>
        </StartColumnElement>
      </OptionsElement>
    </>
  );
};

const AssistanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61">
    <defs>
      <filter id="ellipse" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse">
        <feGaussianBlur stdDeviation="3.5" result="blur" />
        <feFlood floodColor="#1445b4" floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#ellipse)">
      <circle cx="30.5" cy="30.5" r="30.5" fill="#fff" />
    </g>
    <g transform="translate(8 8)">
      <path
        d="M15.047,23.11H8.15A1.15,1.15,0,0,0,7,24.26v6.9a1.15,1.15,0,0,0,1.15,1.15h6.9a1.15,1.15,0,0,0,1.15-1.15v-6.9A1.15,1.15,0,0,0,15.047,23.11Zm-1.15,6.9H9.3v-4.6h4.6Z"
        transform="translate(0.001 1.513)"
        fill="#144ebc"
      />
      <path
        d="M19.15,19.3H30.92a1.15,1.15,0,1,0,0-2.3H19.15a1.15,1.15,0,1,0,0,2.3Z"
        transform="translate(1.646 0.599)"
        fill="#144ebc"
      />
      <path
        d="M19.15,19.3H30.92a1.15,1.15,0,1,0,0-2.3H19.15a1.15,1.15,0,1,0,0,2.3Z"
        transform="translate(1.646 13.244)"
        fill="#144ebc"
      />
      <path
        d="M19.15,15.3H36.534a1.15,1.15,0,1,0,0-2.3H19.15a1.15,1.15,0,0,0,0,2.3Z"
        transform="translate(1.646 0.001)"
        fill="#144ebc"
      />
      <path
        d="M19.15,15.3H36.534a1.15,1.15,0,1,0,0-2.3H19.15a1.15,1.15,0,0,0,0,2.3Z"
        transform="translate(1.646 12.646)"
        fill="#144ebc"
      />
      <path
        d="M14.231,13.334l-3.782,3.794L8.966,15.633a1.154,1.154,0,0,0-1.632,1.632h0c3.782,3.771,2.3,3.909,8.53-2.3a1.154,1.154,0,0,0-1.632-1.632Z"
        fill="#144ebc"
      />
    </g>
  </svg>
);
