import { call, put, select, takeLatest } from 'typed-redux-saga';
import { ActionType, createAsyncAction, createReducer, getType } from 'typesafe-actions';
import {
  CallOrderRequest,
  insertCallOrder as insertCallOrderQuery,
  verifyNapiPassword as verifyNapiPasswordQuery,
  VerifyNapiPasswordRequest,
  VerifyNapiPasswordResponse,
  verifyPassword as verifyPasswordQuery,
  VerifyPasswordRequest,
  VerifyPasswordResponse,
} from '../api';
import { RootStateType } from './store';

export type LiveSaleIntegrationState = {
  callPasswordNapi: {
    success: VerifyNapiPasswordResponse | null;
    loading: boolean;
    error: boolean;
  };
  callPassword: {
    verificationResponse: VerifyPasswordResponse | null;
    loading: boolean;
    error: string | null;
  };
  callOrder: {
    isSuccessReponse: boolean;
    loading: boolean;
    error: string | null;
  };
};

export const verifyNapiPasswordAsync = createAsyncAction(
  '@callpassword/VERIFY_NAPI_REQUEST',
  '@callpassword/VERIFY_NAPI_SUCCESS',
  '@callpassword/VERIFY_NAPI_FAILURE'
)<VerifyNapiPasswordRequest, VerifyNapiPasswordResponse, string>();
export const verifyPasswordAsync = createAsyncAction(
  '@callpassword/VERIFY_REQUEST',
  '@callpassword/VERIFY_SUCCESS',
  '@callpassword/VERIFY_FAILURE'
)<VerifyPasswordRequest, VerifyPasswordResponse, string>();
export const insertCallOrderAsync = createAsyncAction(
  '@callorder/INSERT_REQUEST',
  '@callorder/INSERT_SUCCESS',
  '@callorder/INSERT_FAILURE'
)<CallOrderRequest, string, string>();

export type LiveSaleIntegrationAction =
  | ActionType<typeof verifyNapiPasswordAsync>
  | ActionType<typeof verifyPasswordAsync>
  | ActionType<typeof insertCallOrderAsync>;

export const livesaleIntegrationReducer = createReducer<
  LiveSaleIntegrationState,
  LiveSaleIntegrationAction
>({
  callPasswordNapi: {
    success: null,
    loading: false,
    error: false,
  },
  callPassword: {
    verificationResponse: null,
    loading: false,
    error: null,
  },
  callOrder: {
    isSuccessReponse: false,
    loading: false,
    error: null,
  },
})
  .handleAction(verifyNapiPasswordAsync.request, (state) => ({
    ...state,
    callPasswordNapi: {
      success: null,
      loading: true,
      error: false,
    },
  }))
  .handleAction(verifyNapiPasswordAsync.success, (state, action) => ({
    ...state,
    callPasswordNapi: {
      success: action.payload,
      loading: false,
      error: false,
    },
  }))
  .handleAction(verifyNapiPasswordAsync.failure, (state) => ({
    ...state,
    callPasswordNapi: {
      success: null,
      loading: false,
      error: true,
    },
  }))
  .handleAction(verifyPasswordAsync.request, (state) => ({
    ...state,
    callPassword: {
      ...state.callPassword,
      loading: true,
      error: null,
    },
  }))
  .handleAction(verifyPasswordAsync.success, (state, action) => ({
    ...state,
    callPassword: {
      verificationResponse: action.payload,
      loading: false,
      error: null,
    },
  }))
  .handleAction(verifyPasswordAsync.failure, (state, action) => ({
    ...state,
    callPassword: {
      ...state.callPassword,
      loading: false,
      error: action.payload,
    },
  }))
  .handleAction(insertCallOrderAsync.request, (state) => ({
    ...state,
    callOrder: {
      isSuccessReponse: false,
      loading: true,
      error: null,
    },
  }))
  .handleAction(insertCallOrderAsync.success, (state) => ({
    ...state,
    callOrder: {
      isSuccessReponse: true,
      loading: false,
      error: null,
    },
  }))
  .handleAction(insertCallOrderAsync.failure, (state, action) => ({
    ...state,
    callOrder: {
      isSuccessReponse: false,
      loading: false,
      error: action.payload,
    },
  }));

function* verifyNapiPassword(
  action: ReturnType<typeof verifyNapiPasswordAsync.request>
): Generator {
  try {
    const response = yield* call(verifyNapiPasswordQuery, action.payload);
    if (response.status === 200) {
      yield put(verifyNapiPasswordAsync.success(response.data));
    } else {
      yield put(verifyNapiPasswordAsync.failure('Při ověřování kódu nastala neočekávaná chyba.'));
    }
  } catch (err) {
    yield put(verifyNapiPasswordAsync.failure('Při ověřování kódu nastala neočekávaná chyba.'));
  }
}

function* verifyPassword(action: ReturnType<typeof verifyPasswordAsync.request>): Generator {
  try {
    const response = yield* call(verifyPasswordQuery, action.payload);
    if (response.status === 200) {
      if (response.data.IsValid) {
        yield put(verifyPasswordAsync.success(response.data));
      } else {
        yield put(verifyPasswordAsync.failure(response.data.ErrorMessage));
      }
    } else {
      yield put(verifyPasswordAsync.failure('Při ověřování kódu nastala neočekávaná chyba.'));
    }
  } catch (err) {
    yield put(verifyPasswordAsync.failure('Při ověřování kódu nastala neočekávaná chyba.'));
  }
}

function* insertCallOrder(action: ReturnType<typeof insertCallOrderAsync.request>): Generator {
  try {
    const {
      questionnaire: {
        version2: { baseUrl },
      },
    } = yield* select((e: RootStateType) => e.questionnaire);

    const response = yield* call(insertCallOrderQuery, baseUrl, action.payload);
    if (response.status === 200) {
      yield put(insertCallOrderAsync.success(response.data));
    } else {
      yield put(
        insertCallOrderAsync.failure(
          'Při ukládání údajů nastala chyba. Opakujte prosím akci později.'
        )
      );
    }
  } catch (err) {
    yield put(
      insertCallOrderAsync.failure(
        'Při ukládání údajů nastala chyba. Opakujte prosím akci později.'
      )
    );
  }
}

export function* verifyNapiPasswordSaga() {
  yield takeLatest(getType(verifyNapiPasswordAsync.request), verifyNapiPassword);
}

export function* verifyPasswordSaga() {
  yield takeLatest(getType(verifyPasswordAsync.request), verifyPassword);
}

export function* insertCallOrderSaga() {
  yield takeLatest(getType(insertCallOrderAsync.request), insertCallOrder);
}
