import * as React from 'react';
import styled from 'styled-components';
import {
  CheckboxCheckmarkActiveStyles,
  CheckboxCheckmarkStyles,
} from '../../styles/checkboxes/CheckboxCheckmark.styles';

type CheckboxCheckmarkElementProps = {
  isActive: boolean;
};

const CheckboxCheckmarkElement = styled.div<CheckboxCheckmarkElementProps>`
  --border-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  ${CheckboxCheckmarkStyles};

  align-self: flex-end;

  ${({ isActive }) => isActive && CheckboxCheckmarkActiveStyles}
`;

type Props = {
  isActive: boolean;
  className?: string;
};

export const CheckboxCheckmark: React.FC<Props> = ({ isActive, className }) => (
  <CheckboxCheckmarkElement isActive={isActive} className={className} />
);
