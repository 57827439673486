import { isEmpty } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { RootStateType } from '../../state/store';
import { makeStandardTheme } from '../../theme';

const DoneElement = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  display: flex;
  flex-direction: column;
  font-size: 20px;

  > div {
    width: 100%;
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    > div:first-of-type {
      flex-basis: 50%;
    }

    > div:last-of-type {
      flex-basis: 50%;

      min-width: 820px;
    }
  }
`;

const LeftSectionElement = styled.div`
  background-image: var(--background-image-mobile);
  background-size: cover;
  background-position: center center;
  min-height: 250px;

  @media only screen and (min-width: 1200px) {
    background-image: var(--background-image-desktop);
  }
`;

const RightSectionElement = styled.div`
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    padding: 30px 100px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 40px 142px;
  }
`;

const ContentElement = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderElement = styled.h1`
  font-size: 50px;
`;

const PoweredByElement = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding: 15px 0;
  color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};

  @media only screen and (min-width: 600px) {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1200px) {
    justify-content: flex-start;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }
`;

type Props = {
  redirected: boolean;
};

export const Done = () => {
  const location = useLocation<Props>();
  const { height } = useWindowSize();
  const { questionnaire } = useSelector((e: RootStateType) => e.questionnaire);
  const [desktopImage, setDesktopImage] = React.useState('/img/thanks.jpg');
  const [mobileImage, setMobileImage] = React.useState('/img/thanks.jpg');

  const textToShow = location.state?.redirected
    ? `za pečlivé zodpovězení všech otázek`
    : 'Vaše odpovědi jsme již zaznamenali.';

  React.useEffect(() => {
    if (
      location.state?.redirected &&
      !isEmpty(questionnaire?.systemPages?.thanks?.image?.desktopUrl)
    ) {
      setDesktopImage(questionnaire.systemPages.thanks.image.desktopUrl);
    }

    if (
      location.state?.redirected &&
      !isEmpty(questionnaire?.systemPages?.thanks?.image?.mobileUrl)
    ) {
      setMobileImage(questionnaire.systemPages.thanks.image.mobileUrl);
    }
  }, [location.state?.redirected, questionnaire]);

  return (
    <ThemeProvider theme={makeStandardTheme()}>
      <DoneElement height={height}>
        <LeftSectionElement
          style={
            {
              '--background-image-desktop': `url('${desktopImage}')`,
              '--background-image-mobile': `url('${mobileImage}')`,
            } as React.CSSProperties
          }
        />

        <RightSectionElement>
          <ContentElement>
            <HeaderElement>Děkujeme Vám</HeaderElement>
            <div>{textToShow}</div>
          </ContentElement>

          <PoweredByElement>
            <span>Powered by&nbsp;</span>

            <a href="https://livesale.cz/" target="_blank" rel="noreferrer">
              LiveSale
            </a>
          </PoweredByElement>
        </RightSectionElement>
      </DoneElement>
    </ThemeProvider>
  );
};
