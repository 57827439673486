import * as React from 'react';
import styled from 'styled-components';
import {
  RadioCheckmarkActiveStyles,
  RadioCheckmarkStyles,
} from '../../styles/radios/RadioCheckmark.styles';

const LabelElement = styled.label`
  position: relative;
  width: 27px;
  height: 27px;

  [type='radio'] {
    display: none;
  }

  [type='radio']:not(:checked) + span {
    --border-color: ${({ theme: { questionnaireColors } }) =>
      questionnaireColors.primary};

    cursor: pointer;

    ${RadioCheckmarkStyles}
  }

  [type='radio']:checked + span {
    --border-color: ${({ theme: { questionnaireColors } }) =>
      questionnaireColors.primary};

    cursor: pointer;

    ${RadioCheckmarkStyles}
    ${RadioCheckmarkActiveStyles}
  }
`;

const RadioElement = styled.input`
  margin: 0;
`;

type Props = {
  valueId: string;
  ratingId: string;
  isSelected: boolean;
  onChange: (
    valueId: string,
    ratingId: string,
    dispatchToApi?: boolean
  ) => void;
};

export const Answer: React.FC<Props> = ({
  valueId,
  ratingId,
  isSelected,
  onChange,
}) => {
  React.useEffect(() => {
    if (isSelected) {
      onChange(valueId, ratingId, false);
    }
  }, [isSelected, valueId, ratingId, onChange]);

  return (
    <LabelElement>
      <RadioElement
        type="radio"
        name={valueId}
        value={ratingId}
        onChange={() => onChange(valueId, ratingId)}
        defaultChecked={isSelected}
      />

      <span />
    </LabelElement>
  );
};
