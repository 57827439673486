import { isEmpty } from 'lodash';

export const tryParseInt = (s: string, radix: number = 10): number => {
  if (isEmpty(s)) {
    return 0;
  }

  const parsed = parseInt(s, radix);

  return isNaN(parsed) ? 0 : parsed;
};
