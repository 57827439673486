import * as React from 'react';
import { Icon } from 'studie-core/src/components/icons/Icon';
import {
  RadioIconActiveStyles,
  RadioIconStyles,
} from 'studie-core/src/styles/radios/RadioIcon.styles';
import styled from 'styled-components';

type RadioIconElementProps = {
  isActive: boolean;
};

const RadioIconElement = styled.div<RadioIconElementProps>`
  ${RadioIconStyles}

  ${({ isActive }) => isActive && RadioIconActiveStyles}
`;

type Props = {
  isActive: boolean;
  icon: string;
};

export const RadioIcon: React.FC<Props> = ({ isActive, icon }) => (
  <RadioIconElement isActive={isActive}>
    <Icon name={icon} />
  </RadioIconElement>
);
