import { isEmpty } from 'lodash';
import {
  Agreements,
  ArrayPair,
  Autocomplete,
  BasicWidget,
  Checkbox,
  Company,
  Contacts,
  Matrix,
  Page,
  Person,
  Questionnaire,
  Radio,
  Range,
  SimpleChoice,
  Slider,
  TextBox,
  Widget,
} from 'studie-core/src/models';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from 'typesafe-actions';
import {
  getQuestionnaire as getQuestionnaireQuery,
  QuestionsRequest,
  UpdateAnswerRequest,
  UpdateLeadState,
  updateLeadState as updateLeadStateQuery,
  updateWidgetAnswer as updateWidgetAnswerQuery,
} from '../api';
import { RootStateType } from './store';
import { error } from './toast';

type QuestionnaireState = {
  loading: boolean;
  questionnaire: Questionnaire;
  error: string | null;
  pages: Page[];
  activePageIndex: number;
  widgets: Widget[];
  activeWidgetIndex: number;
  valid: boolean;
  widgetsCount: number;
  loadedActiveIndex: number;
};

export type UpdateFormAnswerData = {
  widgetId: string;
} & (
  | {
      type: 'company';
      property: keyof Omit<Company, 'id'>;
      value: string;
    }
  | {
      type: 'people';
      property: keyof Omit<Person, 'id' | 'order'>;
      value: string;
    }
  | { type: 'isCompanyContact'; value: boolean }
);

type UpdateAnswerPayload = {
  widgetId: string;
};
type UpdateStatePayload =
  | {
      type: 'start';
    }
  | {
      type: 'update';
    }
  | {
      type: 'complete';
    };

export const getQuestionnaireAsync = createAsyncAction(
  '@questionnaire/GET_REQUEST',
  '@questionnaire/GET_SUCCESS',
  '@questionnaire/GET_FAILURE'
)<QuestionsRequest, Questionnaire, string>();
export const updateAnswerAsync = createAsyncAction(
  '@questionnaire/UPDATE_ANSWER_REQUEST',
  '@questionnaire/UPDATE_ANSWER_SUCCESS',
  '@questionnaire/UPDATE_ANSWER_FAILURE'
)<UpdateAnswerPayload, undefined, string>();
export const updateStateAsync = createAsyncAction(
  '@questionnaire/UPDATE_STATE_REQUEST',
  '@questionnaire/UPDATE_STATE_SUCCESS',
  '@questionnaire/UPDATE_STATE_FAILURE'
)<UpdateStatePayload, undefined, string>();

export const setupQuestionnaireIndex = createAction('@questionnaire/SETUP_PAGES')<{
  activeWidgetIndex?: number;
}>();
export const updateCounter = createAction('@questionnaire/UPDATE_COUNTER')<{
  widgetId: string;
  selectedAnswerId: string;
  counter: number;
  isValid: boolean;
}>();
export const updateAutocomplete = createAction('@questionnaire/UPDATE_AUTOCOMPLETE')<{
  widgetId: string;
  selectedAnswerId: string;
  isValid: boolean;
}>();
export const updateCheckbox = createAction('@questionnaire/UPDATE_CHECKBOX')<{
  widgetId: string;
  selectedAnswerId: string;
  // in most cases this will be boolean value – checked or not checked, ocasionally
  // user can change counter value prior to checking it. then tis will be number
  // that has to set selected to true as well
  value: boolean | number;
}>();
export const updateCheckboxValidity = createAction('@questionnaire/UPDATE_CHECKBOX_VALIDITY')<{
  widgetId: string;
}>();
export const updateAgreementValidity = createAction('@questionnaire/UPDATE_AGREEMENT_VALIDITY')<{
  widgetId: string;
}>();
export const updateRadio = createAction('@questionnaire/UPDATE_RADIO')<{
  widgetId: string;
  selectedAnswerId: string;
}>();
export const updateText = createAction('@questionnaire/UPDATE_TEXT')<{
  widgetId: string;
  selectedAnswerId: string;
  value: string;
}>();
export const updateSlider = createAction('@questionnaire/UPDATE_SLIDER')<{
  widgetId: string;
  selectedAnswerId: string;
}>();
export const updateRange = createAction('@questionnaire/UPDATE_RANGE')<{
  widgetId: string;
  selectedAnswerId: string;
  values: ArrayPair<number>;
}>();
export const updateMatrix = createAction('@questionnaire/UPDATE_MATRIX')<{
  widgetId: string;
  selectedAnswerId: string;
  value: number;
}>();
export const updateSimpleChoice = createAction('@questionnaire/UPDATE_SIMPLE_CHOICE')<{
  widgetId: string;
  selectedAnswerId: string;
}>();
export const updateFormAnswer = createAction('@questionnaire/UPDTE_FORM')<UpdateFormAnswerData>();
export const updateAgreement = createAction('@questionnaire/UPDATE_AGREEMeNT')<{
  widgetId: string;
  selectedAnswerId: string;
}>();
export const checkValidity = createAction('@questionnaire/CHECK_VALIDITY')();
export const setNextQuestion = createAction('@questionnaire/SET_NEXT_QUESTION')();
export const setPreviousQuestion = createAction('@questionnaire/SET_PREVIOUS_QUESTION')();
export const updateLoadedActiveWidgetIndex = createAction(
  '@questionnaire/UPDATE_LOADED_ACTIVE_WIDGET_INDEX'
)();

export type QuestionnaireAction =
  | ActionType<typeof getQuestionnaireAsync>
  | ActionType<typeof updateAnswerAsync>
  | ActionType<typeof setupQuestionnaireIndex>
  | ActionType<typeof updateCounter>
  | ActionType<typeof updateAutocomplete>
  | ActionType<typeof updateCheckbox>
  | ActionType<typeof updateRadio>
  | ActionType<typeof updateText>
  | ActionType<typeof updateSlider>
  | ActionType<typeof updateMatrix>
  | ActionType<typeof updateRange>
  | ActionType<typeof updateSimpleChoice>
  | ActionType<typeof updateFormAnswer>
  | ActionType<typeof updateAgreement>
  | ActionType<typeof checkValidity>
  | ActionType<typeof setNextQuestion>
  | ActionType<typeof setPreviousQuestion>
  | ActionType<typeof updateCheckboxValidity>
  | ActionType<typeof updateAgreementValidity>
  | ActionType<typeof updateStateAsync>
  | ActionType<typeof updateLoadedActiveWidgetIndex>;

const sortAscending = <T extends { order: number }>(a: T, b: T): number => a.order - b.order;

export const questionnaireReducer = createReducer<QuestionnaireState, QuestionnaireAction>({
  loading: true,
  questionnaire: {} as Questionnaire,
  error: null,
  pages: [],
  activePageIndex: 0,
  widgets: [],
  activeWidgetIndex: 0,
  valid: false,
  widgetsCount: 0,
  loadedActiveIndex: 0,
})
  .handleAction(setupQuestionnaireIndex, (state, action) => {
    let awi = 0;
    if (
      action.payload.activeWidgetIndex === null ||
      action.payload.activeWidgetIndex === undefined ||
      action.payload.activeWidgetIndex === 0
    ) {
      awi = 0;
    } else {
      if (state.questionnaire.activeWidgetIndex < action.payload.activeWidgetIndex) {
        awi = state.questionnaire.activeWidgetIndex - 1;
      } else if (state.questionnaire.activeWidgetIndex >= action.payload.activeWidgetIndex) {
        awi = action.payload.activeWidgetIndex - 1;
      } else {
        awi = action.payload.activeWidgetIndex - 1;
      }

      if (awi < 0) {
        awi = 0;
      }
    }

    let activePageIndex = 0;
    if (awi > 0) {
      const activePage = state.questionnaire.pages.find((page) =>
        page.widgets.find((widget) => widget === state.widgets[awi])
      )!;
      activePageIndex = state.questionnaire.pages.indexOf(activePage);
    }

    return {
      ...state,
      activePageIndex,
      activeWidgetIndex: awi,
      loadedActiveIndex:
        state.questionnaire.activeWidgetIndex === 0 ? 0 : state.questionnaire.activeWidgetIndex - 1,
    };
  })
  .handleAction(updateCounter, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Checkbox;
    const answer = widget.extra.answers!.find(
      (answer) => answer.id === action.payload.selectedAnswerId
    )!;

    if (answer) {
      answer.counter = action.payload.counter as number;
    }

    widget.valid = widget.extra.answers.length > 0;

    return { ...state, canMoveNext: widget.valid };
  })
  .handleAction(updateAutocomplete, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Autocomplete;
    const value = {
      ...widget.extra.values.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    widget.extra.answer = value;
    widget.valid = action.payload.isValid;

    return { ...state };
  })
  .handleAction(updateCheckbox, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Checkbox;
    const value = {
      ...widget.extra.values!.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    if (!widget.extra.answers) {
      widget.extra.answers = [];
    }

    const answerIndex = widget.extra.answers.findIndex((a) => a.id === value.id);

    if (answerIndex === -1) {
      // checked
      if (typeof action.payload.value === 'number') {
        value.counter = action.payload.value;
      } else {
        value.counter = 1;
      }

      widget.extra.answers.push(value);
    } else {
      // unchecked
      widget.extra.answers.splice(answerIndex, 1);
    }

    widget.valid = widget.extra.answers.length > 0;

    return { ...state };
  })
  .handleAction(updateCheckboxValidity, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Checkbox;

    widget.valid = widget.extra.answers.length > 0;

    return { ...state };
  })
  .handleAction(updateAgreementValidity, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Agreements;

    if (!widget.extra.answers) {
      widget.extra.answers = [];
    }

    const requiredIds = widget.extra.values
      .filter((value) => value.required)
      .map((value) => value.id);
    const checkedIds = widget.extra.answers
      .filter((value) => value.required)
      .map((value) => value.id);

    widget.valid = requiredIds.every((requiredId) => checkedIds.includes(requiredId));

    return { ...state };
  })
  .handleAction(updateRadio, (state, action) => {
    const widget = state.widgets.find((q) => q.id === action.payload.widgetId) as Widget & Radio;
    const value = {
      ...widget.extra.values.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    widget.extra.answer = value;
    widget.valid = true;

    return { ...state };
  })
  .handleAction(updateText, (state, action) => {
    const widget = state.widgets.find((q) => q.id === action.payload.widgetId) as Widget & TextBox;
    const value = {
      ...widget.extra.values!.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    value.value = action.payload.value;
    widget.extra.answer = value;
    widget.valid = action.payload.value.length > 0;

    return { ...state };
  })
  .handleAction(updateSlider, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Slider;
    const value = {
      ...widget.extra.values.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    widget.extra.answer = value;
    widget.valid = true;

    return { ...state };
  })
  .handleAction(updateRange, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Range;
    const value = {
      ...widget.extra.values.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    widget.extra.answer = value;
    widget.extra.answer.values = action.payload.values;
    widget.valid = true;

    return { ...state };
  })
  .handleAction(updateMatrix, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Matrix;
    const value = widget.extra.values.find(
      (value) => value.id === action.payload.selectedAnswerId
    )!;
    if (!widget.extra.answers) {
      widget.extra.answers = [];
    }

    const exists = widget.extra.answers.find(
      (answer) => answer.id === action.payload.selectedAnswerId
    );
    if (!exists) {
      widget.extra.answers.push({ ...value, value: action.payload.value });
    } else {
      exists.value = action.payload.value;
    }

    if (widget.required && widget.extra.values.some((value) => value.required)) {
      if (!widget.extra.answers || isEmpty(widget.extra.answers)) {
        widget.valid = false;
      } else {
        const requiredValueIds = widget.extra.values
          .filter((value) => value.required)
          .map((value) => value.id);

        widget.valid = requiredValueIds.every((id) =>
          widget.extra.answers.find((answer) => answer.id === id)
        );
      }
    }

    return { ...state };
  })
  .handleAction(updateSimpleChoice, (state, action) => {
    const widget = state.widgets.find((q) => q.id === action.payload.widgetId) as Widget &
      SimpleChoice;
    const value = {
      ...widget.extra.values.find((value) => value.id === action.payload.selectedAnswerId)!,
    };

    widget.extra.answer = value;
    widget.valid = true;

    return { ...state };
  })
  .handleAction(updateFormAnswer, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Contacts;

    if (action.payload.type === 'company') {
      widget.extra.company[action.payload.property].value = action.payload.value;
      widget.extra.company[action.payload.property].valid = !isEmpty(action.payload.value);
    } else if (action.payload.type === 'people') {
      widget.extra.people[0][action.payload.property].value = action.payload.value;
      widget.extra.people[0][action.payload.property].valid = !isEmpty(action.payload.value);
    } else if (action.payload.type === 'isCompanyContact') {
      widget.extra.isCompanyContact.value = action.payload.value;
    }

    const companyIsValid = Object.keys(widget.extra.company)
      .filter((key) => key !== 'id')
      .every((key) => {
        const typedKey = key as keyof Omit<Company, 'id'>;
        if (!widget.extra.company[typedKey].show || !widget.extra.company[typedKey].required) {
          return true;
        }

        return (
          (widget.extra.company[typedKey].show &&
            widget.extra.company[typedKey].required &&
            widget.extra.company[typedKey].valid) ||
          !widget.extra.company[typedKey].show
        );
      });
    const personIsValid = Object.keys(widget.extra.people[0])
      .filter((key) => !['id', 'order', 'fullName'].includes(key))
      .every((key) => {
        const typedKey = key as keyof Omit<Person, 'id' | 'order'>;
        if (!widget.extra.people[0][typedKey].show || !widget.extra.people[0][typedKey].required) {
          return true;
        }

        return widget.extra.people[0][typedKey].valid;
      });

    widget.valid = widget.extra.isCompanyContact.value
      ? companyIsValid && personIsValid
      : personIsValid;

    return { ...state };
  })
  .handleAction(updateAgreement, (state, action) => {
    const widget = state.widgets.find((widget) => widget.id === action.payload.widgetId) as Widget &
      Agreements;
    const value = widget.extra.values.find(
      (value) => value.id === action.payload.selectedAnswerId
    )!;

    if (!widget.extra.answers) {
      widget.extra.answers = [];
    }

    const answerIndex = widget.extra.answers.findIndex((answer) => answer.id === value.id);

    if (answerIndex === -1) {
      value.valid = true;
      widget.extra.answers.push(value);
    } else {
      widget.extra.answers.splice(answerIndex, 1);
    }

    const requiredIds = widget.extra.values
      .filter((value) => value.required)
      .map((value) => value.id);
    const checkedIds = widget.extra.answers
      .filter((value) => value.required)
      .map((value) => value.id);

    widget.valid = requiredIds.every((requiredId) => checkedIds.includes(requiredId));

    return { ...state };
  })
  .handleAction(checkValidity, (state) => {
    state.valid = state.widgets.filter((widget) => widget.required).every((widget) => widget.valid);

    return { ...state };
  })
  .handleAction(setNextQuestion, (state) => {
    if (state.widgets.length - 1 > state.activeWidgetIndex) {
      state.activeWidgetIndex += 1;
    }

    const activePage = state.pages.find((page) =>
      page.widgets.find((widget) => widget === state.widgets[state.activeWidgetIndex])
    )!;
    state.activePageIndex = state.pages.indexOf(activePage);

    return { ...state };
  })
  .handleAction(setPreviousQuestion, (state) => {
    if (state.activeWidgetIndex > 0) {
      state.activeWidgetIndex -= 1;
    }

    const activePage = state.pages.find((page) =>
      page.widgets.find((widget) => widget === state.widgets[state.activeWidgetIndex])
    )!;
    state.activePageIndex = state.pages.indexOf(activePage);

    return { ...state };
  })
  .handleAction(getQuestionnaireAsync.request, (state) => ({ ...state }))
  .handleAction(getQuestionnaireAsync.success, (state, action) => {
    action.payload.pages = action.payload.pages.sort(sortAscending);
    action.payload.pages.forEach((page) => {
      page.widgets = page.widgets.sort(sortAscending);
      page.widgets.forEach((widget) => {
        if (widget.type !== 'contacts') {
          widget.extra.values.sort(sortAscending);
        }
      });
    });

    const overallWidgets = action.payload.pages.flatMap((page) => page.widgets);
    return {
      ...state,
      loading: false,
      questionnaire: action.payload,
      pages: action.payload.pages,
      widgets: overallWidgets,
      widgetsCount: overallWidgets.length,
    };
  })
  .handleAction(getQuestionnaireAsync.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(updateLoadedActiveWidgetIndex, (state, action) => ({
    ...state,
    loadedActiveIndex: state.activeWidgetIndex,
  }));

function* getQuestionnaire(action: ReturnType<typeof getQuestionnaireAsync.request>): Generator {
  try {
    const response = yield* call(getQuestionnaireQuery, action.payload);
    if (response.status === 200) {
      yield put(getQuestionnaireAsync.success(response.data));
    } else {
      yield put(getQuestionnaireAsync.failure('Nastala neočekávaná chyba.'));
    }
  } catch (err) {
    yield put(getQuestionnaireAsync.failure('Nastala neočekávaná chyba.'));
  }
}

export function* getQuestionnaireSaga() {
  yield takeLatest(getType(getQuestionnaireAsync.request), getQuestionnaire);
}

function* updateAnswer(action: ReturnType<typeof updateAnswerAsync.request>): Generator {
  if (isEmpty(action.payload)) {
    return;
  }

  try {
    const {
      questionnaire: { leadId, securityToken },
      widgets,
    } = yield* select((e: RootStateType) => e.questionnaire);
    const payload = {
      leadId,
      securityToken,
      widgetId: action.payload.widgetId,
      answer: (widgets.find((widget) => widget.id === action.payload.widgetId) as BasicWidget)
        .extra,
    } as UpdateAnswerRequest;

    if (!window.isDemo) {
      const response = yield* call(updateWidgetAnswerQuery, payload);
      if (response.status === 200) {
        yield put(updateAnswerAsync.success());
      } else {
        yield put(updateAnswerAsync.failure('Při přechodu na další otázku došlo k chybě.'));
        error({ text: 'Při přechodu na další otázku došlo k chybě.' });
      }
    }
  } catch (err) {
    yield put(updateAnswerAsync.failure('Při přechodu na další otázku došlo k chybě.'));
    error({ text: 'Při přechodu na další otázku došlo k chybě.' });
  }
}

export function* updateAnswerSaga() {
  yield takeLatest(getType(updateAnswerAsync.request), updateAnswer);
}

function* updateState(action: ReturnType<typeof updateStateAsync.request>): Generator {
  try {
    const {
      questionnaire: { leadId, securityToken },
      activeWidgetIndex,
      widgets,
    } = yield* select((e: RootStateType) => e.questionnaire);

    const payload = {
      leadId,
      securityToken,
      activeWidgetIndex: activeWidgetIndex + 1,
      widgetId: widgets[activeWidgetIndex].id,
      completed: false,
    } as UpdateLeadState;
    if (action.payload.type === 'complete') {
      payload.completed = true;
    }

    const response = yield* call(updateLeadStateQuery, payload);
    if (response.success) {
      yield put(updateStateAsync.success());
    } else {
      if (
        ['invalid_index', 'cannot_decrease_index', 'cannot_update_index'].includes(
          response.data.error_id
        )
      ) {
        return;
      }

      yield put(updateStateAsync.failure('Při ukládání odpovědi došlo k chybě.'));
      error({ text: 'Při ukládání odpovědi došlo k chybě.' });
    }
  } catch (err) {
    yield put(updateStateAsync.failure('Při ukládání odpovědi došlo k chybě.'));
    error({ text: 'Při ukládání odpovědi došlo k chybě.' });
  }
}

export function* updateStateSaga() {
  yield takeLatest(getType(updateStateAsync.request), updateState);
}
