import { css } from 'styled-components';

export const CheckboxCheckmarkStyles = css`
  min-width: 27px;
  width: 27px;
  min-height: 27px;
  height: 27px;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  border: 1px solid var(--border-color);
  position: absolute;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const CheckboxCheckmarkActiveStyles = css`
  &::before {
    content: '';
    position: absolute;
    border-radius: 1px;
    backface-visibility: hidden;
    width: 5px;
    height: 12px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid
      ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    border-bottom: 2px solid
      ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    transform: rotateZ(37deg);
    transform-origin: 85% 50%;
  }
`;
