import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox as SharedCheckbox } from 'studie-core/src/components/checkboxes/Checkbox';
import { CheckboxValue } from 'studie-core/src/models';
import styled from 'styled-components';
import {
  checkValidity,
  updateAnswerAsync,
  updateCheckbox,
  updateCheckboxValidity,
  updateCounter as updateCounterAction,
} from '../../state/questionnaire';
import { CheckboxText } from './CheckboxText';

const CheckboxElement = styled(SharedCheckbox)`
  user-select: none;
  cursor: pointer;
`;

type Props = {
  model: CheckboxValue;
  widgetId: string;
  isSelected: boolean;
  answer?: CheckboxValue;
};

export const Checkbox: React.FC<Props> = ({ model, widgetId, isSelected, answer }) => {
  const dispatch = useDispatch();

  const onClick = (counter?: number) => {
    dispatch(
      updateCheckbox({
        widgetId,
        selectedAnswerId: model.id,
        value: counter ?? true,
      })
    );
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };
  const updateCounter = (counter: number) => {
    dispatch(
      updateCounterAction({
        widgetId,
        selectedAnswerId: model.id,
        counter,
        isValid: counter > 0,
      })
    );
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };
  const onInputChange = (counter: number | null) => {
    if (counter) {
      //  !== null && counter >= 0
      if (answer === undefined || counter === 0) {
        onClick(counter);
      } else {
        updateCounter(counter);
      }
    } else if ((!isEmpty(answer) && counter === 0) || (counter === null && answer !== undefined)) {
      onClick();
    }
  };

  React.useEffect(() => {
    if (isSelected) {
      dispatch(
        updateCheckboxValidity({
          widgetId,
        })
      );
      dispatch(checkValidity());
    }
  }, []);

  return (
    <CheckboxElement
      isActive={isSelected}
      textComponent={CheckboxText}
      model={model}
      onClick={() => onClick()}
      onInputChange={onInputChange}
      answer={answer}
    />
  );
};
