import { css } from 'styled-components';

export const InputTextBoxStyles = css`
  --border-color: ${({ theme: { defaultColors } }) => defaultColors.gray};
  border: 1px solid var(--border-color);
  min-width: 300px;
  border-radius: 5px;
  height: 55px;
  width: 100%;
  outline: none;
  padding: 0 15px;
  background-color: white;
  font-size: 16px;
`;

export const InputTextBoxInvalidStyles = css`
  --border-color: ${({ theme: { defaultColors } }) => defaultColors.red};
  padding-right: 48px;

  &:hover,
  &:active,
  &:focus {
    --border-color: ${({ theme: { defaultColors } }) => defaultColors.red};
  }
`;
