import * as React from 'react';
import styled from 'styled-components';
import {
  ButtonBaseStyles,
  ButtonOutlinedStyles,
  ButtonStyles,
} from '../../styles/buttons/Button.styles';

type ButtonElementProps = {
  outlined: boolean;
};

const ButtonElement = styled.button<ButtonElementProps>`
  ${ButtonBaseStyles}

  ${({ outlined }) => (outlined ? ButtonOutlinedStyles : ButtonStyles)}
`;

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  outlined?: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
};

export const Button: React.FC<Props> = ({
  onClick,
  outlined = false,
  disabled = false,
  className,
  children,
  type = 'button',
}) => (
  <ButtonElement
    type={type}
    className={className}
    outlined={outlined}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </ButtonElement>
);
