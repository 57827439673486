import { css } from 'styled-components';

export const LineRadioStyles = css`
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  background-color: var(--background-color);
  min-height: 60px;

  user-select: none;
  cursor: pointer;
`;

export const LineRadioActiveStyles = css`
  --background-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};
`;
