import { isEmpty } from 'lodash';
import * as React from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled, { keyframes } from 'styled-components';
import { verifyNapiPasswordAsync, verifyPasswordAsync } from '../../state/livesale-integration';
import { showErrorModal } from '../../state/modal';
import { RootStateType } from '../../state/store';
import { SelectWayForm } from '../callcodes/shared/SelectWayForm';

const rotate = keyframes`
	to {
		transform: rotate(360deg);
	}
`;

const CallCodeElement = styled.div`
  display: grid;
  grid-template-columns: 215px calc(47px + 15px);

  margin: 35px 0;

  height: max-content;

  transition: all 1s ease;

  @media only screen and (min-width: 600px) {
    margin: 0;
  }
`;
const HeadlineElement = styled.h3`
  justify-self: center;

  margin: 0;
  margin-bottom: 10px;

  color: #d01419;
  font-size: 20px;
`;
const InputElement = styled.input`
  width: 215px;
  height: 70px;

  padding: 0;

  border-radius: 5px;
  border: 1px solid #000000;
  background-color: transparent;

  font-size: 46px;
  text-align: center;

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;
const SendButtonElement = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  width: 47px;
  height: 47px;

  margin-left: 15px;

  border-radius: 50%;
  border-width: 0;

  color: #ffffff;
  background-color: #d01419;

  &:disabled {
    background-color: #c9c9c9;
  }
`;
const LoaderIcon = styled(Icon)`
  width: 24px;
  height: 24px;

  use {
    animation: ${rotate} 3.5s linear infinite;
    transform-origin: 50% 50%;
  }
`;

export const CallCode = () => {
  const digitOrLetter = /[a-z0-9]/;
  const mask = [
    digitOrLetter,
    ' ',
    digitOrLetter,
    ' ',
    digitOrLetter,
    ' ',
    digitOrLetter,
    ' ',
    digitOrLetter,
  ];

  const { callPassword, callPasswordNapi } = useSelector((e: RootStateType) => e.livesale);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const [codeEntered, setCodeEntered] = React.useState(false);
  const submitFormButtonRef = React.useRef<HTMLButtonElement>(null);

  const onClickValidate = () => {
    dispatch(verifyNapiPasswordAsync.request({ password: value }));
  };
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickValidate();
    }
  };

  React.useEffect(() => {
    setCodeEntered(value.length === 5);
  }, [value]);

  React.useEffect(() => {
    if (!callPasswordNapi.loading) {
      if (callPasswordNapi.error) {
        dispatch(
          showErrorModal({
            content: <span>Při ověřování nastala chyba.</span>,
          })
        );
      }

      if (!isEmpty(callPasswordNapi.success)) {
        dispatch(
          verifyPasswordAsync.request({
            entryUrl: callPasswordNapi.success!.version2.entryUrl,
            password: callPasswordNapi.success!.version2.code.toString(),
          })
        );
      }

      setValue('');
    }
  }, [dispatch, callPasswordNapi.loading, callPasswordNapi.error, callPasswordNapi.success]);

  React.useEffect(() => {
    if (!callPassword.loading) {
      if (!isEmpty(callPassword.error)) {
        dispatch(
          showErrorModal({
            content: <span>{callPassword.error!}</span>,
          })
        );
      }

      if (!isEmpty(callPassword.verificationResponse)) {
        submitFormButtonRef.current?.click();
      }

      setValue('');
    }
  }, [
    dispatch,
    callPassword.loading,
    callPassword.error,
    callPassword.verificationResponse,
    submitFormButtonRef,
  ]);

  return (
    <>
      <CallCodeElement>
        <HeadlineElement>Vstupní kód</HeadlineElement>

        <div />

        <InputMask
          placeholder="0 0 0 0 0"
          autoComplete="off"
          mask={mask}
          maskPlaceholder=""
          value={value}
          onChange={(e) => setValue(e.target.value.replaceAll(' ', ''))}
          readOnly={callPassword.loading || callPasswordNapi.loading}
          onKeyUp={onKeyUp}
        >
          <InputElement type="tel" />
        </InputMask>

        <SendButtonElement
          type="button"
          disabled={!codeEntered || callPassword.loading || callPasswordNapi.loading}
          onClick={() => onClickValidate()}
        >
          {!(callPassword.loading || callPasswordNapi.loading) ? (
            'OK'
          ) : (
            <LoaderIcon name="loader" />
          )}
        </SendButtonElement>
      </CallCodeElement>

      {!isEmpty(callPassword.verificationResponse) && (
        <SelectWayForm
          verificationResponse={callPassword.verificationResponse!}
          submitFormButtonRef={submitFormButtonRef}
          entryUrl={callPasswordNapi.success!.version2.entryUrl}
        />
      )}
    </>
  );
};
