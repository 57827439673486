import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SimpleChoiceValue } from 'studie-core/src/models';
import { SimpleChoiceStyles } from 'studie-core/src/styles/radios/SimpleChoice.styles';
import styled from 'styled-components';
import { checkValidity, updateAnswerAsync, updateSimpleChoice } from '../../state/questionnaire';
import { Choice } from './Choice';

const SimpleChoiceElement = styled.div`
  ${SimpleChoiceStyles};

  margin: 0 auto;
`;

type Props = {
  widgetId: string;
  values: SimpleChoiceValue[];
  answer: SimpleChoiceValue;
};

export const SimpleChoice: React.FC<Props> = ({ widgetId, values, answer }) => {
  const dispatch = useDispatch();

  const onClick = (answerId: string) => {
    dispatch(updateSimpleChoice({ widgetId, selectedAnswerId: answerId }));
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  React.useEffect(() => {
    if (!isEmpty(answer)) {
      dispatch(updateSimpleChoice({ widgetId, selectedAnswerId: answer.id }));
      dispatch(checkValidity());
    }
  }, []);

  const isActive = (answerId: string) => !isEmpty(answer) && answer.id === answerId;

  return (
    <SimpleChoiceElement>
      {values.map((value) => (
        <Choice
          key={value.id}
          text={value.text}
          isActive={isActive(value.id)}
          onClick={() => onClick(value.id)}
        />
      ))}
    </SimpleChoiceElement>
  );
};
