import { isEmpty } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import {
  CheckboxIconActiveStyles,
  CheckboxIconStyles,
} from '../../styles/checkboxes/CheckboxIcon.styles';
import { Icon } from '../icons/Icon';

type CheckboxIconElementProps = {
  isActive: boolean;
};

const CheckboxIconElement = styled.div<CheckboxIconElementProps>`
  ${CheckboxIconStyles}

  ${({ isActive }) => isActive && CheckboxIconActiveStyles}
`;

type Props = {
  icon: string;
  isActive: boolean;
};

export const CheckboxIcon: React.FC<Props> = ({ icon, isActive }) => (
  <CheckboxIconElement isActive={isActive}>
    {!isEmpty(icon) && <Icon name={icon} />}
  </CheckboxIconElement>
);
