import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { CallOrder } from '../components/questionnaires/pages/landing/CallOrder';
import { Thanks } from '../components/questionnaires/pages/landing/Thanks';
import { Welcome } from '../components/questionnaires/pages/landing/Welcome';

const _pages = {
  Welcome,
  CallOrder,
  Thanks,
};

type LandingState = {
  activePageName: 'Welcome' | 'CallOrder' | 'Thanks';
  activePage: React.FC;
};

export const setPage = createAction('@views/SET_PAGE')<{
  page: 'Welcome' | 'CallOrder' | 'Thanks';
}>();

export type LandingAction = ActionType<typeof setPage>;

export const landingReducer = createReducer<LandingState, LandingAction>({
  activePageName: 'Welcome',
  activePage: Welcome,
}).handleAction(setPage, (state, action) => ({
  ...state,
  activePageName: action.payload.page,
  activePage: _pages[action.payload.page],
}));
