import * as React from 'react';
import {
  InputTextBoxInvalidStyles,
  InputTextBoxStyles,
} from 'studie-core/src/styles/inputs/InputTextBox.styles';
import styled from 'styled-components';

type TextBoxElementProps = {
  isValid: boolean;
};

const TextBoxElement = styled.input<TextBoxElementProps>`
  ${InputTextBoxStyles};

  padding: 0 15px;
  z-index: 3;

  ${({ isValid }) => !isValid && InputTextBoxInvalidStyles};
`;

type Props = {
  value: string;
  isValid: boolean;
  placeholder: string;
  onKeyUp: (value: string) => void;
};

export const AutocompleteTextBox = React.forwardRef<HTMLInputElement, Props>(
  ({ value, isValid, placeholder, onKeyUp }, ref) => {
    const [internalValue, setInternalValue] = React.useState('');

    React.useEffect(() => {
      setInternalValue(value);
    }, [value]);

    return (
      <TextBoxElement
        ref={ref}
        type="text"
        tabIndex={-1}
        placeholder={placeholder}
        value={internalValue}
        isValid={isValid}
        onChange={(e) => setInternalValue(e.target.value)}
        onKeyUp={(e) =>
          onKeyUp(
            e.currentTarget.value
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
        }
        className="autocomplete"
      />
    );
  }
);
