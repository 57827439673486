import * as React from 'react';
import { Button as SharedButton } from 'studie-core/src/components/buttons/Button';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';

const Button = styled(SharedButton)`
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  align-self: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

const SmallIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

type Props = {
  disabled: boolean;
  onClick: () => void;
  useNativeDisabled?: boolean;
  onDisabledClick?: () => void;
};

export const NextButton: React.FC<Props> = React.memo(
  ({ disabled, onClick, useNativeDisabled = true, onDisabledClick, children }) => (
    <Button
      onClick={disabled && !!onDisabledClick ? onDisabledClick : onClick}
      disabled={!((useNativeDisabled && disabled) || !useNativeDisabled)}
    >
      {children}

      <SmallIcon name="arrow-right" />
    </Button>
  )
);
