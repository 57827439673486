import * as React from 'react';
import { AgreementsExtra } from 'studie-core/src/models';
import styled from 'styled-components';
import { Agreement } from './Agreement';

const AgreementsElement = styled.div`
  width: 650px;
  margin: 0 auto;
`;

const AgreeemntsContainerElement = styled.div``;

type Props = {
  widgetId: string;
  widgetExtra: AgreementsExtra;
};

export const Agreements: React.FC<Props> = ({ widgetId, widgetExtra: { values, answers } }) => {
  const requiredAgreement = values.filter((value) => value.required);
  const nonRequiredAgreement = values.filter((value) => !value.required);

  return (
    <AgreementsElement>
      <h3>Povinné souhlasy</h3>
      <AgreeemntsContainerElement>
        {requiredAgreement.map((agreement) => (
          <Agreement
            key={agreement.id}
            widgetId={widgetId}
            agreement={agreement}
            answer={answers?.find((answer) => answer.id === agreement.id)}
          />
        ))}
      </AgreeemntsContainerElement>

      <h3>Nepovinné souhlasy</h3>
      <AgreeemntsContainerElement>
        {nonRequiredAgreement.map((agreement) => (
          <Agreement
            key={agreement.id}
            widgetId={widgetId}
            agreement={agreement}
            answer={answers?.find((answer) => answer.id === agreement.id)}
          />
        ))}
      </AgreeemntsContainerElement>
    </AgreementsElement>
  );
};
