import { isEmpty } from 'lodash';
import * as React from 'react';
import { InputTextBoxStyles } from 'studie-core/src/styles/inputs/InputTextBox.styles';
import styled, { css } from 'styled-components';
import { FieldErrorProps, useFieldError } from '../../../../hooks/useFieldError';
import { Content, Label, Message, Parent } from './Common.styles';

type InternalInputProps = {
  inputRef: React.ForwardedRef<HTMLInputElement>;
  hasError: boolean;
  type: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
const InternalInput: React.FC<InternalInputProps> = ({
  hasError,
  inputRef,
  type,
  id,
  name,
  ...rest
}) => <input {...rest} ref={inputRef} name={name} id={id} type={type} />;

const StyledInput = styled(InternalInput)`
  ${InputTextBoxStyles}

  min-width: unset;
  width: 100%;

  &:active + label,
  &:focus + label,
  &.has-value + label {
    transform: translateY(5px) scale(0.8);
  }

  &:active + label,
  &:focus + label {
    --label-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    opacity: 1;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      --border-color: ${({ theme: { defaultColors } }) => defaultColors.red};

      & + label {
        --label-color: ${({ theme: { defaultColors } }) => defaultColors.red};
        opacity: 1;
      }
    `}
`;

type Props = {
  onChange: (event: any) => Promise<void | boolean>;
  name: string;
  label?: string;
  className?: string;
  type?: string;
} & FieldErrorProps;

export const EndFormInput = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, name, label, fieldError, className, type = 'text' }, ref) => {
    const [hasValue, setHasValue] = React.useState(false);
    const { hasError, errorMessage } = useFieldError({ fieldError });

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) =>
      setHasValue(!isEmpty(e.currentTarget.value));

    return (
      <Parent className={className}>
        <Content>
          <StyledInput
            inputRef={ref}
            name={name}
            hasError={hasError}
            onKeyUp={(e) => onKeyUp(e)}
            className={hasValue ? 'has-value' : undefined}
            type={type}
            id={name}
            onChange={onChange}
          />

          {label && <Label htmlFor={name}>{label}</Label>}
        </Content>

        <Message
          dangerouslySetInnerHTML={{
            __html: (hasError && errorMessage) || '&nbsp;',
          }}
        />
      </Parent>
    );
  }
);
