import * as React from 'react';
import { Remarkable } from 'remarkable';
import { SimpleTextValue } from 'studie-core/src/models';
import { SimpleTextStyles } from 'studie-core/src/styles/visual/SimpleText.styles';
import styled from 'styled-components';

const SimpleTextElement = styled.div`
  ${SimpleTextStyles};

  margin: 0 auto;

  h1 {
    color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
  }
`;

type Props = {
  model: SimpleTextValue;
};

export const SimpleText: React.FC<Props> = ({ model }) => {
  const getRawMarkup = () => {
    const md = new Remarkable({ xhtmlOut: true, breaks: true });
    return { __html: md.render(model.textMarkdown) };
  };

  return <SimpleTextElement dangerouslySetInnerHTML={getRawMarkup()} />;
};
