import { css } from 'styled-components';

export const QuestionStyles = css`
  padding: 5px 0;
  font-size: 18px;
  margin: 35px 0 20px 0;
  font-weight: bold;
`;

export const RequiredQuestionStyles = css`
  &:after {
    content: ' *';
    color: red;
  }
`;
