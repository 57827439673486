import * as React from 'react';
import styled, { css } from 'styled-components';

type CheckboxTextElementProps = {
  isActive: boolean;
};

const CheckboxTextElement = styled.div<CheckboxTextElementProps>`
  --color: ${({ theme: { defaultColors } }) => defaultColors.black};

  color: var(--color);
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 60px;
  padding: 10px 0;
  font-size: 16px;

  ${({ isActive }) =>
    isActive &&
    css`
      --color: ${({ theme: { defaultColors } }) => defaultColors.white};
    `}
`;

type Props = {
  isActive: boolean;
};

export const CheckboxText: React.FC<Props> = ({ isActive, children }) => (
  <CheckboxTextElement isActive={isActive}>{children}</CheckboxTextElement>
);
