import * as React from 'react';
import styled from 'styled-components';

const WrapperElement = styled.div`
  text-align: center;

  @media only screen and (min-width: 1200px) {
    text-align: right;
  }
`;
const PromoImageElement = styled.img`
  width: 100%;
  max-width: 600px;
`;

export const PromoImage = () => (
  <WrapperElement>
    <PromoImageElement
      src="/img/homepage devices.png"
      srcSet="'/img/homepage devices.png', '/img/homepage devices@1.5x.png' 1.5x '/img/homepage devices@2x.png' 2x"
      alt="Ukázka interaktivní aplikace na různých zařízení."
    />
  </WrapperElement>
);
