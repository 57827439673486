import { lighten } from 'polished';
import * as React from 'react';
import { AutocompleteValue } from 'studie-core/src/models';
import { AutocompleteListItemStyles } from 'studie-core/src/styles/autocompletes/AutocompleteListItem.styles';
import styled from 'styled-components';

function replaceAt(string: string, index: number, replacement: string, length: number) {
  return string.substr(0, index) + replacement + string.substr(index + length);
}

type AutocompleteListWrapperElementProps = {
  active: boolean;
  width: number;
};

const AutocompleteListWrapperElement = styled.div<AutocompleteListWrapperElementProps>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: fixed;
  border-radius: 5px;
  width: ${({ width }) => `${width}px`};
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  z-index: 2;
  box-shadow: 0 0 7px 0 ${({ theme: { defaultColors } }) => lighten(0.5, defaultColors.darkGray)};
  margin-top: -10px;
  padding-top: 10px;
`;

const AutocompleteListElement = styled.div`
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  max-height: 210px;
  border-radius: 0 0 7px 7px;
`;

const AutocompleteListItemElement = styled.div`
  ${AutocompleteListItemStyles};
`;

const EmptyAutocompleteListItemElement = styled.div`
  ${AutocompleteListItemStyles};

  cursor: default;

  &:hover {
    background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
    color: ${({ theme: { defaultColors } }) => defaultColors.black};
  }
`;

const _nomralizeString = (value: string) =>
  value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

type Props = {
  active: boolean;
  values: AutocompleteValue[];
  onClick: (answer: AutocompleteValue) => void;
  searched: string;
  width: number;
};

export const AutocompleteList: React.FC<Props> = ({ active, values, onClick, searched, width }) => {
  const [autocomplete, setAutocomplete] = React.useState(values);

  React.useEffect(() => {
    setAutocomplete(
      values.filter((value) => _nomralizeString(value.value).includes(_nomralizeString(searched)))
    );
  }, [values, searched]);

  const highlight = (originalValue: string) => {
    if (searched.length) {
      const normalizedValue = originalValue
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const r = new RegExp(searched, 'g');

      let resultValue = originalValue;
      for (const m of Array.from(normalizedValue.matchAll(r)).reverse()) {
        resultValue = replaceAt(
          resultValue,
          m.index!,
          `<b>${originalValue.substring(m.index!, m.index! + m[0].length)}</b>`,
          m[0].length
        );
      }

      return resultValue;
    }

    return originalValue;
  };

  return (
    <AutocompleteListWrapperElement active={active} width={width}>
      <AutocompleteListElement>
        {autocomplete.map((value) => (
          <AutocompleteListItemElement key={value.id} onClick={() => onClick(value)} tabIndex={0}>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: highlight(value.value) }} />
          </AutocompleteListItemElement>
        ))}

        {autocomplete.length === 0 && (
          <EmptyAutocompleteListItemElement>
            <span>Zadanému textu neodpovídají žádné výsledky</span>
          </EmptyAutocompleteListItemElement>
        )}
      </AutocompleteListElement>
    </AutocompleteListWrapperElement>
  );
};
