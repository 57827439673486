import * as React from 'react';
import { ImageValue } from 'studie-core/src/models';
import { ImageStyles } from 'studie-core/src/styles/visual/Image.styles';
import styled from 'styled-components';

const ImageElement = styled.div`
  ${ImageStyles};
`;

type Props = {
  model: ImageValue;
};

export const Image: React.FC<Props> = ({ model }) => (
  <ImageElement>
    <img src={model.imageUrl} alt="" />
  </ImageElement>
);
