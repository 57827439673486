import * as React from 'react';
import styled, { css } from 'styled-components';
import { CheckboxValue } from '../../models';
import { LineCheckboxCheckmark } from './LineCheckboxCheckmark';

const LineCheckboxElement = styled.div<{ isActive: boolean }>`
  --background-color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.lightPrimary};

  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: var(--background-color);
  min-height: 60px;
  padding: 15px;

  ${({ isActive }) =>
    isActive &&
    css`
      --background-color: ${({ theme: { questionnaireColors } }) =>
        questionnaireColors.primary};
    `}
`;

type Props = {
  isActive: boolean;
  textComponent: React.ComponentType<{
    isActive: boolean;
    onClick: () => void;
  }>;
  model: CheckboxValue;
  onClick?: (model: CheckboxValue) => void;
  className?: string;
};

export const LineCheckbox: React.FC<Props> = ({
  isActive,
  textComponent,
  model,
  onClick,
  className,
}) => (
  <LineCheckboxElement
    className={className}
    isActive={isActive}
    onClick={() => onClick && onClick(model)}
  >
    <LineCheckboxCheckmark isActive={isActive} />

    {React.createElement(textComponent as any, { isActive }, model.text)}
  </LineCheckboxElement>
);
