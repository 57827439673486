import { ActionType, createAction, createReducer } from 'typesafe-actions';

type ModalState = {
  active: boolean;
  headerText?: string;
  content?: JSX.Element;
  buttons?: JSX.Element;
  isError: boolean;
};

export const showModal = createAction('@modal/SHOW')<{
  headerText: string;
  content: JSX.Element;
  buttons?: JSX.Element;
}>();
export const showErrorModal = createAction('@modal/SHOW_ERROR')<{
  content: JSX.Element;
}>();
export const hideModal = createAction('@modal/HIDE')();

export type ModalAction =
  | ActionType<typeof showModal>
  | ActionType<typeof showErrorModal>
  | ActionType<typeof hideModal>;

const defaultState = {
  active: false,
  headerText: undefined,
  content: undefined,
  buttons: undefined,
  isError: false,
};

export const modalReducer = createReducer<ModalState, ModalAction>({
  ...defaultState,
})
  .handleAction(showModal, (state, action) => ({
    ...state,
    active: true,
    headerText: action.payload.headerText,
    content: action.payload.content,
    buttons: action.payload.buttons,
  }))
  .handleAction(showErrorModal, (state, action) => ({
    ...state,
    active: true,
    headerText: 'Nastal problém při zpracovávání',
    content: action.payload.content,
    isError: true,
  }))
  .handleAction(hideModal, (state) => ({
    ...state,
    ...defaultState,
  }));
