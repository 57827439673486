import styled from 'styled-components';

export const Parent = styled.div`
  margin-bottom: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const Label = styled.label`
  --label-color: ${({ theme: { defaultColors } }) => defaultColors.darkGray};
  font-size: 0.85rem;
  cursor: text;
  user-select: none;
  pointer-events: none;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 15px;
  transition: transform 0.2s ease-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform-origin: 0% 100%;
  transform: translateY(20px);

  color: var(--label-color);
`;

export const Message = styled.div`
  color: ${({ theme: { defaultColors } }) => defaultColors.red};
  font-size: 0.85rem;
  padding: 2px 0 0 10px;
  transition: all 0.25s ease;
`;
