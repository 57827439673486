import { css } from 'styled-components';

export const SliderStyles = css`
  width: 650px;
  margin: 0 auto;

  .noUi-target {
    background-color: transparent;
    box-shadow: none;
    border-width: 0;
    display: flex;
    align-items: center;
    height: 42px;
    padding: 0 21px;
  }

  .noUi-connects {
    border-radius: 10px;
  }

  .noUi-base {
    border-radius: 10px;
    box-shadow: none;
    height: 10px;
  }

  .noUi-horizontal .noUi-handle {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    right: -21px;
    top: 50%;
    transform: translateY(-16px);
    border-width: 0;
  }

  .noUi-handle {
    cursor: pointer;
    box-shadow: none;
  }

  .noUi-handle:before,
  .noUi-handle:after {
    display: none;
  }

  .noUi-touch-area:after {
    content: '';

    position: absolute;
    width: 21px;
    height: 21px;
    background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .noUi-marker {
    display: none;
  }

  .noUi-pips-horizontal {
    height: 20px;
    padding: 0;
    width: calc(100% - 34px);
    left: 17px;
    margin-top: 15px;
  }

  .noUi-value {
    top: 0;
    cursor: pointer;
  }

  .noUi-value-horizontal {
    transform: translate(-50%, 10%);
  }
`;
