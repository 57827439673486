import { toast, ToastOptions } from 'react-toastify';

const DEFAULT_SETTINGS: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

type Props = { text: string; options?: Partial<ToastOptions> };

export const error = ({ text, options }: Props) => {
  toast.error(text, {
    ...DEFAULT_SETTINGS,
    ...options,
  });
};

export const info = ({ text, options }: Props) => {
  toast.info(text, {
    ...DEFAULT_SETTINGS,
    ...options,
  });
};

export const success = ({ text, options }: Props) => {
  toast.success(text, {
    ...DEFAULT_SETTINGS,
    ...options,
  });
};
