import * as React from 'react';
import styled from 'styled-components';

const SvgElement = styled.svg`
  max-height: inherit;
`;

export const Icon: React.FC<{
  name: string;
  width?: number;
  height?: number;
  className?: string;
}> = ({ name, width, height, className }) => {
  const [customWidth, setCustomWidth] = React.useState<number | undefined>(
    undefined
  );
  const [customHeight, setCustomHeight] = React.useState<number | undefined>(
    undefined
  );
  const [checkedIcon, setCheckedIcon] = React.useState(false);

  React.useEffect(() => {
    if (!checkedIcon) {
      const icon = document.getElementById(name);
      if (icon?.attributes?.getNamedItem('width')?.value) {
        setCustomWidth(
          parseFloat(icon.attributes.getNamedItem('width')!.value) + 1
        );
      }
      if (icon?.attributes?.getNamedItem('height')?.value) {
        setCustomHeight(
          parseFloat(icon.attributes.getNamedItem('height')!.value) + 1
        );
      }

      setCheckedIcon(true);
    }
  }, [name, checkedIcon]);

  return (
    <SvgElement
      aria-hidden="true"
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      width={customWidth ?? width}
      height={customHeight ?? height}
      className={className}
    >
      <use xlinkHref={`#${name}`} />
    </SvgElement>
  );
};
