import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Structure } from 'studie-core/src/models';
import styled, { css, ThemeProvider } from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setQuestionnaireIcons } from '../../state/icons';
import { getQuestionnaireAsync, setupQuestionnaireIndex } from '../../state/questionnaire';
import { RootStateType } from '../../state/store';
import { setComponent } from '../../state/views';
import { makeQuestionnaireTheme } from '../../theme';
import { RoutePaths } from '../RoutePaths';
import { tryParseInt } from './utils';
import { API_ROUTES } from '../../api';

type QuestionaireElementProps = {
  type: Structure;
  height: number;
};

const InternalQuestionaireElement: React.FC<QuestionaireElementProps> = ({
  children,
  type,
  height,
  ...rest
}) => <div {...rest}>{children}</div>;

const QuestionaireElement = styled(InternalQuestionaireElement)`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ type, height }) =>
    type === 'vertical'
      ? css`
          height: ${height}px;
        `
      : css`
          min-height: inherit;
        `}
`;

type RouteProps = {
  leadId: string;
  securityToken: string;
  activeWidgetIndex?: string;
};

export const Questionaire: React.FC = () => {
  const { leadId, securityToken, activeWidgetIndex } = useParams<RouteProps>();
  const history = useHistory();
  const { type, activeComponent: ActiveComponent } = useSelector((e: RootStateType) => e.views);
  const {
    loading,
    error,
    questionnaire,
    questionnaire: { theme },
  } = useSelector((e: RootStateType) => e.questionnaire);
  const dispatch = useDispatch();
  const { height } = useWindowSize();
  const [canShowContent, setCanShowContent] = React.useState(false);

  React.useLayoutEffect(() => {
    if (leadId === 'livescript-preview' || leadId === 'demo') {
      const rootUrl = `${process.env.REACT_APP_API_URL}/v1/callscript-preview`;
      const configName = securityToken;
      const definitionUrl = `${rootUrl}/${configName}`;
      window.isDemo = true;
      dispatch(getQuestionnaireAsync.request({ definitionUrl }));
    } else {
      const definitionUrl = `${process.env.REACT_APP_API_URL}${API_ROUTES.Questions_GET}/${leadId}/${securityToken}`;
      dispatch(getQuestionnaireAsync.request({ definitionUrl }));
    }
  }, []);

  React.useEffect(() => {
    if (!loading) {
      if (!isEmpty(error)) {
        // removed by MV
        // history.push(RoutePaths.NotFound);
        return;
      }

      if (!isEmpty(questionnaire)) {
        if (questionnaire.completed) {
          history.push({
            pathname: RoutePaths.Done,
            state: { redirected: false },
          });

          return;
        }

        dispatch(setQuestionnaireIcons({ icons: questionnaire.iconset }));

        const parsedWidgetId = tryParseInt(activeWidgetIndex!, 10);
        dispatch(
          setupQuestionnaireIndex({
            activeWidgetIndex: parsedWidgetId,
          })
        );

        if (parsedWidgetId !== 0) {
          dispatch(
            setComponent({
              component: 'Questionnaire',
              type: questionnaire.paginationType,
            })
          );
        }

        setCanShowContent(true);
      }
    }
  }, [loading, error, questionnaire]);

  if (canShowContent) {
    return (
      <ThemeProvider theme={makeQuestionnaireTheme({ primaryColor: theme.light.primary })}>
        <QuestionaireElement type={type!} height={height}>
          <ActiveComponent />
        </QuestionaireElement>
      </ThemeProvider>
    );
  }

  return null;
};
