import { isEmpty } from 'lodash';
import * as React from 'react';
import { RefCallBack } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Company, Person } from 'studie-core/src/models';
import { InputTextBoxStyles } from 'studie-core/src/styles/inputs/InputTextBox.styles';
import styled, { css } from 'styled-components';
import { FieldErrorProps } from '../../../../hooks/useFieldError';
import { useHasValue } from '../../../../hooks/useHasValue';
import { DispatchFormAnswerData } from '../../../form/types';
import { Content, Label, Message, Parent } from './Common.styles';

const InternalInput: React.FC<
  {
    inputRef: RefCallBack;
    hasError: boolean;
    hasValue: boolean;
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ inputRef, hasError, hasValue, name, id, ...rest }) => (
  <input {...rest} ref={inputRef} name={name} id={id} type="tel" autoComplete="tel" />
);

const InputElement = styled(InternalInput)`
  ${InputTextBoxStyles}

  min-width: unset;
  width: 100%;

  &:active + label,
  &:focus + label {
    --label-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    transform: translateY(5px) scale(0.8);
    opacity: 1;
  }

  ${({ hasValue }) =>
    hasValue &&
    css`
      & + label {
        transform: translateY(5px) scale(0.8);
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      --border-color: ${({ theme: { defaultColors } }) => defaultColors.red};

      & + label {
        --label-color: ${({ theme: { defaultColors } }) => defaultColors.red};
        opacity: 1;
      }
    `}
`;

type Props = {
  name: string;
  value?: string;
  label?: string;
  inputRef: RefCallBack;
  onChange: (...event: any[]) => void;
  required?: boolean;
  dispatchAnswer?: (answer: DispatchFormAnswerData) => void;
  triggerOwnValidation?: (name?: string | string[] | undefined) => Promise<boolean>;
} & FieldErrorProps;

export const PhoneInput: React.FC<Props> = ({
  name,
  value,
  fieldError,
  label,
  inputRef,
  onChange,
  required = false,
  dispatchAnswer,
  triggerOwnValidation,
}) => {
  const digit = /[0-9]/;
  const mask = [
    '+',
    '4',
    '2',
    /[0-1]/,
    ' ',
    digit,
    digit,
    digit,
    ' ',
    digit,
    digit,
    digit,
    ' ',
    digit,
    digit,
    digit,
  ];
  const [isTouched, setIsTouched] = React.useState(false);
  const hasValue = useHasValue(value, ['.']);
  const labelText = required ? `${label} *` : label;

  const internalOnChange = (value: string) => {
    if (dispatchAnswer) {
      if (name.split('.')[0] === 'company') {
        dispatchAnswer({
          value,
          type: name.split('.')[0] as 'company',
          property: name.split('.')[1] as keyof Omit<Company, 'id'>,
        });
      } else {
        dispatchAnswer({
          value,
          type: name.split('.')[0] as 'people',
          property: name.split('.')[1] as keyof Omit<Person, 'id' | 'order'>,
        });
      }
    }
  };

  React.useEffect(() => {
    if (!isTouched) {
      if (hasValue) {
        internalOnChange(!isEmpty(fieldError) ? '' : value!);

        if (required) {
          triggerOwnValidation && triggerOwnValidation(name);
        }
      }
    } else if (hasValue && !!isEmpty(fieldError)) {
      internalOnChange(value!);
    } else if (!isEmpty(fieldError)) {
      internalOnChange('');
    }
  }, [isTouched, hasValue, fieldError, required]);

  return (
    <Parent>
      <Content>
        <InputMask
          mask={mask}
          defaultValue={value}
          onChange={(e) => {
            setIsTouched(true);
            onChange(e);
            internalOnChange(e.target.value);
          }}
        >
          <InputElement
            inputRef={inputRef}
            hasValue={hasValue}
            hasError={!isEmpty(fieldError)}
            name={name}
            id={name}
          />
        </InputMask>

        {label && <Label htmlFor={name}>{labelText}</Label>}
      </Content>

      <Message
        dangerouslySetInnerHTML={{
          __html:
            (fieldError && fieldError?.message && fieldError?.message?.toString()) || '&nbsp;',
        }}
      />
    </Parent>
  );
};
