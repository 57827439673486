import * as React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { RootStateType } from '../../../state/store';

type WidgetsContainerElementProps = {
  width: number;
  windowWidth: number;
  activeWidgetIndex: number;
};

const InternalWidgetsContainerElement: React.FC<WidgetsContainerElementProps> = ({
  width,
  windowWidth,
  activeWidgetIndex,
  children,
  ...rest
}) => <div {...rest}>{children}</div>;
const WidgetsContainerElement = styled(InternalWidgetsContainerElement)`
  display: flex;
  height: 100%;
  width: ${({ width }) => `${width}px`};
  will-change: transform;
  // translate3d causes blurry text on macOS (https://bugs.webkit.org/show_bug.cgi?id=192582)
  transform: ${({ windowWidth, activeWidgetIndex }) =>
    css`perspective(1px) translate3d(-${windowWidth * activeWidgetIndex}px, 0px, 0px)`};
  transition: transform 0.75s ease;
`;

export const WidgetsContainer: React.FC = ({ children }) => {
  const windowSize = useWindowSize();
  const { activeWidgetIndex, widgets } = useSelector((e: RootStateType) => e.questionnaire);

  return (
    <WidgetsContainerElement
      windowWidth={windowSize.width}
      width={windowSize.width * widgets.length}
      activeWidgetIndex={activeWidgetIndex}
    >
      {children}
    </WidgetsContainerElement>
  );
};
