import { isEmpty } from 'lodash';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'studie-core/src/components/buttons/Button';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';
import { setPage } from '../../../../state/landing';
import { insertCallOrderAsync } from '../../../../state/livesale-integration';
import { showErrorModal } from '../../../../state/modal';
import { RootStateType } from '../../../../state/store';
import { DateInput } from '../forms/DateInput';
import { EndFormInput } from '../forms/EndFormInput';
import { PhoneInput } from '../forms/PhoneInput';
import { TimeInput } from '../forms/TimeInput';

const MainHeadingElement = styled.h1`
  font-size: 40px;
  margin-top: 0;

  @media only screen and (min-width: 600px) {
    font-size: 50px;
  }
`;

const SmallIcon = styled(Icon)`
  min-width: 20px;
  width: 20px;
  height: 20px;
`;

const SubHeaderElement = styled.h3`
  font-size: 18px;
`;

const InputGroupElement = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;

    > div {
      flex: 0 1 50%;
    }

    > div:first-of-type {
      margin-right: 5px;
    }

    > div:last-of-type {
      margin-left: 5px;
    }
  }
`;

const ButtonsGroupElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BackButtonElement = styled(Button)`
  padding: 12px 14.5px;
`;

const SendButtonElement = styled(Button)`
  justify-content: space-between;
  width: 175px;
`;

type FormModel = {
  email: string;
  phone: string;
  date: string;
  time: string;
};

export const CallOrder: React.FC = () => {
  const dispatch = useDispatch();
  const { leadId, campaignId, callscriptId } = useSelector(
    (e: RootStateType) => e.questionnaire.questionnaire
  );
  const {
    callOrder: { isSuccessReponse, loading, error },
  } = useSelector((e: RootStateType) => e.livesale);
  const today = new Date();
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm<FormModel>({
    mode: 'onChange',
    defaultValues: {
      date: `${`0${today.getDate()}`.slice(-2)}. ${`0${today.getMonth() + 1}`.slice(
        -2
      )}. ${today.getFullYear()}`,
      time: `${`0${today.getHours() + 2}`.slice(-2)}:${`0${today.getMinutes()}`.slice(-2)}`,
    },
  });
  const onSubmit = (values: FormModel) => {
    dispatch(
      insertCallOrderAsync.request({
        LeadID: leadId,
        CampaignID: campaignId,
        CallscriptID: callscriptId,
        EMail: values.email,
        Phone: values.phone,
        Date: values.date,
        Time: values.time,
      })
    );
  };

  const goBack = () => dispatch(setPage({ page: 'Welcome' }));

  React.useEffect(() => {
    if (!loading) {
      if (!isEmpty(error)) {
        dispatch(
          showErrorModal({
            content: <span>{error}</span>,
          })
        );
      }

      if (isSuccessReponse) {
        dispatch(setPage({ page: 'Thanks' }));
      }
    }
  }, [dispatch, loading, isSuccessReponse, error]);

  return (
    <>
      <MainHeadingElement>Objednat asistenci</MainHeadingElement>
      <div>
        Vyplňte, prosím, následující údaje. Náš operátor Vás následně bude kontaktovat ve Vámi
        zvoleném termínu.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <SubHeaderElement>Kontaktní údaje</SubHeaderElement>

          <InputGroupElement>
            <EndFormInput
              type="email"
              label="E-mail"
              fieldError={errors.email}
              {...register('email', {
                required: 'Pole je povinné',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Neplatná e-mailová adresa',
                },
              })}
            />

            <Controller
              control={control}
              rules={{
                required: 'Toto pole je povinné',
                validate: (value) =>
                  /(\+42)+[0-1]\s[0-9]{3}\s[0-9]{3}\s[0-9]{3}/g.test(value) ||
                  'Neplatné telefonní číslo',
              }}
              render={({ field: { onChange, value, name, ref } }) => (
                <PhoneInput
                  name={name}
                  inputRef={ref}
                  value={value}
                  label="Telefon"
                  fieldError={errors.phone}
                  onChange={onChange}
                />
              )}
              name="phone"
              defaultValue=""
            />
          </InputGroupElement>
        </div>

        <div>
          <SubHeaderElement>Kdy si přejete být kontaktováni?</SubHeaderElement>

          <InputGroupElement>
            <Controller
              control={control}
              rules={{
                required: 'Toto pole je povinné',
                pattern: {
                  value: /\d{2}. \d{2}. \d{4}/g,
                  message: 'Datum není ve formátu dd. MM. yyyy',
                },
              }}
              render={({ field: { onChange, value, name, ref } }) => (
                <DateInput
                  name={name}
                  inputRef={ref}
                  value={value}
                  label="Datum"
                  fieldError={errors.date}
                  onChange={onChange}
                />
              )}
              name="date"
            />

            <Controller
              control={control}
              rules={{
                required: 'Toto pole je povinné',
                pattern: {
                  value: /\d{2}:\d{2}/g,
                  message: 'Čas není ve formátu HH:mm',
                },
              }}
              render={({ field: { onChange, value, name, ref } }) => (
                <TimeInput
                  name={name}
                  inputRef={ref}
                  value={value}
                  label="Čas"
                  fieldError={errors.time}
                  onChange={onChange}
                />
              )}
              name="time"
            />
          </InputGroupElement>
        </div>

        <ButtonsGroupElement>
          <BackButtonElement onClick={goBack} outlined>
            <SmallIcon name="arrow-left" />
          </BackButtonElement>

          <SendButtonElement onClick={() => {}} type="submit" disabled={loading}>
            <span>Objednat</span>
            <SmallIcon name="arrow-right" />
          </SendButtonElement>
        </ButtonsGroupElement>
      </form>
    </>
  );
};
