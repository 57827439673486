import * as React from 'react';
import { Remarkable } from 'remarkable';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';
import { Draggable } from './Draggable';

const DraggableModalElement = styled.div`
  z-index: 9;

  position: absolute;
  top: 20px;

  display: none;

  cursor: move;
  touch-action: none;

  min-width: 200px;

  border-radius: 10px;

  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);

  user-select: none;
`;
const HeaderElement = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 10px;

  border-bottom: 1px solid ${({ theme: { defaultColors } }) => defaultColors.lightGray};
`;
const CloseButtonElement = styled.button`
  padding: 0;
  margin: 0;

  border: none;
  background-color: transparent;

  color: black;
`;
const ContentElement = styled.div`
  padding: 20px;
`;

type Props = {
  content: string;
};

export const DraggableModal = ({ content }: Props) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  Draggable(elementRef);

  const getRawMarkup = () => {
    const md = new Remarkable({ xhtmlOut: true, breaks: true });
    return { __html: md.render(content) };
  };

  const onCloseClick = () => {
    elementRef.current!.style.display = 'none';
  };

  if (window.self === window.top) {
    return null;
  }

  return (
    <DraggableModalElement ref={elementRef} className="help">
      <HeaderElement>
        <span>Nápověda</span>

        <CloseButtonElement type="button" onClick={onCloseClick}>
          <Icon width={14} height={14} name="close" />
        </CloseButtonElement>
      </HeaderElement>

      <ContentElement dangerouslySetInnerHTML={getRawMarkup()} />
    </DraggableModalElement>
  );
};
