import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LineCheckbox as SharedCheckbox } from 'studie-core/src/components/checkboxes/LineCheckbox';
import { CheckboxValue } from 'studie-core/src/models';
import styled from 'styled-components';
import {
  checkValidity,
  updateAnswerAsync,
  updateCheckbox,
  updateCheckboxValidity,
} from '../../state/questionnaire';
import { LineCheckboxText } from './LineCheckboxText';

const CheckboxElement = styled(SharedCheckbox)`
  user-select: none;
  cursor: pointer;
`;

type Props = {
  model: CheckboxValue;
  widgetId: string;
  isSelected: boolean;
};

export const LineCheckbox: React.FC<Props> = ({ model, widgetId, isSelected }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      updateCheckbox({
        widgetId,
        selectedAnswerId: model.id,
        value: true,
      })
    );
    dispatch(checkValidity());
    dispatch(
      updateAnswerAsync.request({
        widgetId,
      })
    );
  };

  React.useEffect(() => {
    if (isSelected) {
      dispatch(
        updateCheckboxValidity({
          widgetId,
        })
      );
      dispatch(checkValidity());
    }
  }, []);

  return (
    <CheckboxElement
      isActive={isSelected}
      textComponent={LineCheckboxText}
      model={model}
      onClick={() => onClick()}
    />
  );
};
