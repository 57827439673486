import * as React from 'react';
import styled from 'styled-components';
import {
  QuestionStyles,
  RequiredQuestionStyles,
} from '../../styles/matrixes/Question.styles';

type QuestionElementProps = { required: boolean };
const InternalQuestionElement: React.FC<QuestionElementProps> = ({
  required,
  children,
  ...rest
}) => <div {...rest}>{children}</div>;
const QuestionElement = styled(InternalQuestionElement)`
  ${QuestionStyles}

  ${({ required }) => required && RequiredQuestionStyles}
`;

type Props = { required: boolean };

export const Question: React.FC<Props> = ({ required, children }) => (
  <QuestionElement required={required}>{children}</QuestionElement>
);
