import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'studie-core/src/components/buttons/Button';
import { Image as ImageType, Page as PageType, Widget as WidgetType } from 'studie-core/src/models';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { setComponent } from '../../state/views';
import { Widget } from '../widgets/Widget';

const SinglePageQuestionnaireElement = styled.div``;

const ActionsElement = styled.div`
  margin: 50px 0;
  width: 850px;
  text-align: center;
`;

const Page = styled.div`
  width: 850px;
`;

const isImageWidget = (model: Partial<WidgetType>): model is ImageType => model.type === 'image';

export const SinglePageQuestionnaire = () => {
  const { pages, valid } = useSelector((e: RootStateType) => e.questionnaire);
  const dispatch = useDispatch();

  const finish = () => dispatch(setComponent({ component: 'Landing' }));

  const renderPage = (page: PageType, previousPagesLength: number) => (
    <Page key={page.id}>
      {!isEmpty(page.headerText) && <div>{page.headerText}</div>}

      <SinglePageQuestionnaireElement>
        {page.widgets.map((widget, index) => renderWidget(widget, index, previousPagesLength))}
      </SinglePageQuestionnaireElement>
    </Page>
  );

  const renderWidget = (widget: WidgetType, widgetIndex: number, previousPagesLength: number) => {
    const isFullscreenWidget = isImageWidget(widget) && widget.subtype === 'fullscreen';
    return <Widget key={widget.id} isFullscreenWidget={isFullscreenWidget} widget={widget} />;
  };

  const renderPages = () =>
    pages.map((page, index) => {
      const previousPages = [...pages].slice(0, index);
      let previousPagesLength = 0;

      if (previousPages.length) {
        previousPagesLength = previousPages.flatMap((page) => page.widgets).length;
      }

      return renderPage(page, previousPagesLength);
    });

  return (
    <>
      {renderPages()}

      <ActionsElement>
        <Button onClick={finish} disabled={!valid}>
          Dokončit
        </Button>
      </ActionsElement>
    </>
  );
};
