import * as React from 'react';
import { RefCallBack } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { InputTextBoxStyles } from 'studie-core/src/styles/inputs/InputTextBox.styles';
import styled, { css } from 'styled-components';
import { FieldErrorProps, useFieldError } from '../../../../hooks/useFieldError';
import { useHasValue } from '../../../../hooks/useHasValue';
import { Content, Label, Message, Parent } from './Common.styles';

type InternalInputProps = {
  inputRef: RefCallBack;
  hasError: boolean;
  hasValue: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
const InternalInput: React.FC<InternalInputProps> = ({
  hasError,
  hasValue,
  inputRef,
  name,
  id,
  ...rest
}) => <input {...rest} ref={inputRef} name={name} id={id} type="text" />;

const InputElement = styled(InternalInput)`
  ${InputTextBoxStyles}

  min-width: unset;
  width: 100%;

  &:active + label,
  &:focus + label {
    --label-color: ${({ theme: { questionnaireColors } }) => questionnaireColors.primary};
    transform: translateY(5px) scale(0.8);
    opacity: 1;
  }

  ${({ hasValue }) =>
    hasValue &&
    css`
      & + label {
        transform: translateY(5px) scale(0.8);
      }
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      --border-color: ${({ theme: { defaultColors } }) => defaultColors.red};

      & + label {
        --label-color: ${({ theme: { defaultColors } }) => defaultColors.red};
        opacity: 1;
      }
    `}
`;

type Props = {
  name: string;
  value?: string;
  label: string;
  inputRef: RefCallBack;
  onChange: (...event: any[]) => void;
} & FieldErrorProps;

export const TimeInput: React.FC<Props> = ({
  name,
  value,
  fieldError,
  label,
  inputRef,
  onChange,
}) => {
  const startsWithTwo = (value || '')[0] === '2';
  const mask = [/[0-2]/, startsWithTwo ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/];
  const hasValue = useHasValue(value, [':']);
  const { hasError, errorMessage } = useFieldError({ fieldError });

  return (
    <Parent>
      <Content>
        <InputMask mask={mask} defaultValue={value} onChange={onChange}>
          <InputElement
            inputRef={inputRef}
            hasError={hasError}
            hasValue={hasValue}
            name={name}
            id={name}
          />
        </InputMask>

        <Label htmlFor={name}>{label}</Label>
      </Content>

      <Message
        dangerouslySetInnerHTML={{
          __html: (hasError && errorMessage) || '&nbsp;',
        }}
      />
    </Parent>
  );
};
