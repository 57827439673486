import * as React from 'react';
import styled from 'styled-components';

const DescriptionElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transition: all 1s ease;

  @media screen and (min-width: 767px) {
    padding-left: 41px;
    padding-bottom: 41px;
  }
`;
const MainHeadingElement = styled.h1`
  margin: 0;
  margin-bottom: 15px;

  font-size: 36px;
  font-weight: 300;

  span:nth-of-type(2) {
    color: #d01419;
    font-weight: bold;
  }

  @media only screen and (min-width: 600px) {
    margin-bottom: 30px;
  }
`;
const ParagraphElement = styled.p`
  color: #666666;
  margin: 0;
  margin-bottom: 15px;

  @media only screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const Description = () => (
  <DescriptionElement>
    <MainHeadingElement>
      <span>Vítejte ve světě</span>
      <br />
      <span>interaktivního telemarketingu.</span>
    </MainHeadingElement>

    <ParagraphElement>
      Při běžném telemarketingu zákazník komunikuje pouze přes telefon, při interaktivní formě
      prostřednictvím našeho inovativního řešení nejen slyšíte, ale i vidíte to, co Vám nabízíme.
    </ParagraphElement>
  </DescriptionElement>
);
