import * as React from 'react';
import { Button as SharedButton } from 'studie-core/src/components/buttons/Button';
import { Icon } from 'studie-core/src/components/icons/Icon';
import styled from 'styled-components';

const Button = styled(SharedButton)`
  border-width: 1px;
  padding: 12px;
  align-self: center;
  justify-content: center;
  margin-right: 15px;
  position: fixed;
  left: 20px;
  bottom: 20px;
`;

const SmallIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

type Props = {
  disabled: boolean;
  onClick: () => void;
};

export const PreviousButton: React.FC<Props> = ({ disabled, onClick }) => (
  <Button onClick={onClick} disabled={disabled} outlined={true}>
    <SmallIcon name="arrow-left" />
  </Button>
);
