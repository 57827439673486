import { isEmpty } from 'lodash';
import * as React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import styled, { css } from 'styled-components';

const CounterElement = styled.div`
  --background-color: ${({ theme: { defaultColors } }) => defaultColors.white};

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--background-color);
`;

const ActionButtonElement = css`
  --color: ${({ theme: { questionnaireColors } }) =>
    questionnaireColors.primary};

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(100% / 4);
  height: 100%;
  color: var(--color);
  font-weight: bold;
  font-size: 24px;
  border-radius: 5px;
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  z-index: 2;
`;

const IncrementorElement = styled.div`
  ${ActionButtonElement}
  box-shadow: -3px 3px 7px 0 rgba(20, 69, 180, 0.1);
`;

const DecrementorElement = styled.div`
  ${ActionButtonElement}
  box-shadow: 3px 3px 7px 0 rgba(20, 69, 180, 0.1);
`;

const InputInternal: React.FC<
  { className?: string } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ className, ...rest }) => (
  <input {...rest} className={`${className} counter`} />
);

const InputElement = styled(InputInternal)`
  background-color: ${({ theme: { defaultColors } }) => defaultColors.white};
  min-width: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-width: 0;
  z-index: 1;
`;

type Props = {
  counterPrefix?: string;
  counterSuffix?: string;
  counter?: number;
  onInputChange?: (value: number | null) => void;
};

export const CounterPlaceholder = styled(CounterElement)`
  --background-color: transparent;

  border-top: 0;
`;

export const CheckboxCounter: React.FC<Props> = ({
  counterSuffix,
  counterPrefix,
  counter,
  onInputChange,
}) => {
  const increment = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onInputChange && onInputChange(counter ? counter + 1 : 1);
  };
  const decrement = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (counter && counter > 1) {
      onInputChange && onInputChange(counter - 1);
    } else if (counter && counter === 1) {
      onInputChange && onInputChange(null);
    }
  };

  const mask: Array<string | RegExp> = [
    /[1-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  const props: Partial<NumberFormatProps> = {
    allowNegative: false,
  };
  if (counterPrefix && !isEmpty(counterPrefix)) {
    mask.unshift(...counterPrefix.split(''));
    props.prefix = counterPrefix;
  }
  if (counterSuffix && !isEmpty(counterSuffix)) {
    mask.push(...counterSuffix.split(''));
    props.suffix = counterSuffix;
  }

  return (
    <CounterElement>
      <DecrementorElement onClick={(e) => decrement(e)}>-</DecrementorElement>

      {/* <InputMask
				mask={mask}
				value={counter ? counter.toString() : '0'}
				onChange={(e) =>
					onInputChange &&
					onInputChange(
						e.target.value.length === 0 ? null : Number(e.target.value),
					)
				}
				onClick={(e: React.MouseEvent) => {
					e.stopPropagation();
				}}
			>
				<InputElement className="counter" />
			</InputMask> */}
      <NumberFormat
        {...props}
        value={counter ? counter.toString() : '0'}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
        customInput={InputElement}
        onValueChange={({ value }) => {
          onInputChange &&
            onInputChange(value.length === 0 ? null : Number(value));
        }}
      />

      <IncrementorElement onClick={(e) => increment(e)}>+</IncrementorElement>
    </CounterElement>
  );
};
